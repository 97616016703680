export enum IDonateQueryParam {
  FirstName = 'first_name',
  MiddleInitial = 'middle_initial',
  LastName = 'last_name',
  CompanyName = 'company_name',
  Address1 = 'address1',
  Address2 = 'address2',
  City = 'city',
  State = 'state',
  ZipCode = 'zip_code',
  Country = 'country',
  Email = 'email',
  Phone = 'phone',
  GiftAmount = 'gift_amount',
  GiftArrays = 'gift_arrays',
  PaymentType = 'payment_type',
  Designation = 'designation',
  Designations = 'designations',
  Anonymous = 'anonymous',
  CorporateMatchEmployer = 'corporate_match_employer',
  ReferenceCode = 'reference_code',
  CashDefault = 'cash_default',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  UtmMedium = 'utm_medium',
  UtmSource = 'utm_source',
  UtmTerm = 'utm_term',
  FrequencyOptions = 'frequency_options',
  Frequency = 'frequency',
  CustomerMeta = 'customer_meta',
  StartDate = 'start_date',
  EndDate = 'end_date',
  EndCount = 'end_count'
}
