/**
 * Converts camel or pascal cased string to kebab-case
 * @param {string} str the string to convert from pascal or camel case
 * @returns {string} the kebab-cased version of the given string
 */
const pascalOrCamelToKebab = (str: string): string =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  );

/**
 * Takes in a string with spaces and converts it to pascal case
 * @param {string} str the string to convert to pascal case
 * @returns {string} the converted string
 */
const stringToPascalCase = (str: string): string =>
  str.indexOf(' ') !== -1
    ? str
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join('')
    : str;

/**
 * Takes in a string and converts it into an HTML id-esque string e.g.
 * @example
 * ```javascript
 *   convertToId('hello world') => "hello-world"
 * ```
 * @param str the string to convert to an id
 * @returns an HTML id-esque string
 */
export const convertToId = (str: string): string =>
  pascalOrCamelToKebab(stringToPascalCase(str));
