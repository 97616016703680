import { Box } from '@mui/material';
import Text from 'components/lib/Text';
import './Footer.scss';

type FooterProps = {
  text: string;
  color: string;
};

const Footer = ({ text, color }: FooterProps) => (
  <Box className="GF-Footer">
    <Text
      className="GF-Footer__text"
      color={color}
      textAlign="center"
      variant="body"
    >
      {text}
    </Text>
  </Box>
);

export default Footer;
