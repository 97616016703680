import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import BlockerGif from './Blocker.gif';
import './disabledOverlay.scss';

/**
 * Representation of the API for interfacing with the disalbed overlay.
 * {}`setDisplayOverlay`, `displayOverlay`}. This is what
 * is held in the `DisabledOverlayContext`
 */
type DisabledOverlayContextType = {
  setDisplayOverlay: (show: boolean) => void;
  displayOverlay: boolean;
};

/**
 * The context for tracking and manipulating the global disabled overlay.
 */
const DisabledOverlayContext = createContext<DisabledOverlayContextType | null>(
  null
);

/**
 * A hook for interfacing with the disabled overlay service. Bring this hook into your
 * component to be able to easily hide/show the overlay. Directly interfaces with
 * the `DisabledOverlay Context`
 */
export const useDisabledOverlay = (): DisabledOverlayContextType => {
  const context = useContext(DisabledOverlayContext);
  if (!context) {
    throw new Error(
      'Something went wrong. No context found for disabled overlay.'
    );
  }
  return context;
};

/**
 * A wrapper for `DisabledOverlayContext.Provider`. This component should be rendered at the top of the application
 * so all children components are able to access the API it exposes. `DisabledOverlayProvider` holds the state
 * of the `displayOverlay` boolean and its setter.
 */
export const DisabledOverlayProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

  const value = useMemo(
    () => ({ displayOverlay, setDisplayOverlay }),
    [displayOverlay, setDisplayOverlay]
  );

  return (
    <DisabledOverlayContext.Provider value={value}>
      {children}
    </DisabledOverlayContext.Provider>
  );
};

/**
 * An out of the box consumer for rendering the overlay. Rendering this component in the base
 * of your app will render the oberlay regardless of the origin of their push.
 */

export const GlobalDisabledOverlay = (): JSX.Element => {
  const { displayOverlay } = useDisabledOverlay();

  // Using `div` since this is a very simple utility overlay simply meant to disable access to the rest of the page
  return (
    <div
      id="GivingForm-DisabledOverlay"
      className={displayOverlay ? 'GivingForm-DisabledOverlay--show' : ''}
    >
      <img src={BlockerGif} alt="Loading..." />
    </div>
  );
};
