import { Stack } from '@mui/material';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { calculateTransactionAmount } from 'components/lib/BackendLib/calculateTransactionAmount';
import Checkbox from 'components/lib/Checkbox';
import Text from 'components/lib/Text';
import {
  AllPaymentOptions,
  DonorFees,
  ICoverTransactionFeeBlock,
  SelectedDesignation
} from 'types';
import { usdFormatter } from 'utils';
import { getDonationAmount } from 'utils/donationUtils';
import './CoverTransactionFee.scss';

interface CoverTransactionFeeProps extends ICoverTransactionFeeBlock {
  donorFees: DonorFees;
}

const formatMessage = (
  giftAmount: number,
  paymentOption: AllPaymentOptions,
  donorFees: DonorFees,
  message = 'I would like to cover the processing fee of {{Fee Amount}}.'
) => {
  const feeValue =
    giftAmount && paymentOption && donorFees
      ? usdFormatter.format(
          calculateTransactionAmount(giftAmount, paymentOption, donorFees)
        )
      : '$X.XX';
  const messageChunks = message.split('{{Fee Amount}}') as (
    | string
    | JSX.Element
  )[];
  for (let i = messageChunks.length - 2; i >= 0; i--) {
    messageChunks.splice(
      i + 1,
      0,
      <span key={i} className="GF-CoverTransactionFee__value">
        {feeValue}
      </span>
    );
  }
  return messageChunks;
};

const CoverTransactionFee = ({
  message,
  donorFees,
  checkedByDefault
}: CoverTransactionFeeProps): JSX.Element => {
  const { control, getValues } = useFormContext();

  let giftAmount = getValues('giftAmount') as number;
  const designation = getValues('designation') as SelectedDesignation[];
  const paymentOption = getValues('paymentOption') as AllPaymentOptions;

  giftAmount = getDonationAmount(designation, giftAmount);

  return (
    <div id="GF-CoverTransactionFee">
      <Stack direction="row" alignItems="center" spacing={0.25}>
        <Controller
          name="coverTransactionFee"
          defaultValue={checkedByDefault}
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <Checkbox
              {...field}
              key={value}
              checked={value}
              value={value}
              className={clsx({
                'GF-CoverTransactionFee__checkbox': !value,
                'GF-CoverTransactionFee__checkbox--selected': value
              })}
            />
          )}
        />
        <Text variant="caption" className="GF-CoverTransactionFee__label">
          {formatMessage(giftAmount, paymentOption, donorFees, message)}
        </Text>
      </Stack>
    </div>
  );
};

export default CoverTransactionFee;
