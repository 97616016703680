import { FormEvent } from './useEventTracker';

// TODO: Need to be able to use eventHub for this and only send messages if GoogleTagManager exists
export const useGoogleTagManager = () => {
  const sendEvent = (event: FormEvent) => {
    // need to convert to expected GTM format
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadata: any = { ...event };
    delete metadata.type;
    delete metadata.details;

    const gtmEvent = {
      ...{ event: `gf_${event.type}` }, // needs gf_ prefix
      ...metadata,
      ...event.details
    };

    if (window !== window.parent) {
      const targetOrigin = (document.referrer?.match(
        /^(([a-z]+:)?(\/\/)?[^/]+).*$/
      ) || [])[1];
      window.parent.postMessage({ gtmEvent }, targetOrigin);
    }
  };

  return { sendEvent };
};
