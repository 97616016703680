import { useLocation } from 'react-router-dom';
import { IDonateQueryParam } from 'types/QueryParams';

/**
 * This custom hook extracts query params from the URL.
 * @param queryParam the query param key to check for in the URL's query params
 * @returns the value of the given key within the URL's query params
 * @example
 * ```tsx
 * const giftAmountValue = useQueryParam('gift_amount');
 * ```
 */
export const useQueryParam = (queryParam: IDonateQueryParam) => {
  const { search } = useLocation();

  return new window.URLSearchParams(search).get(queryParam);
};
