/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Text from 'components/lib/Text';
import { ICorporateMatchingBlock } from 'types/givingForm/CorporateMatchingBlock';
import './CorporateMatching.scss';

type CorporateMatchingProps = ICorporateMatchingBlock & {
  doubleTheDonationKey?: string;
};

export const CorporateMatching = ({
  prompt,
  doubleTheDonationKey
}: CorporateMatchingProps): JSX.Element => {
  const { control } = useFormContext();
  const {
    field: { ref, value, ...field }
  } = useController({
    name: 'corporateMatchingCompanyId',
    control
  });
  const [hasSetInitialCompany, setHasSetInitialCompany] = useState(false);

  const handleCompanyChange = (payload: any) => {
    const selectedCompanyId = payload.detail;
    field.onChange(selectedCompanyId);
  };

  useEffect(() => {
    // on mount
    if ((window as any).doublethedonation) {
      const apiKeyScript = document.createElement('script');
      apiKeyScript.innerHTML = `var DDCONF = {API_KEY: "${doubleTheDonationKey}"}`;
      document.getElementById('dd-company-name-input')?.prepend(apiKeyScript);
      (window as any).doublethedonation.plugin.load_streamlined_input();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // only set initial company on first render
    if (value && !hasSetInitialCompany) {
      (window as any).doublethedonation.plugin.set_company(value);
      setHasSetInitialCompany(true);
    }
    document.addEventListener(
      'doublethedonation_company_id',
      handleCompanyChange
    );

    return () => {
      // on unmount
      document.removeEventListener(
        'doublethedonation_company_id',
        handleCompanyChange
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="gf-corporate-matching">
      <Text variant="caption" className="corporate-matching-prompt">
        {prompt}
      </Text>
      <div id="dd-company-name-input" />
    </div>
  );
};
