import { useFormContext } from 'react-hook-form';
import { IGivingFormSchema } from 'components/GivingForm';
import { calculateTransactionAmount } from 'components/lib/BackendLib/calculateTransactionAmount';
import Button from 'components/lib/Button';
import {
  AllPaymentOptions,
  DonorFees,
  RecurringOptionsType,
  SelectedDesignation
} from 'types';
import { usdFormatter } from 'utils';
import { getDonationAmount } from 'utils/donationUtils';
import './SubmitButton.scss';

type SubmitButtonProps = {
  disabled: boolean;
  donorFees: DonorFees;
};

export const SubmitButton = ({ disabled, donorFees }: SubmitButtonProps) => {
  const { watch } = useFormContext<IGivingFormSchema>();

  let giftAmount = watch('giftAmount') as number;
  const designation = watch('designation') as SelectedDesignation[];
  const paymentOption = watch('paymentOption') as AllPaymentOptions;
  const coverTransactionFee = watch('coverTransactionFee');
  const recurringOption = watch('recurringOption');

  giftAmount = getDonationAmount(designation, giftAmount);

  let totalTransactionLabel = '';

  if (giftAmount && paymentOption && donorFees) {
    totalTransactionLabel = usdFormatter
      .format(
        giftAmount +
          (coverTransactionFee
            ? calculateTransactionAmount(giftAmount, paymentOption, donorFees)
            : 0)
      )
      .replace('.00', '');
  } else if (giftAmount) {
    totalTransactionLabel = usdFormatter.format(giftAmount).replace('.00', '');
  }

  // a mapper used to render the recurring options text
  const recurringOptionsTextMap: { [option: string]: string } = {
    [recurringOption]: recurringOption ?? 'Once',
    [RecurringOptionsType.EveryTwoWeeks]: `Every Two Weeks`,
    [RecurringOptionsType.FirstAndFifteenth]: `Every 1st and 15th`
  };

  return (
    <Button
      type="submit"
      disabled={disabled}
      id="GF-SubmitButton"
      name="submit"
    >
      {`Give ${
        giftAmount
          ? `${totalTransactionLabel} ${recurringOptionsTextMap[recurringOption]}`
          : 'Now'
      }`}
    </Button>
  );
};
