import {
  BlockTypes,
  IGivingFormConfig,
  IRecurringOptionsBlock,
  RecurringOptionsType
} from 'types';

/**
 * Function to determine whether a provided recurring options block
 * contains only "Once" option.
 */
export const recurringOnlyHasOnceOption = ({
  recurringOptions
}: IRecurringOptionsBlock) =>
  recurringOptions.length === 1 &&
  recurringOptions.includes(RecurringOptionsType.Once);

export const getGivingFormBlock = (
  block: BlockTypes,
  config?: IGivingFormConfig
) => config?.blocks.find((configBlock) => configBlock.blockType === block);
