import { v4 as uuid } from 'uuid';
import { Designation, DesignationInstance, RecurringOptionsType } from 'types';

export const mapParamToRecurringOptionEnum = (params: Array<string>) => {
  let mappedParams: Array<RecurringOptionsType> = params.map(
    (param: string) => {
      if (param === 'biweekly') return RecurringOptionsType.EveryTwoWeeks;
      if (param === 'twicemonthly')
        return RecurringOptionsType.FirstAndFifteenth;
      if (param === 'semiannually') return RecurringOptionsType.SemiAnnually;
      return (param.charAt(0).toUpperCase() +
        param.slice(1)) as RecurringOptionsType;
    }
  );
  const paramSet = new Set(mappedParams);
  mappedParams = Array.from(paramSet);
  return mappedParams;
};

export const sanitizeRecurringOptionsQueryParams = (params: string | null) => {
  // if no params are present, we exit early
  if (!params) return [];
  let queryParams: Array<string>;

  // Fail gracefully if the user is provided with bad params or mistakenly pastes the params incorrectly
  try {
    queryParams = JSON.parse(params);
  } catch (e) {
    return [];
  }

  // if the params passed were incorrectly formatted and not an array, exit early to prevent runtime exception
  if (!Array.isArray(queryParams)) return [];

  const validOptions = Object.values(RecurringOptionsType).map(
    (option: string) => option
  );
  return mapParamToRecurringOptionEnum(queryParams).filter((option) =>
    validOptions.includes(option as RecurringOptionsType)
  ) as Array<RecurringOptionsType>;
};

export const parseAvailableDesignationQueryParams = (
  designationsFromQueryParam: string | null,
  campaignDesignations: Designation[]
): DesignationInstance[] => {
  // parse JSON string to designation
  let passedDesignations: DesignationInstance[];
  try {
    // console.log('json parse', JSON.parse(designationsFromQueryParam || '[]'))
    passedDesignations = JSON.parse(designationsFromQueryParam || '[]');
  } catch (ex) {
    passedDesignations = [];
  }

  // first create a new list without desginations that
  // don't exist in the GF config
  const filteredDesignations = passedDesignations.filter(
    (d: DesignationInstance, idx: number) => {
      // make sure it exists on the campaign
      const foundDesignation = campaignDesignations.find(
        ({ id }) => id === d.id
      );
      if (!foundDesignation) {
        return false;
      }

      // find the first instance of the id.
      const firstInstance = passedDesignations.findIndex(
        (d2: DesignationInstance) => d2.id === d.id
      );
      if (firstInstance >= 0 && firstInstance < idx) {
        // we've already found this one, don't add this one
        return false;
      }

      return true;
    }
  );

  // then for each valid designation, pull in full designation
  // data from config (title) and generate a key
  const mappedDesignations = filteredDesignations.map(
    (d: DesignationInstance) => {
      const fullDesignation = campaignDesignations.find(
        ({ id }) => id === d.id
      );
      return {
        ...fullDesignation,
        key: uuid(),
        amount: d.amount
      };
    }
  );

  return mappedDesignations;
};
