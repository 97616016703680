import { useLayoutEffect, useState } from 'react';
import { Box, FormHelperText, Grid, useTheme } from '@mui/material';
import { resolvedEnvironment } from 'services/environment';
import Text from 'components/lib/Text';
import { useDisabledOverlay, useGivingFormData } from 'hooks';
import { useCreditCardToken } from 'hooks/useCreditCardToken';
import { useCreditCardValidation } from 'hooks/useCreditCardValidation';
import { CreditCardTypes, IInputProperties, ISpreedlyProps } from 'types';
import { CCExpDate, CCSubheading } from '../CCPaymentInfo';
import '../CCPaymentInfo/CCPaymentInfo.scss';

/* this now lives in sdk
declare global {
  interface Window {
    Spreedly: {
      init(key: string, opts: IOptions): void;
      on(
        key: string,
        /  eslint-disable-next-line @typescript-eslint/no-explicit-any
        func: (param1: any, param2: any, param3: any, param4: any) => void
      ): void;
      validate: () => void;
      setPlaceholder(fields: string, placeholder: string): void;
      setFieldType(fields: string, placeholder: string): void;
      setStyle(fields: string, placeholder: string): void;
      setNumberFormat(fields: string): void;
      reload(): void;
      tokenizeCreditCard(fields: ITokenizationFields): void;
      removeHandlers(): void;
    };
  }
}
*/

const SpreedlyCreditCard = ({
  currentCardType,
  setCurrentCardType
}: ISpreedlyProps): JSX.Element => {
  const [paymentErrors, setPaymentErrors] = useState<Array<string>>([]);
  const { palette } = useTheme();
  const { config: givingFormConfig } = useGivingFormData();
  const spreedlyInputStyles = `background-color: white; border: 1px solid ${palette.lightGray.main}; border-radius: 0.5rem; box-sizing: border-box; font-family: ${givingFormConfig?.theme.font.formSubheadings}; font-size: 1rem; letter-spacing: inherit; line-height: 1; min-height: 3rem; padding: 0.625rem 1rem; width: 100%;`;

  const { tokenResolver } = useCreditCardToken();
  const { creditCardCc, setCreditCardCc, creditCardCvv, setCreditCardCvv } =
    useCreditCardValidation();
  const { setDisplayOverlay } = useDisabledOverlay();

  const loadSpreedly = (callback: (spreedlyKey: string) => void) => {
    const existingScript = document.getElementById('Spreedly-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
      script.id = 'Spreedly-script';
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) {
          callback(resolvedEnvironment.spreedlyKey);
        }
      };
    }
    if (existingScript && callback) {
      callback(resolvedEnvironment.spreedlyKey);
    }
  };

  useLayoutEffect(() => {
    loadSpreedly((spreedlyKey) => {
      if (!window.Spreedly) {
        return;
      }

      window.Spreedly.init(spreedlyKey, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv'
      });

      window.Spreedly.on('ready', () => {
        window.Spreedly.setPlaceholder('number', 'Enter Card Number');
        window.Spreedly.setFieldType('number', 'text');
        window.Spreedly.setStyle('number', spreedlyInputStyles);
        window.Spreedly.setNumberFormat('prettyFormat');
        window.Spreedly.setPlaceholder('cvv', 'CVV');
        window.Spreedly.setFieldType('cvv', 'text');
        window.Spreedly.setStyle('cvv', spreedlyInputStyles);
      });

      window.Spreedly.on('errors', (errors) => {
        window.Spreedly.reload();
        const errorMessages = errors.map((err: Error) => err.message);
        setPaymentErrors(errorMessages);
        setDisplayOverlay(false);
      });

      let ccInputProperties: IInputProperties = {
        cardType: '',
        validNumber: null,
        validCvv: null,
        numberLength: 0,
        cvvLength: 0
      };
      let isCcError = false;
      let isCvvError = false;

      window.Spreedly.on(
        'fieldEvent',
        (
          fieldName: string,
          eventType: string,
          activeEl: string,
          inputProperties: IInputProperties
        ) => {
          if (eventType === 'input') {
            ccInputProperties = inputProperties;

            if (fieldName === 'number') {
              window.Spreedly.setStyle(
                'number',
                `border-color: ${palette.primary.main};`
              );
              isCcError = false;
            }
            if (fieldName === 'cvv') {
              window.Spreedly.setStyle(
                'cvv',
                `border-color: ${palette.primary.main};`
              );
              isCvvError = false;
            }

            if (inputProperties.validNumber) {
              setCurrentCardType([inputProperties.cardType as CreditCardTypes]);
              setCreditCardCc('valid');
            } else {
              setCreditCardCc('untouched');
            }

            if (inputProperties.validCvv) {
              setCreditCardCvv('valid');
            } else {
              setCreditCardCvv('untouched');
            }
          }

          if (eventType === 'blur') {
            if (fieldName === 'number') {
              if (ccInputProperties.validNumber) {
                window.Spreedly.setStyle(
                  'number',
                  `background-color: white; border-color: ${palette.lightGray.main}`
                );
                isCcError = false;
              } else {
                window.Spreedly.setStyle(
                  'number',
                  `background-color: white; border-color: ${palette.red.main}`
                );
                isCcError = true;
                setCreditCardCc('error');
              }
            }
            if (fieldName === 'cvv') {
              if (ccInputProperties.validCvv) {
                window.Spreedly.setStyle(
                  'cvv',
                  `background-color: white; border-color: ${palette.lightGray.main}`
                );
                isCvvError = false;
              } else {
                window.Spreedly.setStyle(
                  'cvv',
                  `background-color: white; border-color: ${palette.red.main}`
                );
                isCvvError = true;
                setCreditCardCvv('error');
              }
            }
          }

          if (eventType === 'mouseover') {
            window.Spreedly.setStyle(
              `${fieldName}`,
              `background-color: ${palette.lightestGray.main}; border-color: ${palette.primary.main};`
            );
            if (fieldName === 'number' && isCcError) {
              window.Spreedly.setStyle(
                'number',
                `border-color: ${palette.red.main};`
              );
            } else if (fieldName === 'cvv' && isCvvError) {
              window.Spreedly.setStyle(
                'cvv',
                `border-color: ${palette.red.main};`
              );
            }
          }
          if (eventType === 'mouseout') {
            window.Spreedly.setStyle(
              `${fieldName}`,
              `background-color: white;`
            );
            if (
              fieldName === 'number' &&
              ccInputProperties.validNumber &&
              activeEl !== 'number'
            ) {
              window.Spreedly.setStyle(
                'number',
                `border-color: ${palette.lightGray.main};`
              );
            } else if (
              fieldName === 'cvv' &&
              ccInputProperties.validCvv &&
              activeEl !== 'cvv'
            ) {
              window.Spreedly.setStyle(
                'cvv',
                `border-color: ${palette.lightGray.main};`
              );
            }
          }
        }
      );

      window.Spreedly.on('paymentMethod', (token: string) => {
        tokenResolver(token);
      });
    });
  }, [
    palette.lightGray.main,
    palette.lightestGray.main,
    palette.primary.main,
    palette.red.main,
    setCreditCardCc,
    setCreditCardCvv,
    setCurrentCardType,
    setDisplayOverlay,
    spreedlyInputStyles,
    tokenResolver
  ]);

  return (
    <>
      <Grid className="GF__GridInputWrapper" item xs={12} sm={6}>
        <Box display="flex" flexDirection="column">
          <CCSubheading currentCardType={currentCardType} />
          <div id="spreedly-number" style={{ height: '3.5rem' }} />
          {creditCardCc === 'error' && (
            <Text color={palette.red.main} variant="h6">
              Invalid credit card number
            </Text>
          )}
        </Box>
      </Grid>
      <CCExpDate />
      <Grid className="GF__GridInputWrapper" item xs={6} sm={3}>
        <Box display="flex" flexDirection="column">
          <Text className="GF-CCPaymentInfo__heading" variant="h5">
            CVV *
          </Text>
          <div id="spreedly-cvv" style={{ height: '3.5rem' }} />
          {creditCardCvv === 'error' && (
            <Text color={palette.red.main} variant="h6">
              Invalid CVV
            </Text>
          )}
        </Box>
      </Grid>
      {paymentErrors.length > 0 && (
        <Grid className="GF_GridInputWrapper" item xs={12}>
          <FormHelperText error>{paymentErrors.join(', ')}</FormHelperText>
        </Grid>
      )}
    </>
  );
};

export default SpreedlyCreditCard;
