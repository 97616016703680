import { AxiosError } from 'axios';
import axios from 'services/axiosClient';

export const createLinkToken = async (visitorId: string) => {
  try {
    const response = await axios.post(`/plaid/createLinkToken`, {
      visitorId
    });

    return response.data;
  } catch (error) {
    throw new Error(
      (error as AxiosError).response?.data.message ||
        'There was an error loading Plaid'
    );
  }
};

export const exchangePlaidTokens = async (publicToken: string) => {
  try {
    const response = await axios.post('/plaid/exchangePlaidTokens', {
      publicToken
    });

    return response.data;
  } catch (error) {
    throw new Error(
      (error as AxiosError).response?.data.message ||
        'There was an error loading Plaid'
    );
  }
};
