import { SubmitDonationDataType } from 'services/donationService';
import { Donation } from 'types/givingForm';

export const getDonationAmount = (
  designations?: { id: string; title: string; amount: number }[],
  giftAmount?: number
): number =>
  designations?.reduce((acc, curr) => acc + (curr?.amount ?? 0), 0) ??
  giftAmount ??
  0;

export const serializeUrlParams = (
  obj: Record<string, unknown>,
  prefix?: string
) => {
  const str: string[] = [];

  Object.keys(obj).forEach((p) => {
    const k = prefix ? `${prefix}[${p}]` : p;
    const v = obj[p];
    str.push(
      v !== null && typeof v === 'object'
        ? serializeUrlParams(v as Record<string, unknown>, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`
    );
  });
  return str.join('&');
};

export const buildRedirectUrl = (
  baseUrl: string,
  donationData: SubmitDonationDataType & Donation
) => {
  const params: Record<string, unknown> = {
    transactionId: donationData.transactionId,
    transactionTotal: donationData.transactionTotal,
    transactionCreatedAt: donationData.transactionCreatedAt,
    fullName: donationData.fullName,
    giftAmount: donationData.giftAmount,
    coverTransactionFee: donationData.coverTransactionFee,
    recurringOption: donationData.recurringOption,
    paymentOption: donationData.paymentOption,
    creditCard: donationData.creditCard,
    designation: donationData.designation,
    billing: donationData.billing,
    anonymousGift: donationData.anonymousGift,
    corporateMatchingCompanyId: donationData.corporateMatchingCompanyId,
    emailOptIn: donationData.emailOptIn,
    organizationId: donationData.organizationId,
    campaignId: donationData.campaignId,
    referenceCode: donationData.referenceCode,
    customFields: donationData.customFields,
    billingStartDate: donationData.billingStartDate
  };

  const paramsWithValidFields = Object.keys(params).reduce(
    (prev, curr) => (params[curr] ? { ...prev, [curr]: params[curr] } : prev),
    {}
  );

  return `${baseUrl}?${serializeUrlParams(paramsWithValidFields)}`;
};
