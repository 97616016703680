import { FunctionComponent } from 'react';
import { Stack } from '@mui/material';
import { CreditCardIcon, ICONS } from 'components/Icon/CreditCardIcons';
import Text from 'components/lib/Text';
import { CreditCardTypes, acceptedCreditCards } from 'types';
import './CCPaymentInfo.scss';

interface ICCSubheadingProps {
  currentCardType: string[];
}

const cardIconMap: {
  [key in CreditCardTypes]: {
    icons: { active: FunctionComponent; inactive: FunctionComponent };
  };
} = {
  [CreditCardTypes.VISA]: {
    icons: {
      active: ICONS.VISA,
      inactive: ICONS.VISA_INACTIVE
    }
  },
  [CreditCardTypes.MASTERCARD]: {
    icons: {
      active: ICONS.MASTERCARD,
      inactive: ICONS.MASTERCARD_INACTIVE
    }
  },
  [CreditCardTypes.DISCOVER]: {
    icons: {
      active: ICONS.DISCOVER,
      inactive: ICONS.DISCOVER_INACTIVE
    }
  },
  [CreditCardTypes.AMEX]: {
    icons: {
      active: ICONS.AMEX,
      inactive: ICONS.AMEX_INACTIVE
    }
  }
};

const CCSubheading = ({ currentCardType }: ICCSubheadingProps) => (
  <Stack
    className="GF-CCPaymentInfo__heading--container"
    direction="row"
    spacing="0.3rem"
  >
    <Text variant="h5" className="GF-CCPaymentInfo__heading">
      Card Number *
    </Text>
    {acceptedCreditCards.map((acceptedCreditCard) => (
      <CreditCardIcon
        activeIcon={cardIconMap[acceptedCreditCard].icons.active}
        inactiveIcon={cardIconMap[acceptedCreditCard].icons.inactive}
        active={currentCardType.includes(acceptedCreditCard)}
        key={acceptedCreditCard}
        className={`GF-CCPaymentInfo__cc-icon GF-CCPaymentInfo__cc-icon-${acceptedCreditCard}`}
      />
    ))}
  </Stack>
);

export default CCSubheading;
