/* eslint-disable no-return-assign */
import { v4 as uuid } from 'uuid';
import { isLocalStorageAvailable } from 'utils';

export interface IpInfo {
  ipAddress: string;
  location?: string;
}

export const useUserInfo = () => {
  const getVisitorId = (): string => {
    if (isLocalStorageAvailable()) {
      return localStorage.visitorId || (localStorage.visitorId = uuid());
    }
    // If we don't support localStorage (embedded incognito) then we can't
    // track visitorId anyways, so just return a new guid.
    return uuid();
  };
  const getIpInfo = () =>
    new Promise<IpInfo | undefined>((resolve) => {
      const url = 'https://pro.ip-api.com/json/?key=qLmvN3keVDyvwXM';
      const onError = (error: unknown) => {
        console.error(error);
        resolve(undefined);
      };

      try {
        fetch(url)
          .then((resp) =>
            resp
              .json()
              .then((data) =>
                resolve({
                  ipAddress: data.query,
                  location: data.city
                    ? `${data.city}, ${data.regionName} (${data.countryCode})`
                    : undefined
                })
              )
              .catch(onError)
          )
          .catch(onError);
      } catch (error) {
        onError(error);
      }
    });

  return {
    getVisitorId,
    getIpInfo
  };
};
