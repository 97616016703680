import * as yup from 'yup';

export const DesignationsSchema = ({
  min = 5,
  max = 250000
}: {
  min?: number;
  max?: number;
}) => {
  const schemaShape = yup.object({
    amount: yup
      .number()
      .required('Must be at least $0.01')
      .min(0.01, 'Must be at least $0.01')
      .max(max, 'Cannot be more than $250,000'),
    id: yup.string().required().typeError('Invalid type for designation ID'),
    key: yup.string().required().typeError('Invalid type for designation key'),
    title: yup.string().required('A designation cannot be empty')
  });

  return yup.object({
    designation: yup
      .array()
      .of(schemaShape)
      .test(
        'is-in-range',
        `Total donation amount must be between $${min} and $${max}`,
        async (value) => {
          const total =
            value?.reduce((acc, { amount = 0 }) => acc + amount, 0) ?? 0;
          return total >= min && total <= max;
        }
      )
  });
};
