import { FC } from 'react';
import { MenuItemProps, MenuItem as MuiMenuItem } from '@mui/material';
import Icon from 'components/lib/Icon';
import Text from 'components/lib/Text';

export interface SelectMenuItemProps extends MenuItemProps {
  label?: string;
  icon?: FC;
}

const SelectMenuItem = (
  { label, icon, ...props }: SelectMenuItemProps,
  name: string | undefined
): JSX.Element => (
  <MuiMenuItem
    {...{ ...props, label }}
    key={props.value as string}
    data-name={name}
  >
    {!!icon && (
      <Icon icon={icon} sx={{ verticalAlign: 'middle', marginRight: 0.5 }} />
    )}
    {!!label && (
      <Text variant="body" sx={{ display: 'inline' }}>
        {label}
      </Text>
    )}
  </MuiMenuItem>
);

export default SelectMenuItem;
