import { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  RadioGroup,
  useTheme
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';
import { isMatch, parse } from 'date-fns';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import DatePicker from 'components/lib/DatePicker';
import Radio from 'components/lib/Radio';
import Select from 'components/lib/Select';
import Text from 'components/lib/Text';
import TextField, { NumberTextField } from 'components/lib/TextField';
import { NumberTextFieldProps } from 'components/lib/TextField/NumberTextField';
import { useGivingFormData } from 'hooks';
import { CustomFieldType, ICustomFieldBlock } from 'types';
import './CustomField.scss';

type CustomFieldProps = Omit<ICustomFieldBlock, 'blockType'> & {
  currencySymbol: string;
};

const CustomField = ({
  fieldInfo: { type, label, options, required },
  reportingKey,
  heading,
  currencySymbol,
  legacyFieldMapping
}: CustomFieldProps): JSX.Element => {
  const { organizationFeatures } = useGivingFormData();
  const { control, trigger, getValues, watch, setValue } = useFormContext();
  const customFieldFormName = `customField-${reportingKey}`;
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref: refFromContext, ...fieldFromContext },
    fieldState: { error: errorFromContext }
  } = useController({
    name: customFieldFormName,
    control
  });
  const {
    palette: {
      primary: { main: primary }
    }
  } = useTheme();

  const customFieldValue = watch(customFieldFormName);

  useEffect(() => {
    if (organizationFeatures?.legacyCustomFieldMapping && legacyFieldMapping) {
      setValue(legacyFieldMapping, customFieldValue);
    }
  }, [
    customFieldValue,
    legacyFieldMapping,
    organizationFeatures?.legacyCustomFieldMapping,
    setValue
  ]);

  const getHeading = (): string | undefined =>
    required ? `* ${heading}` : heading;

  const getComponent = (
    fieldType: CustomFieldType,
    props: TextFieldProps | NumberTextFieldProps
  ) => {
    const className = clsx(
      'GF-CustomField__field',
      `GF-CustomField__field--${fieldType}`,
      `GF-CustomField__field--${uuid()}` // add a new class that makes each dropdown selector uniquely identifiable by class string .
    );
    // Abstraction of common components and common props between types
    switch (fieldType) {
      case 'text':
      case 'textarea':
        return (
          <Controller
            name={customFieldFormName}
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                className={className}
                hiddenLabel
                placeholder="Type Answer Here"
                label={label}
                error={!!error}
                helperText={error?.message ?? null}
                InputLabelProps={{
                  required: false
                }}
                {...(props as TextFieldProps)}
              />
            )}
          />
        );
      case 'date':
        return (
          <Controller
            name={customFieldFormName}
            control={control}
            defaultValue={null}
            render={({
              field: { ref, onChange, ...field },
              fieldState: { error }
            }) => {
              const fieldValue = field?.value;
              const isExpectedFormat =
                isMatch(fieldValue, 'yyyy-MM-dd') ||
                isMatch(fieldValue, 'yyyy/MM/dd');

              return (
                <DatePicker
                  name={customFieldFormName}
                  primaryColor={primary}
                  error={!!error}
                  value={
                    isExpectedFormat
                      ? // if expected format, parse to date object
                        parse(
                          fieldValue.replace(/(-|\/)/g, '-'),
                          'yyyy-MM-dd',
                          new Date()
                        )?.toDateString()
                      : // if not expected format, pass it through
                        fieldValue
                  }
                  onChange={(...args) => {
                    onChange(...args);
                    trigger(customFieldFormName);
                  }}
                  dialogClassName={className}
                  TextFieldProps={{
                    ...field,
                    className: 'GF-CustomField__field--dateInput',
                    label,
                    helperText: error?.message ?? null
                  }}
                />
              );
            }}
          />
        );
      case 'number':
      case 'currency':
        return (
          <Controller
            name={customFieldFormName}
            control={control}
            defaultValue={getValues(customFieldFormName)}
            render={({
              field: { ref, value, ...field },
              fieldState: { error }
            }) => (
              <NumberTextField
                {...field}
                value={Number(value)}
                className={className}
                hiddenLabel
                placeholder="Type Answer Here"
                label={label}
                error={!!error}
                helperText={error?.message ?? null}
                {...(props as NumberTextFieldProps)}
              />
            )}
          />
        );
      case 'singleChoice':
        return (
          <Controller
            name={customFieldFormName}
            control={control}
            defaultValue={getValues(customFieldFormName)}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({
              field: { ref, value, ...field },
              fieldState: { error }
            }) => (
              <FormControl error={!!error}>
                <RadioGroup
                  {...field}
                  key={value}
                  value={value}
                  className="GF-CustomField__field--radiogroup"
                >
                  {options?.map((option) => (
                    <FormControlLabel
                      value={option}
                      control={<Radio />}
                      label={option}
                      className={className}
                      key={option}
                    />
                  ))}
                </RadioGroup>
                <FormHelperText>
                  {error ? error?.message || null : null}
                </FormHelperText>
              </FormControl>
            )}
          />
        );
      case 'dropdown':
        return (
          <Select
            {...fieldFromContext}
            key={fieldFromContext.value}
            label={label || 'Select'}
            error={!!errorFromContext}
            defaultValue={fieldFromContext.value}
            helperText={
              errorFromContext ? errorFromContext?.message || null : null
            }
            options={
              options
                ? options.map((option) => ({
                    value: option,
                    label: option
                  }))
                : []
            }
            className={className}
          />
        );

      default:
        throw new Error('Not a valid type');
    }
  };

  // map of type specific props to inject into component
  const typeProps =
    {
      textarea: {
        multiline: true,
        minRows: 3
      },
      currency: {
        prefix: currencySymbol,
        decimalScale: 2
      }
    }[type as string] ?? {};

  return (
    <Box className="GF-CustomField" id="GF-CustomField">
      <Grid container>
        {heading && (
          <Grid item xs={12}>
            <Text variant="h3" className="GF-CustomField__heading">
              {getHeading()}
            </Text>
          </Grid>
        )}
        <Grid item xs={12}>
          {getComponent(type, typeProps)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomField;
