import axios from 'axios';
import { sdkClientPromise } from 'services/idonateSdk';
import { IGivingFormSchema } from 'components/GivingForm';

export const tokenizeBankAccountCardConnect = async (
  organizationId: string,
  givingFormData: IGivingFormSchema
) => {
  const client = await sdkClientPromise(organizationId);

  try {
    const { data } = await axios.post(
      `${client.config.cardConnectBaseUrl}/cardsecure/api/v1/ccn/tokenize`,
      {
        account: `${givingFormData.ach?.routingNumber}/${givingFormData.ach?.accountNumber}`
      }
    );

    return data.token;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return '';
  }
};
