import Text from '../Text';

type DialogContentTitleProps = {
  /**
   * The content to show as a content title within the
   * <DialogContent /> component
   */
  children: React.ReactNode;
  className?: string;
};

/**
 * The title to show within the <DialogContent /> component. This should
 * only show up when using a normal dialog.
 * If using the `success` dialog variant, this will not render.
 * See the .DialogContentTitle class selector in the sx prop of the
 * <SuccessDialog /> component.
 */
const DialogContentTitle = ({
  children,
  className
}: DialogContentTitleProps) => (
  <Text
    className={`DialogContentTitle ${className ?? ''}`}
    variant="h1"
    component="h2"
    mb={1}
  >
    {children}
  </Text>
);

export default DialogContentTitle;
