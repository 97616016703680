import BlockBaseType from './BlockBaseType';

export enum DetailType {
  DonorInformation = 'DonorInformation',
  TodaysTransaction = 'TodaysTransaction',
  CustomFields = 'CustomFields',
  ScheduleInformation = 'ScheduleInformation'
}

export interface IThankYouMessageContentTableBlock extends BlockBaseType {
  placeholders: string[];
  detailType: DetailType;
}
