import { useEffect, useState } from 'react';
import { getDonationAmountRaisedData } from 'services/donationService';
import { DonationAmountRaised } from 'types/givingForm/DonationAmountRaised';
import { useGivingFormData } from './useGivingFormData';

const useDonationAmountRaised = (): DonationAmountRaised => {
  const { givingFormId, organizationId } = useGivingFormData();
  const [data, setData] = useState<DonationAmountRaised | null>(null);

  const givingFromIduuid = givingFormId?.startsWith('edit-')
    ? givingFormId.slice(5)
    : givingFormId;

  useEffect(() => {
    const fetch = async () => {
      if (organizationId && givingFromIduuid) {
        try {
          const result = await getDonationAmountRaisedData(
            organizationId,
            givingFromIduuid
          );
          setData(result);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    };
    fetch();
  }, [givingFromIduuid, organizationId]);

  return data ?? {};
};

export default useDonationAmountRaised;
