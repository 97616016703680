import { Theme, alpha, createTheme, darken, lighten } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    text: true;
    contained: false;
    outlined: false;
  }

  interface ButtonPropsSizeOverrides {
    large: false;
  }
}

export const createButtonTheme = (currTheme: Theme): Theme => {
  const {
    palette: { primary, lightGray, darkGray, common },
    typography: { h4, h6 }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            height: '3rem',
            borderRadius: '.5rem',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            padding: '0 1.5rem',
            '&.Mui-disabled': {
              opacity: 0.3
            },
            '&.Mui-focusVisible': {
              outline: `solid 2px ${alpha(primary.main, 0.5)}`
            },
            ...h4
          },
          sizeSmall: {
            height: '2rem',
            ...h6
          }
        },
        defaultProps: {
          disableRipple: false,
          disableFocusRipple: true
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              backgroundColor: primary.main,
              color: primary.contrastText,
              '&:hover': {
                backgroundColor: `${darken(primary.main, 0.2)}`
              },
              '&:disabled': {
                color: 'white'
              }
            }
          },
          {
            props: { variant: 'secondary' },
            style: {
              color: primary.main,
              background: common.white,
              border: `1px solid ${primary.main}`,
              '&:hover': {
                backgroundColor: `${lighten(primary.main, 0.9)}`
              },
              '&:disabled': {
                color: primary.main
              }
            }
          },
          {
            props: { variant: 'tertiary' },
            style: {
              color: darkGray.main,
              border: `1px solid ${lightGray.main}`,
              '&:hover': {
                backgroundColor: `${lighten(primary.main, 0.9)}`
              },
              '&:disabled': {
                color: darkGray.main
              }
            }
          },
          {
            props: { variant: 'text' },
            style: {
              color: primary.main,
              '&:hover': {
                backgroundColor: `${lighten(primary.main, 0.9)}`
              },
              '&:disabled': {
                color: primary.main
              }
            }
          }
        ]
      }
    }
  });

  return theme;
};
