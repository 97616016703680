export const getTimeBetweenInSeconds = (x: Date, y: Date): number =>
  x && y ? +(Math.abs(x.getTime() - y.getTime()) / 1000).toFixed(1) : 0;

export const getISO8601DateString = (date: Date) => {
  const isoString = date.toISOString(); // 2023-02-14T16:57:29.000Z

  const formattedDate = `${isoString.slice(0, 19)}${isoString.slice(
    23,
    29
  )}:00`; // 2023-02-14T16:57:29-05:00
  return formattedDate;
};
