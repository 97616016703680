import { useEffect } from 'react';

/**
 * If you want to change the position of the reCAPTCHA modal (the challenge that appears when reCAPTCHA thinks the user might be a bot),
 * you're somewhat limited because Google doesn't officially support custom styling or positioning of the reCAPTCHA challenge box.
 */

export const useChangeRecaptchaPosition = () => {
  useEffect(() => {
    const updateRecaptchaIframePos = () => {
      const iframes = document.querySelectorAll('iframe');
      let recaptchaIframe: HTMLIFrameElement | undefined;
      iframes.forEach((iframe) => {
        if (
          iframe.src.startsWith('https://www.google.com/recaptcha/api2/bframe')
        ) {
          recaptchaIframe = iframe;
        }
      });

      const submitButton = document.getElementById('GF-SubmitButton');
      if (recaptchaIframe && submitButton) {
        const iframeContainer = recaptchaIframe.parentElement;
        const recaptchaModalContainer = iframeContainer?.parentElement;

        if (iframeContainer && recaptchaModalContainer) {
          const submitButtonTopPos =
            submitButton.getBoundingClientRect().top +
            document.documentElement.scrollTop;
          const modalHeight = parseInt(iframeContainer.style.height, 10);
          iframeContainer.style.top = `${Math.max(
            submitButtonTopPos - modalHeight - 50,
            0
          )}px`;
          iframeContainer.style.position = 'absolute';
          recaptchaModalContainer.style.inset = 'unset';
          recaptchaModalContainer.style.top = '0px';
          recaptchaModalContainer.style.bottom = '0px';
          recaptchaIframe.style.border = '1px solid #dadce8';
        }
      }
    };
    const interval = setInterval(updateRecaptchaIframePos, 200);
    return () => {
      clearInterval(interval);
    };
  }, []);
};
