import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

/**
 * Base TextField component that renders an MUI TextField component.
 * Wraps your label in an h5 if a string is received.
 * To read more about what props this component accepts, visit https://mui.com/components/text-fields/
 */
const TextField = ({
  ...props
}: TextFieldProps & { name: string }): JSX.Element => (
  <MuiTextField {...props} />
);

export default TextField;
