import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGivingFormData } from './useGivingFormData';

type RecaptchaCheckboxContextType = {
  checkboxCaptchaToken: string;
  checkboxRecaptchaRef: React.MutableRefObject<ReCAPTCHA | null>;
  handleCheckboxRecaptcha: (token: string | null) => void;
  useCheckbox: boolean;
};

type RecaptchaCheckboxProviderProps = {
  children: React.ReactNode;
};

const RecaptchaCheckboxContext =
  createContext<RecaptchaCheckboxContextType | null>(null);

export const useRecaptchaCheckbox = () => {
  const context = useContext(RecaptchaCheckboxContext);
  if (!context) {
    throw new Error(
      'Something went wrong. No context found for Recaptcha Checkbox.'
    );
  }
  return context;
};

export const RecaptchaCheckboxProvider = ({
  children
}: RecaptchaCheckboxProviderProps): JSX.Element => {
  const [checkboxCaptchaToken, setCheckboxCapchaToken] = useState<string>('');
  const [useCheckbox, setUseCheckbox] = useState(false);
  const checkboxRecaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { transactionStats } = useGivingFormData();

  const handleCheckboxRecaptcha = useCallback((token: string | null) => {
    setCheckboxCapchaToken(token || '');
  }, []);

  useEffect(() => {
    const refreshCaptcha = () => {
      if (checkboxRecaptchaRef.current && checkboxCaptchaToken) {
        checkboxRecaptchaRef.current.reset();
        setCheckboxCapchaToken('');
      }
    };

    let tokenRefreshTimeout: NodeJS.Timeout | null = null;

    if (checkboxCaptchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 110000); // 110 seconds
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout);
      }
    };
  }, [checkboxCaptchaToken]);

  useEffect(() => {
    if (transactionStats) {
      const failed = transactionStats?.failed ?? 0;
      const gatewayProcessingFailed =
        transactionStats?.gatewayProcessingFailed ?? 0;
      const recentDeclines = failed + gatewayProcessingFailed;
      if (recentDeclines >= 10) {
        setUseCheckbox(true);
      }
    }
  }, [transactionStats]);

  const checkboxRecaptchaValue: RecaptchaCheckboxContextType = useMemo(
    () => ({
      checkboxCaptchaToken,
      useCheckbox,
      checkboxRecaptchaRef,
      handleCheckboxRecaptcha
    }),
    [
      checkboxCaptchaToken,
      useCheckbox,
      checkboxRecaptchaRef,
      handleCheckboxRecaptcha
    ]
  );

  return (
    <RecaptchaCheckboxContext.Provider value={checkboxRecaptchaValue}>
      {children}
    </RecaptchaCheckboxContext.Provider>
  );
};
