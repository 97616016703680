import { Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from 'components/lib/Checkbox';
import Text from 'components/lib/Text';
import { IGiveAnonymouslyBlock } from 'types';
import './GiveAnonymously.scss';

const GiveAnonymously = ({ message }: IGiveAnonymouslyBlock): JSX.Element => {
  const { control, getValues } = useFormContext();
  return (
    <div id="GF-GiveAnonymously">
      <Stack direction="row" alignItems="center" spacing={0.25}>
        <Controller
          name="anonymousGift"
          defaultValue={getValues('anonymousGift')}
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <Checkbox
              {...field}
              key={value}
              value={value}
              checked={value}
              className={`GF-GiveAnonymously__checkbox${
                value ? '--selected' : ''
              }`}
            />
          )}
        />
        <Text variant="caption" className="GF-GiveAnonymously__label">
          {message || 'I would like to give my donation anonymously'}
        </Text>
      </Stack>
    </div>
  );
};

export default GiveAnonymously;
