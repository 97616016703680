import { SubmitDonationDataType } from 'services/donationService';
import PlaceholderTable from 'components/PlaceholderTable';
import { usePlaceholderData } from 'hooks/usePlaceholderData';
import { Donation } from 'types';
import {
  DetailType,
  IThankYouMessageContentTableBlock
} from 'types/givingForm/ThankYouMessageContentTableBlock';
import './ContentTableBlock.scss';

export type IContentTableBlockProps = IThankYouMessageContentTableBlock & {
  givingFormSubmission: SubmitDonationDataType | null;
  donationResponse: Donation | null;
  isEditMode: boolean;
};

const ContentTableBlock = ({
  placeholders,
  givingFormSubmission,
  donationResponse,
  isEditMode,
  detailType
}: IContentTableBlockProps) => {
  const getPlaceholder = usePlaceholderData(
    givingFormSubmission,
    donationResponse,
    isEditMode
  );

  const isTransaction = donationResponse?.transactionCreatedAt ?? false;
  const isRecurringOption = givingFormSubmission?.recurringOption ?? false;

  if (!isEditMode) {
    if (!isTransaction && detailType === DetailType.TodaysTransaction) {
      return null;
    }
    if (!isRecurringOption && detailType === DetailType.ScheduleInformation) {
      return null;
    }
  }

  const mapper = (placeholder: string): { label: string; value: string } => {
    if (detailType === DetailType.CustomFields) {
      return {
        label: placeholder as string,
        value: isEditMode
          ? `{{${placeholder}*}}`
          : getPlaceholder(`${placeholder}*`) ?? `{{${placeholder}*}}`
      };
    }
    return {
      label: placeholder as string,
      value: isEditMode
        ? `{{${placeholder}}}`
        : getPlaceholder(placeholder) ?? `{{${placeholder}}}`
    };
  };

  return (
    <PlaceholderTable
      className="GF-ContentTable"
      tableData={placeholders.map(mapper)}
    />
  );
};

export default ContentTableBlock;
