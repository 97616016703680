import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { ArcElement, Chart as ChartJS } from 'chart.js';
import clsx from 'clsx';
import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes
} from 'date-fns';
import { Doughnut } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart';
import { EditorEventTypes } from 'components/EventHub';
import Icon, { ICONS } from 'components/lib/Icon';
import { ProgressBar } from 'components/lib/ProgressBar';
import Text from 'components/lib/Text';
import useDonationAmountRaised from 'hooks/useDonationAmountRaised';
import { GoalMeterConfig, GoalMeterType, GoalSource } from 'types';
import { rgbToHex } from 'utils';
import './GoalMeter.scss';

ChartJS.register(ArcElement);

interface GoalMeterProps extends GoalMeterConfig {
  side: boolean | undefined;
}

export const GoalMeter = ({
  goalMeterType,
  goalAmount,
  goalSource,
  sectionTitle,
  goalEndDate,
  showAmountRaised,
  showGoalAmount,
  showDaysLeft,
  side
}: GoalMeterProps) => {
  const { amountRaisedForCampaign, amountRaisedForChannel, campaignEndDate } =
    useDonationAmountRaised();
  const theme = useTheme();
  const primaryColorRgb = theme.palette.primary.main;
  const lightestGrayRgb = theme.palette.lightestGray.main;
  const primaryColorHex = rgbToHex(primaryColorRgb);
  const lightestGrayHex = rgbToHex(lightestGrayRgb);
  const containerClassName = clsx({
    condense: side && goalMeterType !== GoalMeterType.PROGRESS
  });
  const [giftAmount, setGiftAmount] = useState(0);

  const amountRaised = useMemo(
    () =>
      Math.round(
        ((goalSource === GoalSource.CAMPAIGN
          ? amountRaisedForCampaign
          : amountRaisedForChannel) ?? 0) + giftAmount
      ),
    [amountRaisedForCampaign, amountRaisedForChannel, giftAmount, goalSource]
  );

  const getGoalMeterPercentage = (chartType: GoalMeterType) => {
    const percentage = goalAmount
      ? Math.min(amountRaised / goalAmount, 100)
      : 0;
    // donut and progress bar require percentage as a whole number
    // speedometer library requires percentage as a value between 0 and 1
    // returning 100% if goal exceeded to prevent goal meters from overfilling
    if (chartType === GoalMeterType.SPEEDOMETER) {
      return percentage > 1 ? 1 : percentage;
    }
    return percentage > 1 ? 100 : percentage * 100;
  };

  const renderAmounts = () => {
    if (showAmountRaised && showGoalAmount) {
      return (
        <div className="goal-meter-amounts">
          <Text variant="h2">${amountRaised}</Text>
          <Text variant="body">&nbsp;of ${goalAmount} raised</Text>
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (showAmountRaised && !showGoalAmount) {
      return (
        <div className="goal-meter-amounts">
          <Text variant="h2">${amountRaised}</Text>
          <Text variant="body">&nbsp;raised</Text>
        </div>
      );
    } else if (!showAmountRaised && showGoalAmount) {
      return (
        <div className="goal-meter-amounts">
          <Text variant="h5">Goal:&nbsp;</Text>
          <Text variant="body">${goalAmount}</Text>
        </div>
      );
    }
    return null;
  };

  const circleMeterData = {
    datasets: [
      {
        data: [
          getGoalMeterPercentage(GoalMeterType.CIRCLE),
          100 - getGoalMeterPercentage(GoalMeterType.CIRCLE)
        ],
        backgroundColor: [primaryColorRgb, lightestGrayRgb],
        borderColor: primaryColorRgb,
        borderWidth: 1
      }
    ]
  };

  const circleMeterOptions = {
    cutout: '80%',
    events: [],
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    layout: {
      padding: 0
    }
  };

  const getGoalMeter = (meterType: GoalMeterType) => {
    switch (meterType) {
      case GoalMeterType.PROGRESS:
        return (
          <ProgressBar
            className="goal-meter-progress-bar"
            variant="determinate"
            value={getGoalMeterPercentage(meterType)}
          />
        );
      case GoalMeterType.CIRCLE:
        return (
          <div className={`goal-meter-circle-container ${containerClassName}`}>
            <Doughnut
              className="goal-meter-circle"
              data={circleMeterData}
              options={circleMeterOptions}
            />
          </div>
        );
      case GoalMeterType.SPEEDOMETER:
        return (
          <div
            className={`goal-meter-speedometer-container ${containerClassName}`}
          >
            <GaugeChart
              animate={false}
              arcPadding={0}
              arcWidth={0.15}
              arcsLength={[
                getGoalMeterPercentage(GoalMeterType.SPEEDOMETER),
                1 - getGoalMeterPercentage(GoalMeterType.SPEEDOMETER)
              ]}
              className="speedometer"
              cornerRadius={0}
              colors={[`#${primaryColorHex}`, `#${lightestGrayHex}`]}
              hideText
              needleColor={`#${primaryColorHex}`}
              needleBaseColor={`#${primaryColorHex}`}
              nrOfLevels={2}
              percent={getGoalMeterPercentage(GoalMeterType.SPEEDOMETER)}
              style={{
                height: '100%' // necessary per GuageChart docs to prevent a rendering bug
              }}
            />
          </div>
        );
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  };

  const getTimeLeft = () => {
    let endDate;

    if (goalSource === GoalSource.CHANNEL) {
      if (!goalEndDate) return null;
      endDate = new Date(goalEndDate);
    } else {
      if (!campaignEndDate) return null;
      endDate = new Date(campaignEndDate);
    }
    const today = new Date();
    const diffInDays = differenceInCalendarDays(endDate, today);
    const diffInHours = differenceInHours(endDate, today);
    const diffInMinutes = differenceInMinutes(endDate, today);

    if (diffInDays > 0) return `${diffInDays} Days Left`;
    if (diffInHours > 0) return `${diffInHours} Hours Left`;
    if (diffInMinutes > 0) return `${diffInMinutes} Minutes Left`;
    return '0 Minutes Left';
  };

  useEffect(() => {
    const givingFormListener = (e: MessageEvent) => {
      if (e.data?.name === EditorEventTypes.DonationComplete) {
        const { giftAmount: donationAmount } = e.data;
        setGiftAmount(donationAmount);
      }
    };
    window.addEventListener('message', givingFormListener);

    return () => {
      window.removeEventListener('message', givingFormListener);
    };
  }, []);

  return (
    <div className={`goal-meter ${containerClassName}`}>
      {sectionTitle && (
        <Text className="goal-meter-title" variant="h3">
          {sectionTitle}
        </Text>
      )}
      <div
        className={
          goalMeterType === GoalMeterType.PROGRESS
            ? ''
            : `meter-container ${containerClassName}`
        }
      >
        {getGoalMeter(goalMeterType)}
        <div
          className={clsx('amount-time-container', {
            column: goalMeterType !== GoalMeterType.PROGRESS
          })}
        >
          {renderAmounts()}
          {showDaysLeft && (
            <div className="time-container">
              <Icon
                className="goal-meter-time-icon"
                icon={ICONS.CALENDAR_TIME}
              />
              <Text className="goal-meter-time" variant="h6">
                {getTimeLeft()}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
