/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable class-methods-use-this */
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { EditorEventTypes, EventHub } from 'components/EventHub/EventHub.types';
import {
  FormEventType,
  useEventTracker,
  useGivingFormData,
  useUserInfo
} from 'hooks';
import {
  deepParse,
  getAttributeWithDataFallback,
  getTimeBetweenInSeconds,
  supportsUserEntry
} from 'utils';

// these values cannot be managed using useState
// because they are used outside of React context
export interface ActivityState {
  formValues?: any;
  activeElement?: { name: string; value: any; timestamp: Date } | undefined;
  activeIframe?: { name: string | null; timestamp: Date };
  windowBlurTimestamp?: Date;
  lastActivityTimestamp?: Date; // last time mouse was moved or key was pressed
}

declare global {
  interface Window {
    // allows access outside of the React context
    activityState: ActivityState;
  }
}

export interface FormActivityMonitorProps {
  sessionId: string;
  eventHub: EventHub;
}

export const FormActivityMonitor = ({
  sessionId,
  eventHub
}: FormActivityMonitorProps) => {
  const [pageLoadTimestamp] = useState<Date>(new Date());
  const userInfo = useUserInfo();
  const eventTracker = useEventTracker(sessionId, userInfo.getVisitorId());
  const formValues: any = useWatch();
  const { referrer } = useGivingFormData();

  const numberOfSecondsToConsiderIdle = 30;

  const handleFormLoadAndUnload = () => {
    // form load
    userInfo.getIpInfo().then((ipInfo) => {
      const { userAgent, language } = window.navigator;
      const resolution = `${window.screen.width}x${window.screen.height}`;

      eventTracker.log(
        'formLoad',
        {
          referrer,
          ipAddress: ipInfo?.ipAddress,
          location: ipInfo?.location,
          userAgent,
          language,
          resolution
        },
        false,
        pageLoadTimestamp
      );
    });

    // form unload
    window.addEventListener(
      'beforeunload',
      () => {
        eventTracker.log('formUnload', {
          totalTimeInSeconds: getTimeBetweenInSeconds(
            pageLoadTimestamp,
            new Date()
          )
        });
      },
      false
    );
  };

  const handleFormInteractions = () => {
    const getElementEventType = (
      isEntryField: boolean,
      isValueChange: boolean
    ): FormEventType => {
      if (isEntryField) {
        return isValueChange ? 'entry' : 'focus';
      }

      return isValueChange ? 'selection' : 'click';
    };

    document.addEventListener(
      'focusin',
      (event) => {
        const element = event.target as Element;
        const name = getAttributeWithDataFallback(element, 'name');

        // any element with a tabIndex will trigger this event
        // we are only concerned about focusable elements with a name attribute
        if (name) {
          const value = deepParse(window.activityState.formValues, name);
          // needed for reference in focusout
          window.activityState.activeElement = {
            name,
            value,
            timestamp: new Date()
          };
        } else {
          delete window.activityState.activeElement;
        }
      },
      true
    );

    document.addEventListener(
      'focusout',
      (event) => {
        const element = event.target as Element;
        const name = getAttributeWithDataFallback(element, 'name');

        // clone in case new element is brought into focus before timeout
        const focused = { ...window.activityState.activeElement };
        delete window.activityState.activeElement;

        // these should always match!
        if (focused?.name && name && focused?.name !== name) {
          throw new Error('Focused element mismatch');
        }

        if (name) {
          // setTimeout is needed to ensure React updates always occur before logging
          // and also prevents the UI from being blocked in process
          setTimeout(() => {
            const newValue = deepParse(window.activityState.formValues, name);
            const oldValue = focused?.value;
            const isEntryField = supportsUserEntry(element);
            const isValueChange = newValue !== oldValue;
            // event type based on entry type and value change
            const type = getElementEventType(isEntryField, isValueChange);
            const details: any = isValueChange
              ? { target: name, oldValue, newValue }
              : { target: name };

            if (isEntryField && focused?.timestamp) {
              details.durationInSeconds = getTimeBetweenInSeconds(
                focused.timestamp,
                new Date()
              );
            }

            eventTracker.log(type, details);
          });
        }
      },
      true
    );
  };

  const handleScrolling = () => {
    // scrolling can only be monitored from parent frame since
    // the iframe is resized to always match the body height
    eventHub?.subscribe(EditorEventTypes.PageScroll, (x) =>
      eventTracker.log('pageScroll', x.payload, true)
    );
  };

  const handleFormActiveAndInactive = () => {
    window.addEventListener(
      'blur',
      () => {
        // the same event is fired for any frame or tab change
        // so we need to know if the blur was a change to an iframe
        if (document.activeElement?.tagName === 'IFRAME') {
          const name =
            document.activeElement.getAttribute('title') ||
            getAttributeWithDataFallback(document.activeElement, 'name');
          window.activityState.activeIframe = { name, timestamp: new Date() };
        } else {
          window.activityState.windowBlurTimestamp = new Date();
          eventTracker.log('formInactive');
        }
      },
      false
    );

    window.addEventListener(
      'focus',
      () => {
        const iframe = window.activityState.activeIframe;
        // the same event is fired for any frame or tab change
        // so we need to know if they are returning from an iframe
        if (iframe) {
          eventTracker.log('focus', {
            target: iframe.name,
            durationInSeconds: getTimeBetweenInSeconds(
              iframe.timestamp,
              new Date()
            )
          });
          delete window.activityState.activeIframe;
        } else {
          eventTracker.log('formActive', {
            inactiveTimeInSeconds: getTimeBetweenInSeconds(
              window.activityState.windowBlurTimestamp || pageLoadTimestamp,
              new Date()
            )
          });
          delete window.activityState.windowBlurTimestamp;
        }
      },
      false
    );
  };

  const handleIdleness = () => {
    const checkForIdleness = (newActivityTimestamp = new Date()) => {
      const durationInSeconds = getTimeBetweenInSeconds(
        window.activityState.lastActivityTimestamp || pageLoadTimestamp,
        newActivityTimestamp
      );

      if (durationInSeconds > numberOfSecondsToConsiderIdle) {
        eventTracker.log(
          'userIdle',
          { durationInSeconds },
          false,
          newActivityTimestamp
        );
      }

      window.activityState.lastActivityTimestamp = newActivityTimestamp;
    };

    window.addEventListener('mousemove', () => checkForIdleness());
    window.addEventListener('keypress', () => checkForIdleness());
    window.addEventListener('beforeunload', () => checkForIdleness());

    // handles activity OUTSIDE of Giving Form
    eventHub?.subscribe(EditorEventTypes.UserActivity, (x) => {
      const lastActivityOnParent: Date = x.payload;
      // only need to handle if more resent that last activity inside Giving Form
      if (
        !window.activityState.lastActivityTimestamp ||
        lastActivityOnParent > window.activityState.lastActivityTimestamp
      ) {
        checkForIdleness(lastActivityOnParent);
      }
    });
  };

  const handleDonationSubmit = () => {
    const logDonationPayload = (event: CustomEvent) => {
      eventTracker.log('donationSubmit', event.detail);
    };

    window.addEventListener('donationSubmit', ((event: CustomEvent) =>
      logDonationPayload(event)) as EventListener);
  };

  useEffect(() => {
    // Setup event monitors
    window.activityState = {};
    handleFormLoadAndUnload();
    handleFormInteractions();
    handleScrolling();
    handleFormActiveAndInactive();
    handleIdleness();
    handleDonationSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // this makes formValues accessable outside of React's context
    window.activityState.formValues = formValues;
  }, [formValues]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
