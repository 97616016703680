import { Box } from '@mui/material';
import parse from 'html-react-parser';
import { IHostedPageCustomContentBlock } from 'types/givingForm/HostedPage';
import './HostedPageCustomContentBlock.scss';

export type IHostedPageCustomContentBlockProps = Omit<
  IHostedPageCustomContentBlock,
  'blockType'
>;

export const HostedPageCustomContentBlock = ({
  html
}: IHostedPageCustomContentBlockProps) => (
  <Box className="HP-CustomContent">{parse(html)}</Box>
);
