import { TextFieldProps } from '@mui/material';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import TextField from '.';

export type NumberTextFieldProps = Omit<
  NumberFormatProps<TextFieldProps & { name: string }>,
  'onChange'
> & {
  onChange?: (value: string) => void;
  name: string;
};

const NumberTextField = ({
  name,
  onChange,
  ...props
}: NumberTextFieldProps): JSX.Element => (
  <NumberFormat
    name={name}
    onValueChange={(values) => onChange?.(values.value)}
    customInput={TextField}
    {...props}
  />
);
export default NumberTextField;
