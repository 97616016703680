import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { SubmitDonationDataType } from 'services/donationService';
import { calculateTransactionAmount } from 'components/lib/BackendLib/calculateTransactionAmount';
import { useGivingFormData } from 'hooks';
import {
  AllPaymentOptions,
  Donation,
  DonorFees,
  IGivingFormConfig,
  RecurringOptionsType
} from 'types';
import { getDonationAmount } from 'utils/donationUtils';

const getFormattedDateTimeString = (
  dateTimeString: string,
  dateFormat = 'MMMM dd, yyyy, h:mmaa z'
): string =>
  dateTimeString ? format(parseISO(dateTimeString), dateFormat) : '';

const dateFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/gi;

const dateReviver = (_key: string, value: unknown) => {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return getFormattedDateTimeString(value);
  }

  return value;
};

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const paymentMethodMap: { [key: string]: string } = {
  ECHECK: 'eCheck',
  GOOGLEPAY: 'Google Pay',
  APPLEPAY: 'Apple Pay',
  PAYPAL: 'PayPal'
};

type PlaceholderInputs = {
  givingFormConfig?: IGivingFormConfig | undefined;
  givingFormSubmission?: SubmitDonationDataType | null;
  donationResponse?: Donation | null;
  donorFees?: DonorFees;
};

type PlaceholderResolver = {
  [key: string]: (inputs: PlaceholderInputs) => string;
};

const placeholderResolver: PlaceholderResolver = {
  'Donor Name': (inputs) => inputs?.donationResponse?.fullName ?? 'N/A',
  'Company Name': (inputs) =>
    inputs?.givingFormSubmission?.billing?.companyName ?? 'N/A',
  'Donor Address': (inputs) => {
    const address2 = inputs?.givingFormSubmission?.billing?.address2;
    const donorAddress = `${inputs?.givingFormSubmission?.billing?.address1}\n${
      address2 ? `${address2}\n` : ''
    }${inputs?.givingFormSubmission?.billing?.city}, ${
      inputs?.givingFormSubmission?.billing?.state
    } ${inputs?.givingFormSubmission?.billing?.postalCode}`;
    return donorAddress;
  },
  'Donor Phone': (inputs) =>
    inputs?.givingFormSubmission?.billing?.phoneNumber ?? 'N/A',
  'Donor Email': (inputs) =>
    inputs?.givingFormSubmission?.billing?.email ?? 'N/A',
  'Email Opt-In': (inputs) =>
    inputs?.givingFormSubmission?.emailOptIn ?? false ? 'Yes' : 'No',
  'Donation Amount': (inputs) => {
    const amount = getDonationAmount(
      inputs?.givingFormSubmission?.designation,
      inputs?.givingFormSubmission?.giftAmount
    );
    return formatter.format(amount as number);
  },
  'Transaction Fee': (inputs) => {
    const isCoverTransactionEnabled =
      inputs?.givingFormSubmission?.coverTransactionFee ?? false;
    const giftAmount = getDonationAmount(
      inputs?.givingFormSubmission?.designation,
      inputs?.givingFormSubmission?.giftAmount
    );
    const fee = calculateTransactionAmount(
      giftAmount,
      inputs?.givingFormSubmission?.paymentOption as AllPaymentOptions,
      inputs?.donorFees as DonorFees
    );
    return isCoverTransactionEnabled ? formatter.format(fee) : 'N/A';
  },
  Designation: (inputs) =>
    inputs?.givingFormSubmission?.designation?.reduce((accumulator, value) => {
      if (!accumulator) {
        return `${value.title} (${formatter.format(value.amount)})`;
      }

      return `${accumulator}, ${value.title} (${formatter.format(
        value.amount
      )})`;
    }, '') ?? 'N/A',
  'Anonymous Gift': (inputs) =>
    inputs?.givingFormSubmission?.anonymousGift ?? false ? 'Yes' : 'No',
  'Payment Method': (inputs) => {
    const paymentOption =
      inputs?.givingFormSubmission?.paymentOption ?? AllPaymentOptions.CREDIT;
    const cardBrand = inputs?.donationResponse?.cardType ?? '';
    const lastFourDigits = inputs?.donationResponse?.lastFourDigits ?? '';

    const paymentMethod =
      paymentOption === AllPaymentOptions.CREDIT ||
      paymentOption === AllPaymentOptions.DEBIT
        ? `${capitalizeFirstLetter(cardBrand)} xxxx${lastFourDigits}`
        : paymentMethodMap[paymentOption];

    return paymentMethod;
  },
  'Payment Expiration': (inputs) => {
    const paymentOption =
      inputs?.givingFormSubmission?.paymentOption ?? AllPaymentOptions.CREDIT;
    return paymentOption === AllPaymentOptions.CREDIT
      ? inputs?.givingFormSubmission?.creditCard?.expirationDate ?? 'N/A'
      : 'N/A';
  },
  'Billing Frequency': (inputs) =>
    inputs?.givingFormSubmission?.recurringOption ?? RecurringOptionsType.Once,
  'Billing Start Date': (inputs) => {
    const billingFrequency =
      inputs?.givingFormSubmission?.recurringOption ??
      RecurringOptionsType.Once;
    const unformatted = inputs?.donationResponse?.billingStartDate as string;
    const formatted = getFormattedDateTimeString(unformatted, 'MMMM dd, yyyy');
    return billingFrequency !== RecurringOptionsType.Once ? formatted : 'N/A';
  },
  'Schedule Number': (inputs) => {
    const billingFrequency =
      inputs?.givingFormSubmission?.recurringOption ??
      RecurringOptionsType.Once;
    const unformatted = inputs?.donationResponse?.scheduleId ?? '';
    const formatted = unformatted.substring(0, 8).toLocaleUpperCase();
    return billingFrequency !== RecurringOptionsType.Once ? formatted : 'N/A';
  },
  'Authorization Number': (inputs) => {
    const unformatted = inputs?.donationResponse?.transactionId ?? '';
    const formatted = unformatted.substring(0, 8).toLocaleUpperCase();
    return formatted;
  },
  'Transaction Date': (inputs) =>
    getFormattedDateTimeString(
      inputs?.donationResponse?.transactionCreatedAt as string
    ),
  'Transaction Amount': (inputs) =>
    formatter.format(inputs?.donationResponse?.transactionTotal as number),
  'Deductible Amount': (inputs) =>
    formatter.format(inputs?.donationResponse?.deductibleAmount as number),
  'Thank You Gift Name': (inputs) =>
    inputs?.givingFormSubmission?.thankYouGift?.giftName ?? 'N/A',
  'Thank You Gift Description': (inputs) =>
    inputs?.givingFormSubmission?.thankYouGift?.description ?? 'N/A',
  'Tribute Name': (inputs) => {
    const tributeFullName = `${
      inputs?.givingFormSubmission?.tribute?.tributeFirstName
    }${' '}${inputs?.givingFormSubmission?.tribute?.tributeLastName}`;
    return inputs?.givingFormSubmission?.tribute?.tributeFirstName
      ? tributeFullName
      : 'N/A';
  },
  'Tribute Recipient Email': (inputs) =>
    inputs?.givingFormSubmission?.tribute?.tributeEmail ?? 'N/A',
  'Tribute Message': (inputs) =>
    inputs?.givingFormSubmission?.tribute?.ecardCustomMessage ?? 'N/A',
  'Fair Market Value': (inputs) => {
    const fairMarketValue =
      inputs?.givingFormSubmission?.thankYouGift?.fairMarketValue;
    return fairMarketValue
      ? formatter.format(fairMarketValue as number)
      : 'N/A';
  }
};

export const usePlaceholderData = (
  givingFormSubmission:
    | (SubmitDonationDataType & { customFields?: { [key: string]: string } })
    | null,
  donationResponse: Donation | null,
  isEditMode: boolean
) => {
  const { config: givingFormConfig, donorFees } = useGivingFormData();

  const allData = useMemo(() => {
    if (isEditMode) {
      return {};
    }

    return {
      givingFormSubmission,
      donationResponse,
      givingFormConfig,
      donorFees
    };
  }, [
    donationResponse,
    givingFormConfig,
    givingFormSubmission,
    isEditMode,
    donorFees
  ]);

  const getPlaceholder = (placeholder: string) => {
    // removes the &nbsp; from the WYSIWYG placeholders
    const formattedPlaceholder = placeholder.replace(/&nbsp;/gi, ' ');

    // handle custom fields
    if (placeholder.endsWith('*')) {
      const customFields = allData?.givingFormSubmission?.customFields ?? '{}';
      const data = JSON.parse(JSON.stringify(customFields), dateReviver) ?? {};

      const value = data[placeholder.slice(0, -1)] ?? 'N/A';
      return value;
    }

    // otherwise resolve placeholder
    try {
      return placeholderResolver[formattedPlaceholder](allData);
    } catch (e) {
      throw new Error(
        `Placeholder: {{${formattedPlaceholder}}} does not exist.`
      );
    }
  };

  return getPlaceholder;
};
