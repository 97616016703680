import { useTheme } from '@mui/material';
import './RecurringGiftSvg.scss';

export const RecurringGiftSvg = () => {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const primaryContrastColor = theme.palette.getContrastText(primary);

  return (
    <svg
      id="GF-RecurringGiftSvg"
      className="GF-RecurringGiftSvg"
      width="138"
      height="169"
      viewBox="0 0 138 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <ellipse
          cx="68.2975"
          cy="84.6407"
          rx="62.8323"
          ry="85.9063"
          transform="rotate(18.9244 68.2975 84.6407)"
          fill="#EEF0FC"
        />
      </g>
      <path d="M124.47 133.78H8.49219V46.1328H124.47V133.78Z" fill="white" />
      <path
        d="M36.6009 64.4213C39.1505 64.4213 41.2174 62.3394 41.2174 59.7712C41.2174 57.203 39.1505 55.1211 36.6009 55.1211C34.0513 55.1211 31.9844 57.203 31.9844 59.7712C31.9844 62.3394 34.0513 64.4213 36.6009 64.4213Z"
        fill="#9A99A8"
      />
      <path
        d="M47.2563 56.6758C47.0525 56.6761 46.8572 56.758 46.7132 56.9033C46.5692 57.0486 46.4883 57.2455 46.4883 57.4508C46.4883 57.6561 46.5692 57.853 46.7132 57.9983C46.8572 58.1436 47.0525 58.2254 47.2563 58.2258H103.433C103.637 58.2254 103.832 58.1436 103.976 57.9983C104.12 57.853 104.201 57.6561 104.201 57.4508C104.201 57.2455 104.12 57.0486 103.976 56.9033C103.832 56.758 103.637 56.6761 103.433 56.6758H47.2563Z"
        fill="#9A99A8"
      />
      <path
        d="M47.2515 61.3242C47.0475 61.3238 46.8516 61.405 46.707 61.5501C46.5624 61.6951 46.4809 61.8921 46.4805 62.0976C46.48 62.3031 46.5607 62.5004 46.7047 62.6461C46.8487 62.7918 47.0442 62.8738 47.2483 62.8743H82.7638C82.865 62.8744 82.9652 62.8545 83.0587 62.8157C83.1522 62.7768 83.2372 62.7197 83.3088 62.6477C83.3803 62.5758 83.4371 62.4903 83.4759 62.3961C83.5146 62.302 83.5346 62.2011 83.5346 62.0992C83.5346 61.9973 83.5146 61.8964 83.4759 61.8023C83.4371 61.7082 83.3803 61.6227 83.3088 61.5507C83.2372 61.4787 83.1522 61.4217 83.0587 61.3828C82.9652 61.3439 82.865 61.324 82.7638 61.3242H47.2515Z"
        fill="#9A99A8"
      />
      <path
        d="M124.47 133.78H8.49219V46.1328H124.47V133.78ZM9.79027 132.472H123.172V47.4404H9.79027V132.472Z"
        fill="#9A99A8"
      />
      <path
        d="M14.9648 72.8633V124.994H117.978V72.8633H14.9648ZM117.483 85.7713H92.3488V73.3621H117.483V85.7713ZM66.7193 85.7713V73.3621H91.8536V85.7713H66.7193ZM91.8536 86.2701V98.6793H66.7193V86.2701H91.8536ZM66.224 85.7713H41.0897V73.3621H66.224V85.7713ZM66.224 86.2701V98.6793H41.0897V86.2701H66.224ZM40.5944 98.6793H15.4601V86.2701H40.5944V98.6793ZM40.5944 99.1781V111.587H15.4601V99.1781H40.5944ZM41.0897 99.1781H66.224V111.587H41.0897V99.1781ZM66.224 112.086V124.495H41.0897V112.086H66.224ZM66.7193 112.086H91.8536V124.495H66.7193V112.086ZM66.7193 111.587V99.1781H91.8536V111.587H66.7193ZM92.3488 99.1781H117.483V111.587H92.3488V99.1781ZM92.3488 98.6793V86.2701H117.483V98.6793H92.3488ZM40.5944 73.3621V85.7713H15.4601V73.3621H40.5944ZM15.4601 112.086H40.5944V124.495H15.4601V112.086ZM92.3488 124.495V112.086H117.483V124.495H92.3488Z"
        fill="#9A99A8"
      />
      <path
        d="M57.263 86.8894L47.013 86.6556C45.9877 82.6551 46.4493 78.846 47.2675 75.3359L57.5175 75.5697C56.4807 79.2601 56.3931 83.1555 57.263 86.8894Z"
        fill={primary}
      />
      <path
        d="M49.0234 77.8343L49.0289 77.4414L54.3393 77.6325L54.3336 78.0253L49.0234 77.8343Z"
        fill={primaryContrastColor}
      />
      <path
        d="M49.0098 79.4085L49.0152 79.0156L54.3254 79.2069L54.3199 79.5998L49.0098 79.4085Z"
        fill={primaryContrastColor}
      />
      <path
        d="M48.9805 80.9749L48.9862 80.582L54.2963 80.7733L54.2909 81.1662L48.9805 80.9749Z"
        fill={primaryContrastColor}
      />
      <path
        d="M48.9512 82.5491L48.9566 82.1562L54.267 82.3476L54.2613 82.7404L48.9512 82.5491Z"
        fill={primaryContrastColor}
      />
      <path
        d="M48.9375 84.1194L48.9429 83.7266L54.2533 83.9179L54.2477 84.3107L48.9375 84.1194Z"
        fill={primaryContrastColor}
      />
      <path
        d="M34.9837 115.815L24.7337 115.581C23.7084 111.581 24.17 107.772 24.9882 104.262L35.2382 104.495C34.2014 108.186 34.1138 112.081 34.9837 115.815Z"
        fill="#F0F0F0"
      />
      <path
        d="M26.7383 106.768L26.7437 106.375L32.0541 106.566L32.0484 106.959L26.7383 106.768Z"
        fill="white"
      />
      <path
        d="M26.709 108.342L26.7144 107.949L32.0246 108.141L32.0191 108.533L26.709 108.342Z"
        fill="white"
      />
      <path
        d="M26.6953 109.912L26.701 109.52L32.0112 109.711L32.0057 110.104L26.6953 109.912Z"
        fill="white"
      />
      <path
        d="M26.6719 111.487L26.6773 111.094L31.9877 111.285L31.982 111.678L26.6719 111.487Z"
        fill="white"
      />
      <path
        d="M26.6426 113.057L26.648 112.664L31.9584 112.855L31.9527 113.248L26.6426 113.057Z"
        fill="white"
      />
      <path
        d="M88.4642 102.6L78.2142 102.367C77.1889 98.366 77.6505 94.5569 78.4687 91.0469L88.7186 91.2806C87.6819 94.971 87.5943 98.8664 88.4642 102.6Z"
        fill="#F0F0F0"
      />
      <path
        d="M80.2266 93.5452L80.232 93.1523L85.5424 93.3434L85.5367 93.7363L80.2266 93.5452Z"
        fill="white"
      />
      <path
        d="M80.2051 95.1155L80.2105 94.7227L85.5207 94.914L85.5152 95.3068L80.2051 95.1155Z"
        fill="white"
      />
      <path
        d="M80.1758 96.6897L80.1815 96.2969L85.4916 96.4882L85.4862 96.881L80.1758 96.6897Z"
        fill="white"
      />
      <path
        d="M80.1602 98.2639L80.1656 97.8711L85.476 98.0624L85.4703 98.4553L80.1602 98.2639Z"
        fill="white"
      />
      <path
        d="M80.1387 99.8343L80.1441 99.4414L85.4545 99.6327L85.4488 100.026L80.1387 99.8343Z"
        fill="white"
      />
      <path
        d="M84.0072 100.104L73.7571 99.8705C72.7319 95.8699 73.1935 92.0609 74.0116 88.5508L84.2616 88.7845C83.2248 92.4749 83.1373 96.3704 84.0072 100.104Z"
        fill={primary}
      />
      <path
        d="M75.7617 91.0569L75.7672 90.6641L81.0776 90.8551L81.0719 91.248L75.7617 91.0569Z"
        fill={primaryContrastColor}
      />
      <path
        d="M75.748 92.6272L75.7535 92.2344L81.0636 92.4257L81.0582 92.8185L75.748 92.6272Z"
        fill={primaryContrastColor}
      />
      <path
        d="M75.7266 94.1975L75.7323 93.8047L81.0424 93.996L81.037 94.3889L75.7266 94.1975Z"
        fill={primaryContrastColor}
      />
      <path
        d="M75.7031 95.7718L75.7086 95.3789L81.019 95.5702L81.0133 95.9631L75.7031 95.7718Z"
        fill={primaryContrastColor}
      />
      <path
        d="M75.6816 97.3421L75.6871 96.9492L80.9975 97.1405L80.9918 97.5334L75.6816 97.3421Z"
        fill={primaryContrastColor}
      />
      <path
        d="M121.812 122.893L111.562 122.66C110.537 118.659 110.998 114.85 111.816 111.34L122.066 111.574C121.03 115.264 120.942 119.159 121.812 122.893Z"
        fill={primary}
      />
      <path
        d="M113.568 113.842L113.574 113.449L118.884 113.641L118.879 114.033L113.568 113.842Z"
        fill={primaryContrastColor}
      />
      <path
        d="M113.547 115.412L113.552 115.02L118.863 115.211L118.857 115.604L113.547 115.412Z"
        fill={primaryContrastColor}
      />
      <path
        d="M113.533 116.987L113.539 116.594L118.849 116.785L118.844 117.178L113.533 116.987Z"
        fill={primaryContrastColor}
      />
      <path
        d="M113.51 118.553L113.515 118.16L118.826 118.351L118.82 118.744L113.51 118.553Z"
        fill={primaryContrastColor}
      />
      <path
        d="M113.473 120.127L113.478 119.734L118.789 119.926L118.783 120.319L113.473 120.127Z"
        fill={primaryContrastColor}
      />
      <path
        d="M110.757 88.8816L100.507 88.6478C99.4819 84.6473 99.9435 80.8382 100.762 77.3281L111.012 77.5619C109.975 81.2523 109.887 85.1477 110.757 88.8816Z"
        fill="#F0F0F0"
      />
      <path
        d="M102.512 79.8343L102.517 79.4414L107.828 79.6325L107.822 80.0253L102.512 79.8343Z"
        fill="white"
      />
      <path
        d="M102.49 81.4007L102.496 81.0078L107.806 81.1991L107.8 81.592L102.49 81.4007Z"
        fill="white"
      />
      <path
        d="M102.461 82.9749L102.467 82.582L107.777 82.7733L107.771 83.1662L102.461 82.9749Z"
        fill="white"
      />
      <path
        d="M102.447 84.5413L102.453 84.1484L107.763 84.3397L107.757 84.7326L102.447 84.5413Z"
        fill="white"
      />
      <path
        d="M102.418 86.1155L102.423 85.7227L107.734 85.914L107.728 86.3068L102.418 86.1155Z"
        fill="white"
      />
    </svg>
  );
};
