import { CSSProperties } from 'react';
import { Theme, createTheme } from '@mui/material';
import BodoniHeavy from 'assets/fonts/BodoniModa_72pt-Black.ttf';
import BodoniHeavyItalic from 'assets/fonts/BodoniModa_72pt-BlackItalic.ttf';
import BodoniBold from 'assets/fonts/BodoniModa_72pt-Bold.ttf';
import BodoniBoldItalic from 'assets/fonts/BodoniModa_72pt-BoldItalic.ttf';
import BodoniMediumItalic from 'assets/fonts/BodoniModa_72pt-Italic.ttf';
import BodoniMedium from 'assets/fonts/BodoniModa_72pt-Regular.ttf';
import CrimsonProHeavy from 'assets/fonts/CrimsonPro-Black.ttf';
import CrimsonProHeavyItalic from 'assets/fonts/CrimsonPro-BlackItalic.ttf';
import CrimsonProBold from 'assets/fonts/CrimsonPro-Bold.ttf';
import CrimsonProBoldItalic from 'assets/fonts/CrimsonPro-BoldItalic.ttf';
import CrimsonProMediumItalic from 'assets/fonts/CrimsonPro-Italic.ttf';
import CrimsonProMedium from 'assets/fonts/CrimsonPro-Regular.ttf';
import EbGaramondBold from 'assets/fonts/EBGaramond-Bold.ttf';
import EbGaramondBoldItalic from 'assets/fonts/EBGaramond-BoldItalic.ttf';
import EbGaramondHeavy from 'assets/fonts/EBGaramond-ExtraBold.ttf';
import EbGaramondHeavyItalic from 'assets/fonts/EBGaramond-ExtraBoldItalic.ttf';
import EbGaramondMediumItalic from 'assets/fonts/EBGaramond-Italic.ttf';
import EbGaramondMedium from 'assets/fonts/EBGaramond-Regular.ttf';
import JostBold from 'assets/fonts/Jost-Bold.ttf';
import JostBoldItalic from 'assets/fonts/Jost-BoldItalic.ttf';
import JostItalic from 'assets/fonts/Jost-Italic.ttf';
import JostMedium from 'assets/fonts/Jost-Medium.ttf';
import JostMediumItalic from 'assets/fonts/Jost-MediumItalic.ttf';
import Jost from 'assets/fonts/Jost-Regular.ttf';
import KameronBold from 'assets/fonts/Kameron-Bold.ttf';
import Kameron from 'assets/fonts/Kameron-Regular.ttf';
import LibreBaskervilleBold from 'assets/fonts/LibreBaskerville-Bold.ttf';
import LibreBaskervilleMediumItalic from 'assets/fonts/LibreBaskerville-Italic.ttf';
import LibreBaskervilleMedium from 'assets/fonts/LibreBaskerville-Regular.ttf';
import LibreFranklinHeavy from 'assets/fonts/LibreFranklin-Black.ttf';
import LibreFranklinHeavyItalic from 'assets/fonts/LibreFranklin-BlackItalic.ttf';
import LibreFranklinBold from 'assets/fonts/LibreFranklin-Bold.ttf';
import LibreFranklinBoldItalic from 'assets/fonts/LibreFranklin-BoldItalic.ttf';
import LibreFranklinMediumItalic from 'assets/fonts/LibreFranklin-Italic.ttf';
import LibreFranklinMedium from 'assets/fonts/LibreFranklin-Regular.ttf';
import MontserratHeavy from 'assets/fonts/Montserrat-Black.ttf';
import MontserratHeavyItalic from 'assets/fonts/Montserrat-BlackItalic.ttf';
import MontserratBold from 'assets/fonts/Montserrat-Bold.ttf';
import MontserratBoldItalic from 'assets/fonts/Montserrat-BoldItalic.ttf';
import MontserratMediumItalic from 'assets/fonts/Montserrat-Italic.ttf';
import MontserratMedium from 'assets/fonts/Montserrat-Regular.ttf';
import NunitoHeavy from 'assets/fonts/Nunito-Black.ttf';
import NunitoHeavyItalic from 'assets/fonts/Nunito-BlackItalic.ttf';
import NunitoBold from 'assets/fonts/Nunito-Bold.ttf';
import NunitoBoldItalic from 'assets/fonts/Nunito-BoldItalic.ttf';
import NunitoMediumItalic from 'assets/fonts/Nunito-Italic.ttf';
import NunitoMedium from 'assets/fonts/Nunito-Regular.ttf';
import PoppinsHeavy from 'assets/fonts/Poppins-Black.ttf';
import PoppinsHeavyItalic from 'assets/fonts/Poppins-BlackItalic.ttf';
import PoppinsBold from 'assets/fonts/Poppins-Bold.ttf';
import PoppinsBoldItalic from 'assets/fonts/Poppins-BoldItalic.ttf';
import PoppinsMediumItalic from 'assets/fonts/Poppins-Italic.ttf';
import PoppinsMedium from 'assets/fonts/Poppins-Regular.ttf';
import TinosBold from 'assets/fonts/Tinos-Bold.ttf';
import TinosBoldItalic from 'assets/fonts/Tinos-BoldItalic.ttf';
import TinosItalic from 'assets/fonts/Tinos-Italic.ttf';
import Tinos from 'assets/fonts/Tinos-Regular.ttf';

/**
 * Updates typerules for new variants, and disables unused variants.
 * Read more here - https://mui.com/customization/typography/#adding-amp-disabling-variants
 */
declare module '@mui/material/styles' {
  // establish additional valid variants
  interface TypographyVariants {
    fontFamilyDisplay: CSSProperties;
    fontWeightExtraBold: CSSProperties;
    hero: CSSProperties;
    h6Regular: CSSProperties;
    h7: CSSProperties;
    h8: CSSProperties;
    body: CSSProperties;
  }
  // establish valid options for createTheme configuration
  interface TypographyVariantsOptions {
    hero?: CSSProperties;
    h1?: CSSProperties;
    h2?: CSSProperties;
    h3?: CSSProperties;
    h4?: CSSProperties;
    h5?: CSSProperties;
    h6?: CSSProperties;
    h6Regular?: CSSProperties;
    h7?: CSSProperties;
    h8?: CSSProperties;
    body?: CSSProperties;
    caption?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  // update Typography valid variants and disable unused Typography variants
  interface TypographyPropsVariantOverrides {
    hero: true;
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    h6Regular: true;
    h7: true;
    h8: true;
    body: true;
    caption: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    overline: false;
    button: false;
  }
}

// interface for uniform font styles
interface FontStyle {
  fontFamily: string;
  fontWeight: number;
  fontSize: number | string;
  lineHeight: number | string;
  color: string | undefined;
}

// Accepts FontStyle properties and combines them into a valid FontStyle
const createFontStyle = (
  fontFamily: string,
  fontWeight: number,
  fontSize: number | string,
  color: string | undefined,
  lineHeight?: number | string
): FontStyle => ({
  fontFamily,
  fontWeight,
  fontSize,
  color,
  lineHeight: lineHeight ?? 1
});

export const displayStack = [
  'Helvetica',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif'
].join(', ');

export const systemStack = [
  '"Helvetica", sans-serif',
  'system',
  '-apple-system',
  '".SFNSText-Regular"',
  '"San Francisco"',
  '"Segoe UI"',
  '"Helvetica Neue"',
  '"Lucida Grande"',
  'sans-serif'
].join(', ');

export const createTypographyTheme = (currTheme: Theme): Theme => {
  const {
    palette: {
      black: { main: black },
      blue: { main: blue }
    }
  } = currTheme;
  const theme = createTheme(currTheme, {
    typography: {
      fontFamily: systemStack,
      fontFamilyDisplay: displayStack,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      fontWeightExtraBold: 900,
      // create/update Typography variants and define their styles
      hero: {
        ...createFontStyle(systemStack, 900, '3rem', '#FFF'),
        background: 'rgba(2, 0, 38, 0.3)',
        padding: '2rem',
        borderRadius: '1rem',
        display: 'inline-block'
      },
      h1: createFontStyle(systemStack, 900, '2rem', black),
      h2: createFontStyle(systemStack, 900, '1.5rem', black),
      h3: createFontStyle(systemStack, 900, '1.125rem', black),
      h4: createFontStyle(systemStack, 900, '1rem', black),
      h5: createFontStyle(systemStack, 700, '1rem', black),
      h6: createFontStyle(systemStack, 700, '.75rem', black),
      h6Regular: createFontStyle(systemStack, 400, '.75rem', black, '115%'),
      h7: createFontStyle(systemStack, 400, '1.25rem', black),
      h8: createFontStyle(systemStack, 400, '1.125rem', blue),
      body: createFontStyle(displayStack, 400, '1rem', black, '28px'),
      caption: createFontStyle(displayStack, 400, '0.875rem', black),
      // update unused variants to have no styles
      subtitle1: undefined,
      subtitle2: undefined,
      body1: undefined,
      body2: undefined,
      overline: undefined,
      button: undefined
    },
    components: {
      MuiTypography: {
        defaultProps: {
          // Ensure variants use proper tags under the hood
          variantMapping: {
            hero: 'h1',
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6Regular: 'h6',
            h6: 'h6',
            h7: 'p',
            h8: 'p',
            body: 'p',
            caption: 'p'
          }
        }
      },
      MuiCssBaseline: {
        // Add GMS fonts to CSS Baseline
        styleOverrides: `
          @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            src: url(${MontserratMedium});
          }

          @font-face {
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 400;
            src: url(${MontserratMediumItalic})
          }

          @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            src: url(${MontserratBold});
          }

          @font-face {
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 700;
            src: url(${MontserratBoldItalic});
          }

          @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 900;
            src: url(${MontserratHeavy});
          }

          @font-face {
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 900;
            src: url(${MontserratHeavyItalic});
          }



          @font-face {
            font-family: 'Bodoni Moda';
            font-style: normal;
            font-weight: 400;
            src: url(${BodoniMedium});
          }

          @font-face {
            font-family: 'Bodoni Moda';
            font-style: italic;
            font-weight: 400;
            src: url(${BodoniMediumItalic})
          }

          @font-face {
            font-family: 'Bodoni Moda';
            font-style: normal;
            font-weight: 700;
            src: url(${BodoniBold});
          }

          @font-face {
            font-family: 'Bodoni Moda';
            font-style: italic;
            font-weight: 700;
            src: url(${BodoniBoldItalic});
          }

          @font-face {
            font-family: 'Bodoni Moda';
            font-style: normal;
            font-weight: 900;
            src: url(${BodoniHeavy});
          }

          @font-face {
            font-family: 'Bodoni Moda';
            font-style: italic;
            font-weight: 900;
            src: url(${BodoniHeavyItalic});
          }



          @font-face {
            font-family: 'EB Garamond';
            font-style: normal;
            font-weight: 400;
            src: url(${EbGaramondMedium});
          }

          @font-face {
            font-family: 'EB Garamond';
            font-style: italic;
            font-weight: 400;
            src: url(${EbGaramondMediumItalic})
          }

          @font-face {
            font-family: 'EB Garamond';
            font-style: normal;
            font-weight: 700;
            src: url(${EbGaramondBold});
          }

          @font-face {
            font-family: 'EB Garamond';
            font-style: italic;
            font-weight: 700;
            src: url(${EbGaramondBoldItalic});
          }

          @font-face {
            font-family: 'EB Garamond';
            font-style: normal;
            font-weight: 900;
            src: url(${EbGaramondHeavy});
          }

          @font-face {
            font-family: 'EB Garamond';
            font-style: italic;
            font-weight: 900;
            src: url(${EbGaramondHeavyItalic});
          }



          @font-face {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            src: url(${NunitoMedium});
          }

          @font-face {
            font-family: 'Nunito';
            font-style: italic;
            font-weight: 400;
            src: url(${NunitoMediumItalic})
          }

          @font-face {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            src: url(${NunitoBold});
          }

          @font-face {
            font-family: 'Nunito';
            font-style: italic;
            font-weight: 700;
            src: url(${NunitoBoldItalic});
          }

          @font-face {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 900;
            src: url(${NunitoHeavy});
          }

          @font-face {
            font-family: 'Nunito';
            font-style: italic;
            font-weight: 900;
            src: url(${NunitoHeavyItalic});
          }



          @font-face {
            font-family: 'Crimson Pro';
            font-style: normal;
            font-weight: 400;
            src: url(${CrimsonProMedium});
          }

          @font-face {
            font-family: 'Crimson Pro';
            font-style: italic;
            font-weight: 400;
            src: url(${CrimsonProMediumItalic})
          }

          @font-face {
            font-family: 'Crimson Pro';
            font-style: normal;
            font-weight: 700;
            src: url(${CrimsonProBold});
          }

          @font-face {
            font-family: 'Crimson Pro';
            font-style: italic;
            font-weight: 700;
            src: url(${CrimsonProBoldItalic});
          }

          @font-face {
            font-family: 'Crimson Pro';
            font-style: normal;
            font-weight: 900;
            src: url(${CrimsonProHeavy});
          }

          @font-face {
            font-family: 'Crimson Pro';
            font-style: italic;
            font-weight: 900;
            src: url(${CrimsonProHeavyItalic});
          }



          @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            src: url(${PoppinsMedium});
          }

          @font-face {
            font-family: 'Poppins';
            font-style: italic;
            font-weight: 400;
            src: url(${PoppinsMediumItalic})
          }

          @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            src: url(${PoppinsBold});
          }

          @font-face {
            font-family: 'Poppins';
            font-style: italic;
            font-weight: 700;
            src: url(${PoppinsBoldItalic});
          }

          @font-face {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 900;
            src: url(${PoppinsHeavy});
          }

          @font-face {
            font-family: 'Poppins';
            font-style: italic;
            font-weight: 900;
            src: url(${PoppinsHeavyItalic});
          }



          @font-face {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            src: url(${LibreFranklinMedium});
          }

          @font-face {
            font-family: 'Libre Franklin';
            font-style: italic;
            font-weight: 400;
            src: url(${LibreFranklinMediumItalic})
          }

          @font-face {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            src: url(${LibreFranklinBold});
          }

          @font-face {
            font-family: 'Libre Franklin';
            font-style: italic;
            font-weight: 700;
            src: url(${LibreFranklinBoldItalic});
          }

          @font-face {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 900;
            src: url(${LibreFranklinHeavy});
          }

          @font-face {
            font-family: 'Libre Franklin';
            font-style: italic;
            font-weight: 900;
            src: url(${LibreFranklinHeavyItalic});
          }



          @font-face {
            font-family: 'Libre Baskerville';
            font-style: normal;
            font-weight: 400;
            src: url(${LibreBaskervilleMedium});
          }

          @font-face {
            font-family: 'Libre Baskerville';
            font-style: italic;
            font-weight: 400;
            src: url(${LibreBaskervilleMediumItalic})
          }

          @font-face {
            font-family: 'Libre Baskerville';
            font-style: normal;
            font-weight: 700;
            src: url(${LibreBaskervilleBold});
          }

          @font-face {
            font-family: 'Libre Baskerville';
            font-style: italic;
            font-weight: 700;
            src: url(${LibreBaskervilleBold});
          }

          @font-face {
            font-family: 'Libre Baskerville';
            font-style: normal;
            font-weight: 900;
            src: url(${LibreBaskervilleBold});
          }

          @font-face {
            font-family: 'Libre Baskerville';
            font-style: italic;
            font-weight: 900;
            src: url(${LibreBaskervilleBold});
          }


          @font-face {
            font-family: 'Jost';
            font-style: normal;
            font-weight: 400;
            src: url(${Jost});
          }

          @font-face {
            font-family: 'Jost';
            font-style: italic;
            font-weight: 400;
            src: url(${JostItalic});
          }

          @font-face {
            font-family: 'Jost';
            font-style: normal;
            font-weight: 700;
            src: url(${JostMedium});
          }

          @font-face {
            font-family: 'Jost';
            font-style: italic;
            font-weight: 700;
            src: url(${JostMediumItalic});
          }

          @font-face {
            font-family: 'Jost';
            font-style: normal;
            font-weight: 900;
            src: url(${JostBold});
          }

          @font-face {
            font-family: 'Jost';
            font-style: italic;
            font-weight: 900;
            src: url(${JostBoldItalic});
          }


          
          @font-face {
            font-family: 'Kameron';
            font-style: normal;
            font-weight: 400;
            src: url(${Kameron});
          }

          @font-face {
            font-family: 'Kameron';
            font-style: normal;
            font-weight: 700;
            src: url(${KameronBold});
          }



          @font-face {
            font-family: 'Tinos';
            font-style: normal;
            font-weight: 400;
            src: url(${Tinos});
          }

          @font-face {
            font-family: 'Tinos';
            font-style: italic;
            font-weight: 400;
            src: url(${TinosItalic});
          }

          @font-face {
            font-family: 'Tinos';
            font-style: normal;
            font-weight: 700;
            src: url(${TinosBold});
          }

          @font-face {
            font-family: 'Tinos';
            font-style: italic;
            font-weight: 700;
            src: url(${TinosBoldItalic});
          }
        `
      }
    }
  });

  return theme;
};
