/**
 * deepParse will read from a nested property using dot notation (i.e.: foo.bar.baz)
 * @param obj - target object
 * @param key - path of desired property
 * @returns any
 */
export const deepParse = (obj: any, key: string): any => {
  const keys = key.split('.');
  let value = obj;
  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]];
    if (!value) {
      break;
    }
  }
  return value !== undefined ? value : null;
};
