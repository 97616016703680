/**
 * This component is used on both the backend and "apps app" to generate an email's HTML
 * based on a config.  The HTML property on an email entity in the DB is generated whenever
 * the config is updated, but we also need to share this builder capability with the front-end
 * in order to support real-time previews while wrapping blocks for edit/highlight/drag-and-drop
 * functionality.
 *
 * If you update this file, please be sure your changes are propagated to BOTH repositories!
 *
 * How this works:
 * - Use `buildEmail(config)` if you just need the full email HTML
 * - Use the html generator functions for block specific HTML, e.g. generateHeaderHtml and the functions inside generateBlockHtmlMap,
 */
// Front-end imports:
import {
  CustomContentBlockType,
  DonationDetailBlock,
  DonationSummaryBlock,
  EmailBlockBaseType,
  EmailBlockTypes,
  EmailConfig,
  EmailTheme,
  HeaderBlock
} from 'types/email';

// Backend imports:
// import {
//   EmailConfigDto as EmailConfig,
//   EmailThemeDto as EmailTheme
//   HeaderBlockDto as HeaderBlock,
//   DonationDetailBlockDto as DonationDetailBlock,
//   EmailBlockTypes,
//   EmailBlockBaseType
// } from '../dto/emails-sub.dto';

/**
 * This string template function will take the generated HTML string and wrap it in all the
 * wrapper HTML for the email.  We can control the header content and any styles that aren't
 * inline.
 */
const emailHtmlWrapper = (htmlContent: string, backgroundColor: string) => `
  <html>
    <head>
      <!-- TODO: How to handle styles?  Any particular HTML elements/structure/conventions
      expected from the e-mail clients? -->
      <style>
        .email-container {
          padding: 3rem;
        }
        .email-container div:not(:first-of-type) { 
          margin-top: 2.5rem;
        }
      </style>
    </head>
    <body style="backgroundColor: ${backgroundColor}">
      <div>
        ${htmlContent}
      </div>
    </body>
  </html>
`;

// Individual generator functions:
export const generateHeaderHtml = (headerBlock: EmailBlockBaseType) =>
  (headerBlock as HeaderBlock).html;

export const generateDonationDetailHtml = (
  donationDetailBlock: EmailBlockBaseType,
  theme: EmailTheme
) => {
  const block = donationDetailBlock as DonationDetailBlock;
  const { text, font } = theme;
  const tdStyle = `
    text-align: left; 
    display: inline-block; 
    width: calc(33% - 20px);
    margin-right: 20px;
    margin-bottom: 24px;
    vertical-align: top;
  `;

  // iterate block's placeholders, rendering them in whatever table format is needed.
  return `
    <table style='width: 100%; font-family: ${font}; color: ${text};'>
      <tr>
        ${block.placeholders
          .map(
            (placeholder) =>
              `<td style="${tdStyle}">
                <span style="line-height: 28px; font-weight: 300;">${placeholder}</span><br />
                <span style="font-weight: 700;" class="email-details-placeholder">{{${placeholder}}}</span>
              </td>`
          )
          .join('')}
      </tr>
    </table>
  `;
};

export const generateCustomContentHtml = (headerBlock: EmailBlockBaseType) =>
  (headerBlock as CustomContentBlockType).html;

export const generateDonationSummaryHtml = (
  donationSummaryBlock: EmailBlockBaseType,
  theme: EmailTheme,
  // actual org info will be emitted from gms-react to render in editor; placeholders will be saved to backend
  orgInfo: {
    orgName: string;
    orgLogoUrl: string;
  }
) => {
  const block = donationSummaryBlock as DonationSummaryBlock;
  const { transactionAmounts, transactionDetails } = block;
  const { text, font } = theme;

  const orgNameCell = block.orgDescription
    ? `<div>
      <h2 style='margin: 0; padding-bottom: 0.25rem;'>${orgInfo.orgName}</h2>
      <p style='margin: 0; padding-top: 0.25rem;'>${block.orgDescription}</p>
    </div>`
    : `<div><h2>${orgInfo.orgName}</h2></div>`;

  const orgDetailRow =
    block.orgLogo && orgInfo.orgLogoUrl
      ? `<tr>
      <td style='padding: 2.5rem 0;'>
        <img src='${orgInfo.orgLogoUrl}' style='border-radius: 100%; height: 3.8rem; object-fit: contain; width: 3.8rem;'/>
      </td>
      <td colspan='2' style='padding: 2.5rem 0; vertical-align: baseline;'>
        ${orgNameCell}
      </td>
      <td style='text-align: right; padding: 2.5rem 0;'><span class="email-details-placeholder">{{${transactionAmounts.donationAmount}}}</span></td>
    </tr>`
      : `<tr>
      <td colspan='3' style='padding: 2.5rem 0'>
        ${orgNameCell}
      </td>
      <td style='text-align: right;' style='padding: 2.5rem 0'><span class="email-details-placeholder">{{${transactionAmounts.donationAmount}}}</span></td>
    </tr>`;

  return `
    <table style='width: 100%; font-family: ${font}; color: ${text}; border-collapse: collapse;'>
      <tr style='padding-bottom: 1rem'>
        <td>
          <span>Transaction #</span><br />
          <span class="email-details-placeholder">{{${transactionDetails.transactionNumber}}}</span>
        </td>
        <td>
          <span>Transaction Date</span><br />
          <span class="email-details-placeholder">{{${transactionDetails.dateOfTransaction}}}</span>
        </td>
        <td>
          <span>Occurrence</span><br />
          <span class="email-details-placeholder">{{${transactionDetails.occurrence}}}</span>
        </td>
        <td>
          <span>Payment</span><br />
          <span class="email-details-placeholder">{{${transactionDetails.paymentMethod}}}</span>
        </td>
      </tr>
      </table>
      <table style='width: 100%; font-family: ${font}; color: ${text}; border-collapse: collapse;'>
        ${orgDetailRow}
      </table>
      <table style='width: 100%; font-family: ${font}; color: ${text}; border-collapse: collapse;'>
      <tr style='border-bottom: 1px solid #DADCE8; height: 4rem; vertical-align: center;'>
        <td colspan='3'>Transaction Fee</td>
        <td style='text-align: right;'> <span class="email-details-placeholder">{{${transactionAmounts.transactionFee}}}</span></td>
      </tr>
      <tr style='height: 4rem;'>
        <td colspan='3'>Total</td>
        <td style='text-align: right;'><span class="email-details-placeholder">{{${transactionAmounts.totalAmount}}}</span></td>
      </tr>
    </table>
  `;
};

/**
 * This map is used to define an HTML generation function for every block defined in the EmailBlockBaseType enum.
 */
export const generateBlockHtmlMap: {
  [key in EmailBlockTypes]: (
    block: EmailBlockBaseType,
    theme: EmailTheme,
    orgInfo: {
      orgName: string;
      orgLogoUrl: string;
    }
  ) => string;
} = {
  [EmailBlockTypes.CustomContent]: generateCustomContentHtml,
  [EmailBlockTypes.Header]: generateHeaderHtml,
  [EmailBlockTypes.DonationDetails]: generateDonationDetailHtml,
  [EmailBlockTypes.DonationSummary]: generateDonationSummaryHtml
};

/**
 * Function that will generate an entire HTML document for storing on the backend based on a config
 */
export const buildEmail = (config: EmailConfig) => {
  const header = generateHeaderHtml(config.header);
  const blocks = config.blocks.map((block) =>
    generateBlockHtmlMap[block.blockType](block, config.theme, {
      orgName: '{{OrganizationName}}',
      orgLogoUrl: '{{OrganizationLogo}}'
    })
  );

  // Assemble header/all blocks and wrap each in their own <div> element
  // May need to revisit the wrapper setup once we know email-HTML best practices
  // and to handle any inline styling
  const htmlContent = `
  <div class="email-container">
    <div>${header}</div>
    ${blocks.length > 0 ? '<div>' : ''}
      ${blocks.join('</div>\n<div>')}
    ${blocks.length > 0 ? '</div>' : ''}
  </div>`;

  return emailHtmlWrapper(htmlContent, config.theme.background);
};
