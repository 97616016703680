/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { SubmitDonationDataType } from 'services/donationService';
import ContentTableBlock from 'components/ContentTableBlock/ContentTableBlock';
import CustomCSS from 'components/CustomCSS';
import { ICustomContentBlockProps } from 'components/CustomContentBlock';
import EditWrapper from 'components/EditWrapper';
import { renderBlockDropzone } from 'components/EditWrapper/EditWrapperUtils';
import { ThankYouAccountInvitationBlock } from 'components/ThankYouAccountInvitationBlock';
import { ThankYouCorporateMatchingBlock } from 'components/ThankYouCorporateMatchingBlock';
import ThankYouCustomContent from 'components/ThankYouCustomContent/ThankYouCustomContent';
import { useGivingFormData } from 'hooks';
import {
  BlockTypes,
  Donation,
  IThankYouAccountInvitationBlock,
  IThankYouCorporateMatchingBlock,
  IThankYouHeader,
  IThankYouMessageContentTableBlock,
  ThankYouBlockType
} from 'types';
import './ThankYou.scss';

type ThankYouProps = {
  donationResponse: Donation | null;
  givingFormSubmission: SubmitDonationDataType | null;
  isEditMode: boolean;
  isPreviewMode: boolean;
  highlightedEditBlock: string;
  emitThankYouEditEvent: (id: string) => void;
  emitThankYouDropEvent: (blockOrder: string[]) => void;
};

const ThankYou = ({
  isEditMode = false,
  highlightedEditBlock,
  emitThankYouEditEvent,
  emitThankYouDropEvent,
  givingFormSubmission,
  donationResponse
}: ThankYouProps): JSX.Element => {
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const { config: givingFormConfig } = useGivingFormData();

  const editWrapper = (
    blocks: JSX.Element[],
    {
      showDropzones = true,
      compressWrapper = false
    }: { showDropzones?: boolean; compressWrapper?: boolean } = {}
  ) => {
    if (isDragging && showDropzones) {
      return [
        ...blocks.flatMap((block, idx) => [
          ...renderBlockDropzone(
            blocks,
            idx,
            block.key as string,
            isDragging,
            emitThankYouDropEvent
          ),
          <EditWrapper
            onEdit={() => emitThankYouEditEvent(block.key as string)}
            isEditable={isEditMode}
            isDraggable={
              showDropzones &&
              blocks[idx]?.props?.blockType !== 'ThankYouHeaderBlock'
            }
            setIsDragging={setIsDragging}
            key={block.key}
            dragKey={block.key as string}
            className={
              (block.key as string) === highlightedEditBlock
                ? 'edit-options-wrapper--selected'
                : ''
            }
          >
            {block}
          </EditWrapper>
        ]),
        ...renderBlockDropzone(
          blocks,
          blocks.length,
          'last_dropzone',
          isDragging,
          emitThankYouDropEvent
        )
      ];
    }
    return blocks.map((block, idx) => (
      <EditWrapper
        onEdit={() => emitThankYouEditEvent(block.key as string)}
        isEditable={isEditMode}
        isDraggable={
          showDropzones &&
          blocks[idx]?.props?.blockType !== 'ThankYouHeaderBlock'
        }
        setIsDragging={setIsDragging}
        key={block.key}
        dragKey={block.key as string}
        className={`${
          (block.key as string) === highlightedEditBlock
            ? 'edit-options-wrapper--selected'
            : ''
        } ${compressWrapper ? 'compress-wrapper' : ''}`}
      >
        {block}
      </EditWrapper>
    ));
  };

  const blocks = givingFormConfig?.thankYouConfig.blocks.map(
    (block: ThankYouBlockType) => {
      const { id, blockType } = block;
      switch (blockType) {
        case BlockTypes.ThankYouHeaderBlock: {
          const { html } = block as IThankYouHeader;
          return (
            <ThankYouCustomContent
              key={id}
              {...(block as ICustomContentBlockProps)}
              html={html}
              givingFormSubmission={givingFormSubmission}
              donationResponse={donationResponse}
              isEditMode={isEditMode}
            />
          );
        }
        case BlockTypes.ThankYouMessageBodyBlock: {
          return (
            <ThankYouCustomContent
              key={id}
              {...(block as ICustomContentBlockProps)}
              givingFormSubmission={givingFormSubmission}
              donationResponse={donationResponse}
              isEditMode={isEditMode}
            />
          );
        }

        case BlockTypes.ThankYouMessageDonationDetailsBlock: {
          return (
            <ContentTableBlock
              key={id}
              givingFormSubmission={givingFormSubmission}
              donationResponse={donationResponse}
              isEditMode={isEditMode}
              {...(block as IThankYouMessageContentTableBlock)}
            />
          );
        }

        case BlockTypes.ThankYouAccountInvitationBlock: {
          return (
            <ThankYouAccountInvitationBlock
              key={id}
              {...(block as IThankYouAccountInvitationBlock)}
              givingFormSubmission={givingFormSubmission}
              donationResponse={donationResponse}
              isEditMode={isEditMode}
            />
          );
        }

        case BlockTypes.ThankYouCorporateMatchingBlock: {
          return (
            <ThankYouCorporateMatchingBlock
              key={id}
              {...(block as IThankYouCorporateMatchingBlock)}
              givingFormSubmission={givingFormSubmission}
              donationResponse={donationResponse}
            />
          );
        }

        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
      }
    }
  );

  useEffect(() => {
    if ((window as any).heap) {
      (window as any).heap.track('Confirmation Page Loaded');
    }
  }, []);

  return (
    <>
      {!!givingFormConfig?.customCss && (
        <CustomCSS css={givingFormConfig.customCss} />
      )}
      <Box className="GF-ThankYou">
        <Stack
          id="ThankYou-Blocks"
          spacing={isDragging ? 0 : 2.5}
          className={isDragging ? 'drag-active' : ''}
        >
          {isEditMode && blocks ? editWrapper(blocks) : blocks}
        </Stack>
      </Box>
    </>
  );
};

export default ThankYou;
