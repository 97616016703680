import Dialog from './Dialog';

export default Dialog;
export { DialogActions, DialogContent } from '@mui/material';
export type { DialogProps } from './Dialog';
export * from './Dialog.theme';
export { default as DialogActionButton } from './DialogActionButton';
export { default as DialogContentDescription } from './DialogContentDescription';
export { default as DialogContentTitle } from './DialogContentTitle';
export { default as DialogTitle } from './DialogTitle';
