import { Theme } from '@mui/material';
import MuiRadio, { RadioProps } from '@mui/material/Radio';
import { alpha, styled } from '@mui/material/styles';

const UncheckedIcon = styled('span')(({ theme }: { theme: Theme }) => ({
  // Base Styles
  position: 'relative',
  width: '1.125rem',
  height: '1.125rem',
  border: `solid 1px ${theme.palette.blue.main}`,
  borderRadius: '50%',
  // Style the focus outline
  '.Mui-focusVisible &': {
    outline: `2px auto ${alpha(theme.palette.blue.main, 0.5)}`,
    outlineOffset: 2
  },
  // Change styles based on state
  'input:hover ~ &': {
    backgroundColor: theme.palette.lightestGray.main
  },

  'input:disabled ~ &': {
    opacity: 0.3
  }
}));

const CheckedIcon = styled(UncheckedIcon)(({ theme }: { theme: Theme }) => ({
  // Add hover styles
  'input:hover ~ &': {
    border: `1px solid ${theme.palette.darkBlue.main}`,
    backgroundColor: 'transparent',
    '&:before': {
      backgroundColor: theme.palette.darkBlue.main
    }
  },
  // Create a pseudo element for the center circle and add styles
  '&:before': {
    backgroundColor: theme.palette.blue.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    display: 'block',
    width: '0.625rem',
    height: '0.625rem',
    content: '""'
  }
}));

/**
 * Radio button component with GMS stylings
 * Can be used with paired RadioGroup export and MUI's FormControlLabel to
 * help manage form control and support accessibility
 *
 * https://mui.com/components/radio-buttons/ to learn more
 */

const Radio = ({ sx, ...props }: RadioProps): JSX.Element => (
  <MuiRadio
    sx={{
      '&:hover': {
        // Remove some default mui styles
        bgcolor: 'transparent'
      },
      ...sx
    }}
    color="default"
    checkedIcon={<CheckedIcon />}
    icon={<UncheckedIcon />}
    {...props}
  />
);

export default Radio;
