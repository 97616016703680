import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState
} from 'react';
import { FormControl, FormHelperText, Stack } from '@mui/material';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { PlaidLinkError } from 'react-plaid-link';
import { SubmitDonationDataType } from 'services/donationService';
import { resolvedEnvironment } from 'services/environment';
import { AnyObjectSchema } from 'yup';
import { CorporateMatching } from 'components/CorporateMatching/CorporateMatching';
import CoverTransactionFee from 'components/CoverTransactionFee';
import CustomCSS from 'components/CustomCSS';
import { CustomContentBlock } from 'components/CustomContentBlock';
import CustomField from 'components/CustomField';
import DesignationsBlock from 'components/DesignationsBlock';
import EditWrapper from 'components/EditWrapper';
import { renderBlockDropzone } from 'components/EditWrapper/EditWrapperUtils';
import EmailOptIn from 'components/EmailOptIn/EmailOptIn';
import { EditorEventTypes, ScrollPositions } from 'components/EventHub';
import Footer from 'components/Footer';
import GiftOptions from 'components/GiftOptions';
import GiveAnonymously from 'components/GiveAnonymously';
import Header from 'components/Header';
import { PageBreak } from 'components/PageBreak';
import PaymentSection from 'components/PaymentSection';
import { RecurringGiftPrompt } from 'components/RecurringGiftPrompt';
import RecurringOptions from 'components/RecurringOptions';
import { SubmitButton } from 'components/SubmitButton';
import { ThankYouGiftBlock } from 'components/ThankYouGiftBlock';
import { TributeBlock } from 'components/TributeBlock';
import {
  useDisabledOverlay,
  useExtraFormInfo,
  useGivingFormData,
  useQueryParam
} from 'hooks';
import { useDonationSubmission } from 'hooks/useDonationSubmission';
import { usePlaid } from 'hooks/usePlaid';
import { useRecaptchaCheckbox } from 'hooks/useRecaptchaCheckbox';
import {
  AllPaymentOptions,
  BlockTypes,
  CreditCardTypes,
  Donation,
  DonorFees,
  GivingFormBlockType,
  ICorporateMatchingBlock,
  ICoverTransactionFeeBlock,
  ICustomContentBlock,
  ICustomFieldBlock,
  IDesignationsBlock,
  IEmailOptInBlock,
  IGiftOptionsBlock,
  IGiveAnonymouslyBlock,
  IGivingFormConfig,
  IPageBreakBlock,
  IPaymentInfoSection,
  IRecurringOptionsBlock,
  IThankYouGiftBlock,
  RecurringGiftEquation,
  RecurringOptionsType,
  TributeBlockType,
  acceptedCreditCards
} from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import { recurringOnlyHasOnceOption } from 'utils/blockUtils';
import { parseAvailableDesignationQueryParams } from 'utils/queryParamUtils';
import { FormActivityMonitor } from './FormActivityMonitor';
import { IGivingFormSchema } from './GivingForm.Schema';
import { getGivingFormPage } from './GivingFormUtils';

type GivingFormElementProps = {
  currPageIndex: number;
  donorFees: DonorFees;
  doubleTheDonationKey?: string;
  emitFormPageChange: (scrollPosition: ScrollPositions) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventHub: any;
  givingFormConfig: IGivingFormConfig;
  givingFormSchemaPages: AnyObjectSchema[];
  highlightedEditBlock: string;
  isEditMode: boolean;
  isPreviewMode: boolean;
  methods: UseFormReturn<IGivingFormSchema>;
  recurringGiftPromptOpen: boolean;
  sessionId: string;
  setCurrPageIndex: Dispatch<SetStateAction<number>>;
  setDonationComplete: Dispatch<SetStateAction<boolean>>;
  setDonationObject: Dispatch<SetStateAction<SubmitDonationDataType | null>>;
  setDonationResponse: Dispatch<SetStateAction<Donation | null>>;
  setHighlightedEditBlock: Dispatch<SetStateAction<string>>;
  setRecurringGiftPromptOpen: Dispatch<SetStateAction<boolean>>;
};

export const GivingFormElement = ({
  currPageIndex,
  donorFees,
  doubleTheDonationKey,
  emitFormPageChange,
  eventHub,
  givingFormConfig,
  givingFormSchemaPages,
  highlightedEditBlock,
  isEditMode,
  isPreviewMode,
  methods,
  recurringGiftPromptOpen,
  sessionId,
  setCurrPageIndex,
  setDonationComplete,
  setDonationObject,
  setDonationResponse,
  setHighlightedEditBlock,
  setRecurringGiftPromptOpen
}: GivingFormElementProps) => {
  const {
    paymentGateway,
    acceptedPaymentOptions,
    organizationFeatures,
    showPlaidOnSubmit,
    campaignDesignations
  } = useGivingFormData();
  const [currentCardType, setCurrentCardType] =
    useState<CreditCardTypes[]>(acceptedCreditCards);
  const [showCCPaymentInfo, setShowCCPaymentInfo] = useState(false);
  const [showGooglePay, setShowGooglePay] = useState(false);
  const [showApplePay, setShowApplePay] = useState(false);
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const [hasAttemptedDonation, setHasAttemptedDonation] = useState<
    RecurringOptionsType | false
  >(false);
  const [recurringGiftCurrentEquation, setRecurringGiftCurrentEquation] =
    useState<RecurringGiftEquation | null>(null);
  const [plaidIsComplete, setPlaidIsComplete] = useState(false);
  const [plaidError, setPlaidError] = useState<PlaidLinkError | null>(null);
  const [amountIsFromRecurringGiftPrompt, setAmountIsFromRecurringGiftPrompt] =
    useState(false);
  const { displayOverlay } = useDisabledOverlay();
  const { onSubmit, submitButtonError, setSubmitButtonError } =
    useDonationSubmission({
      emitFormPageChange,
      givingFormConfig,
      isEditMode,
      sessionId,
      methods,
      eventHub,
      setDonationComplete,
      setDonationObject,
      setDonationResponse
    });
  const { initPlaid } = usePlaid({
    resetForm: methods.reset,
    setPlaidError,
    setPlaidIsComplete,
    setValue: methods.setValue
  });
  const designationsQueryParam = useQueryParam(IDonateQueryParam.Designations);
  const [disableGiftOptions, setDisableGiftOptions] = useState<boolean>(() => {
    const designations = parseAvailableDesignationQueryParams(
      designationsQueryParam,
      campaignDesignations
    );
    return designations.length > 0;
  });
  const { setIsThirdPartyPaySelected } = useExtraFormInfo();
  const { checkboxRecaptchaRef, handleCheckboxRecaptcha, useCheckbox } =
    useRecaptchaCheckbox();

  useEffect(() => {
    if (plaidIsComplete) {
      methods.handleSubmit(onSubmit)();
    }
    if (plaidError) {
      setSubmitButtonError(`plaid error: ${plaidError.error_message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plaidIsComplete, plaidError]);

  useEffect(() => {
    setIsThirdPartyPaySelected(
      showApplePay || showGooglePay || showPlaidOnSubmit
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showApplePay, showGooglePay, showPlaidOnSubmit]);

  const emitEditEvent = (id: string) => {
    setHighlightedEditBlock(id);
    eventHub.emit(EditorEventTypes.EditBlock, { id });
  };

  const emitDropEvent = (blockOrder: string[]) => {
    eventHub.emit(EditorEventTypes.BlockOrderUpdate, { blockOrder });
  };

  const emitDeleteEvent = (id: string) => {
    eventHub.emit(EditorEventTypes.DeleteBlock, { id });
  };

  const editWrapper = (
    blocks: JSX.Element[],
    {
      showDropzones = true,
      compressWrapper = false,
      className
    }: {
      showDropzones?: boolean;
      compressWrapper?: boolean;
      className?: string;
    } = {}
  ) => {
    if (isDragging && showDropzones) {
      return [
        ...blocks.flatMap((block, idx) => {
          const { blockType } = block.props as { blockType: BlockTypes };
          const isPageBreak = blockType === BlockTypes.PageBreakBlock;
          if (
            blockType === BlockTypes.DesignationsBlock &&
            block.props.designations.length <= 1
          ) {
            return null;
          }
          return [
            ...renderBlockDropzone(
              blocks,
              idx,
              block.key as string,
              isDragging,
              emitDropEvent
            ),
            <EditWrapper
              onEdit={() => emitEditEvent(block.key as string)}
              onDelete={
                isPageBreak
                  ? () => emitDeleteEvent(block.key as string)
                  : undefined
              }
              isDraggable={showDropzones}
              isEditable={!isPageBreak}
              setIsDragging={setIsDragging}
              key={block.key}
              dragKey={block.key as string}
              className={clsx(
                {
                  'edit-options-wrapper--selected':
                    (block.key as string) === highlightedEditBlock
                },
                className
              )}
            >
              {block}
            </EditWrapper>
          ];
        }),
        ...renderBlockDropzone(
          blocks,
          blocks.length,
          'last_dropzone',
          isDragging,
          emitDropEvent
        )
      ];
    }
    return blocks.map((block) => {
      const { blockType } = block.props as { blockType: BlockTypes };
      const isPageBreak = blockType === BlockTypes.PageBreakBlock;
      if (
        blockType === BlockTypes.DesignationsBlock &&
        block.props.designations.length <= 1
      ) {
        return null;
      }
      return (
        <EditWrapper
          onEdit={() => emitEditEvent(block.key as string)}
          onDelete={
            isPageBreak ? () => emitDeleteEvent(block.key as string) : undefined
          }
          setIsDragging={setIsDragging}
          isDraggable={showDropzones}
          isEditable={blockType !== BlockTypes.PageBreakBlock}
          key={block.key}
          dragKey={block.key as string}
          className={clsx(
            {
              'edit-options-wrapper--selected':
                (block.key as string) === highlightedEditBlock,
              'compress-wrapper': compressWrapper
            },
            className
          )}
        >
          {block}
        </EditWrapper>
      );
    });
  };

  const handleBack = () => {
    if (!isEditMode) {
      methods.clearErrors();
      setCurrPageIndex((prev) => prev - 1);
      emitFormPageChange(ScrollPositions.bottom);
    }
  };

  const handleNext = async () => {
    if (isEditMode) {
      return;
    }
    if (!isPreviewMode) {
      const currentFormValues = methods.getValues();
      try {
        await givingFormSchemaPages[currPageIndex].validate(currentFormValues);
        methods.clearErrors();
      } catch (err) {
        methods.trigger();
        return;
      }
    }
    // If validation passes OR we're in preview mode, increment the page:
    setCurrPageIndex((curr) => curr + 1);
    emitFormPageChange(ScrollPositions.top);
  };

  // render recurring gift prompt then plaid, if applicable, before calling onSubmit
  const handlePreSubmit = (givingFormSchema: IGivingFormSchema) => {
    const { giftAmount, recurringOption, paymentOption, designation } =
      methods.getValues();
    const { recurringGiftPrompt, blocks } = givingFormConfig;

    // do not submit donation in preview mode
    if (isPreviewMode) {
      if (!giftAmount && recurringGiftPrompt?.isEnabled) {
        setRecurringGiftPromptOpen(true);
      } else {
        setDonationComplete(true);
        emitFormPageChange(ScrollPositions.top);
      }
      return;
    }

    const equationsFromConfig = givingFormConfig.recurringGiftPrompt?.equations;
    const recurringOptionsBlock = blocks.find(
      (block) => block.blockType === BlockTypes.RecurringOptions
    );

    // equations from config possibly undefined
    const equations = equationsFromConfig || [];

    // if criteria is met for recurring gift prompt, open the modal and finish the
    // donation submission from within the modal.
    // otherwise finish the submission here
    // first, determine if recurringGiftPrompt is enabled and donor has selected 'once'
    // if recurringOptionsBlock is undefined, this means there were no options for the donor
    // to select and 'once' is the default selection
    // recurring prompt not available for Apple Pay, Google Pay, or multidesignations
    if (
      !hasAttemptedDonation &&
      recurringGiftPrompt?.isEnabled &&
      (recurringOption === RecurringOptionsType.Once ||
        (recurringOptionsBlock as IRecurringOptionsBlock)?.recurringOptions
          ?.length === 1 ||
        !recurringOptionsBlock) &&
      paymentOption !== AllPaymentOptions.APPLEPAY &&
      paymentOption !== AllPaymentOptions.GOOGLEPAY &&
      paymentOption !== AllPaymentOptions.PAYPAL &&
      designation?.length === 1
    ) {
      // sorting equations from highest to lowest by maximum threshold.
      const sortedEquations = equations
        .slice()
        .sort(
          (a: RecurringGiftEquation, b: RecurringGiftEquation) => b.max - a.max
        );
      // if there are overlapping thresholds and donor selects gift amount
      // that applies to two or more thresholds, show equation for the
      // highest threshold
      for (let i = 0; i < sortedEquations.length; i++) {
        if (
          giftAmount >= sortedEquations[i].min &&
          giftAmount <= sortedEquations[i].max
        ) {
          setRecurringGiftCurrentEquation(sortedEquations[i]);
          setRecurringGiftPromptOpen(true);
          return;
        }
        if (showPlaidOnSubmit && !plaidIsComplete) {
          initPlaid();
          return;
        }
      }
    } else if (showPlaidOnSubmit && !plaidIsComplete) {
      initPlaid();
      return;
    }
    onSubmit(givingFormSchema);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (isEditMode) {
      // Don't allow submitting in edit mode
      return;
    }

    methods.handleSubmit(handlePreSubmit)();
  };

  let pageBreakCount = 0; // Counter to know whether or not to show back button and show page count

  const blocks = givingFormConfig.blocks
    .map((block: GivingFormBlockType) => {
      const { id, blockType } = block;
      switch (blockType) {
        case BlockTypes.RecurringOptions: {
          const onlyHasOnceOption = recurringOnlyHasOnceOption(
            block as IRecurringOptionsBlock
          );

          return onlyHasOnceOption ||
            hasAttemptedDonation === RecurringOptionsType.Monthly ? null : (
            <RecurringOptions key={id} {...(block as IRecurringOptionsBlock)} />
          );
        }
        case BlockTypes.GiftOptionBlock:
          return disableGiftOptions ||
            hasAttemptedDonation === RecurringOptionsType.Monthly ? null : (
            <GiftOptions
              key={id}
              {...(block as IGiftOptionsBlock)}
              unmount={() => setDisableGiftOptions(true)}
              isEditMode={isEditMode}
            />
          );
        case BlockTypes.PaymentSection: {
          const paymentSectionBlock = block as IPaymentInfoSection;
          return (
            <PaymentSection
              key={id}
              {...{
                ...paymentSectionBlock,
                paymentOptionsBlock: {
                  ...paymentSectionBlock.paymentOptionsBlock,
                  paymentOptions:
                    paymentSectionBlock.paymentOptionsBlock.paymentOptions.filter(
                      (option) => acceptedPaymentOptions.includes(option)
                    )
                }
              }}
              paymentGateway={paymentGateway}
              currentCardType={currentCardType}
              setCurrentCardType={setCurrentCardType}
              showCCPaymentInfo={showCCPaymentInfo}
              setShowCCPaymentInfo={setShowCCPaymentInfo}
              showGooglePay={showGooglePay}
              setShowGooglePay={setShowGooglePay}
              showApplePay={showApplePay}
              setShowApplePay={setShowApplePay}
              amountIsFromRecurringGiftPrompt={amountIsFromRecurringGiftPrompt}
            />
          );
        }
        case BlockTypes.CustomFieldBlock:
          return (
            <CustomField
              key={id}
              {...(block as ICustomFieldBlock)}
              currencySymbol={givingFormConfig.currencySymbol}
            />
          );
        case BlockTypes.CustomContentBlock:
          return (
            <CustomContentBlock key={id} {...(block as ICustomContentBlock)} />
          );
        case BlockTypes.DesignationsBlock:
          return (
            <DesignationsBlock
              key={id}
              remountGiftOptions={() => setDisableGiftOptions(false)}
              {...(block as IDesignationsBlock)}
            />
          );
        case BlockTypes.EmailOptInBlock:
          return <EmailOptIn key={id} {...(block as IEmailOptInBlock)} />;
        case BlockTypes.GiveAnonymouslyBlock:
          return (
            <GiveAnonymously key={id} {...(block as IGiveAnonymouslyBlock)} />
          );
        case BlockTypes.CoverTransactionFeeBlock:
          return (
            <CoverTransactionFee
              key={id}
              {...(block as ICoverTransactionFeeBlock)}
              donorFees={donorFees}
            />
          );
        case BlockTypes.PageBreakBlock:
          pageBreakCount++;
          return (
            <PageBreak
              {...(block as IPageBreakBlock)}
              onBack={pageBreakCount !== 1 ? handleBack : undefined}
              onNext={handleNext}
              isEditMode={!!isEditMode}
              hasValidationErrors={
                !!Object.keys(methods.formState.errors).length
              }
              key={id}
            />
          );
        case BlockTypes.CorporateMatchingBlock:
          return (
            <CorporateMatching
              key={id}
              {...(block as ICorporateMatchingBlock)}
              doubleTheDonationKey={doubleTheDonationKey}
            />
          );
        case BlockTypes.TributeBlock:
          return <TributeBlock key={id} {...(block as TributeBlockType)} />;
        case BlockTypes.ThankYouGiftBlock:
          return organizationFeatures?.thankYouGift ? (
            <ThankYouGiftBlock key={id} {...(block as IThankYouGiftBlock)} />
          ) : null;
        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
      }
    })
    .filter((e) => e) as JSX.Element[];

  return (
    <FormProvider {...methods}>
      <form className="GF-Form" onSubmit={handleSubmit}>
        {!!givingFormConfig?.header &&
          (isEditMode ? (
            editWrapper(
              [
                <Header
                  title={givingFormConfig.header.title}
                  subtitle={givingFormConfig.header.subtitle}
                  key={givingFormConfig.header.id}
                />
              ],
              {
                showDropzones: false,
                compressWrapper: true,
                className: 'header-edit-wrapper'
              }
            )
          ) : (
            <Header
              title={givingFormConfig.header.title}
              subtitle={givingFormConfig.header.subtitle}
            />
          ))}
        <Stack id="GF-Blocks" className={isDragging ? 'drag-active' : ''}>
          {isEditMode
            ? editWrapper(blocks)
            : getGivingFormPage(blocks, currPageIndex)}
          {useCheckbox && resolvedEnvironment?.recaptchaCheckboxSiteKey && (
            <ReCAPTCHA
              ref={checkboxRecaptchaRef}
              sitekey={resolvedEnvironment?.recaptchaCheckboxSiteKey}
              onChange={handleCheckboxRecaptcha}
            />
          )}
          {(isEditMode ||
            givingFormSchemaPages.length === 0 ||
            currPageIndex === givingFormSchemaPages.length - 1) && (
            <FormControl
              className={`GF-Submit ${isEditMode ? 'preview-mode' : ''}`}
            >
              <>
                {pageBreakCount > 0 && (
                  <PageBreak
                    onBack={handleBack}
                    isEditMode={!!isEditMode}
                    hasValidationErrors={
                      !!Object.keys(methods.formState.errors).length
                    }
                    submissionSlot
                  />
                )}
                <div className="submit-button-wrapper">
                  <SubmitButton
                    disabled={displayOverlay}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    donorFees={donorFees!}
                  />
                  {submitButtonError && (
                    <FormHelperText className="error-message" error>
                      {submitButtonError}
                    </FormHelperText>
                  )}
                  {!!Object.keys(methods.formState.errors).length && (
                    <FormHelperText className="validation-message" error>
                      Please fill out all required fields
                    </FormHelperText>
                  )}
                </div>
              </>
            </FormControl>
          )}
          {!!givingFormConfig?.footer?.text &&
            (isEditMode && givingFormConfig.footer.text ? (
              editWrapper(
                [
                  <Footer
                    text={givingFormConfig.footer.text}
                    color={givingFormConfig.footer.color}
                    key={givingFormConfig.footer.id}
                  />
                ],
                { showDropzones: false }
              )
            ) : (
              <Footer
                text={givingFormConfig.footer.text}
                color={givingFormConfig.footer.color}
              />
            ))}
          {givingFormConfig.recurringGiftPrompt?.isEnabled && (
            <RecurringGiftPrompt
              description={
                givingFormConfig.recurringGiftPrompt?.modalDescription ?? ''
              }
              emitFormPageChange={emitFormPageChange}
              equation={
                recurringGiftCurrentEquation ?? ({} as RecurringGiftEquation)
              }
              hasAttemptedDonation={hasAttemptedDonation}
              header={givingFormConfig.recurringGiftPrompt?.modalHeader ?? ''}
              isEditMode={isEditMode}
              isPreviewMode={isPreviewMode}
              onClose={() => setRecurringGiftPromptOpen(false)}
              open={recurringGiftPromptOpen}
              plaidIsComplete={plaidIsComplete}
              resetForm={methods.reset}
              setAmountIsFromRecurringGiftPrompt={
                setAmountIsFromRecurringGiftPrompt
              }
              setDonationComplete={setDonationComplete}
              setHasAttemptedDonation={setHasAttemptedDonation}
              setPlaidError={setPlaidError}
              setPlaidIsComplete={setPlaidIsComplete}
              submitDonation={onSubmit}
            />
          )}
        </Stack>
        <div id="captcha-challenge-wrapper">
          <div id="captcha-challenge" />
        </div>
        {!!givingFormConfig.customCss && (
          <CustomCSS css={givingFormConfig.customCss} />
        )}
      </form>
      {!isEditMode && !isPreviewMode && (
        <FormActivityMonitor eventHub={eventHub} sessionId={sessionId} />
      )}
    </FormProvider>
  );
};
