import { Theme, createTheme } from '@mui/material';

export const createFormHelperTextTheme = (currTheme: Theme): Theme => {
  const {
    palette: { darkGray, red },
    typography: { h6 }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            ...h6,
            marginLeft: 0,
            marginTop: '.5rem',
            color: darkGray.main,
            '&.Mui-error': {
              color: red.main
            }
          }
        }
      }
    }
  });

  return theme;
};
