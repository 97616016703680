import { HostedPageSections } from 'types/givingForm/HostedPage';

export interface EventHubEvent {
  name: string;
  payload: any;
}

export enum EditorEventTypes {
  BlockOrderUpdate = 'BlockOrderUpdate',
  BodyHeightUpdate = 'BodyHeightUpdate',
  ConfigurationUpdate = 'ConfigurationUpdate',
  DeleteBlock = 'DeleteBlock',
  DeselectBlock = 'DeselectBlock',
  EditBlock = 'EditBlock',
  EditThankYouBlock = 'EditThankYouBlock',
  FormPageChange = 'FormPageChange',
  GoogleTagManager = 'GoogleTagManager',
  HostedPageBlockOrderUpdate = 'HostedPageBlockOrderUpdate',
  OrgInfoUpdate = 'OrgInfoUpdate',
  PageLoaded = 'PageLoaded',
  PageReady = 'PageReady',
  PageScroll = 'PageScroll',
  PreviewZoom = 'PreviewZoom',
  Referrer = 'Referrer',
  SetBannerImage = 'SetBannerImage',
  SetPreviewGivingForm = 'SetPreviewGivingForm',
  SetPreviewThankYou = 'SetPreviewThankYou',
  ThankYouBlockOrderUpdate = 'ThankYouBlockOrderUpdate',
  ToggleRecurringGiftPrompt = 'ToggleRecurringGiftPrompt',
  UserActivity = 'UserActivity',
  DonationComplete = 'DonationComplete'
}

export enum ScrollPositions {
  top = 'top',
  bottom = 'bottom'
}

export enum ZoomEmitValues {
  Increase = 'Increase',
  Decrease = 'Decrease',
  FitToWidth = 'FitToWidth',
  FitToHeight = 'FitToHeight'
}

export type HostedPageBlockOrderUpdatePayload = {
  from: { section: HostedPageSections; index: number };
  target: { section: HostedPageSections; index: number };
};

export interface EventHub {
  name: string;
  props: any;
  emit(name: EditorEventTypes, payload?: any): void;
  subscribe(name: EditorEventTypes, callback: (e: EventHubEvent) => void): void;
}
