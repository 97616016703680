import { Box } from '@mui/material';

export const getUpdatedBlockOrder = (
  blocks: JSX.Element[],
  blockIdToMove: string,
  blockIdToPreceed: string
): string[] => {
  const blockIds = blocks.map((block) => block.key as string);
  if (blockIdToPreceed === 'last_dropzone') {
    return [...blockIds.filter((id) => id !== blockIdToMove), blockIdToMove];
  }
  blockIds.splice(blockIds.indexOf(blockIdToMove), 1);
  blockIds.splice(blockIds.indexOf(blockIdToPreceed), 0, blockIdToMove);

  return blockIds;
};

export const renderBlockDropzone = (
  blocks: JSX.Element[],
  index: number,
  key: string,
  isDragging: string | null,
  emitDropEvent: (blocks: string[]) => void
): JSX.Element[] => {
  const hideBlock =
    isDragging === (blocks[index]?.key as string) ||
    isDragging === (blocks[index - 1]?.key as string) ||
    blocks[index]?.props?.blockType === 'ThankYouHeaderBlock';

  return hideBlock
    ? []
    : [
        <Box
          className="block-dropzone"
          key={`DZ-${key}`}
          onDragEnter={(event) => {
            event.preventDefault();
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onDragOver={(event) => event.preventDefault()}
          onDrop={() =>
            emitDropEvent(
              // onDrop can only occur when isDragging = true
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getUpdatedBlockOrder(blocks, isDragging!, key)
            )
          }
        >
          <div />
        </Box>
      ];
};
