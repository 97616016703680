import { Box } from '@mui/material';
import parse from 'html-react-parser';
import { ICustomContentBlock } from 'types';
import './CustomContentBlock.scss';

export type ICustomContentBlockProps = Omit<ICustomContentBlock, 'blockType'>;

export const CustomContentBlock = ({ html }: ICustomContentBlockProps) => (
  <Box className="GF-CustomContent">{parse(html)}</Box>
);
