/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo } from 'react';
import { useGivingFormData } from './useGivingFormData';
import { useGoogleTagManager } from './useGoogleTagManager';
import { useKeenActivityTracking } from './useKeenActivityTracking';

export declare type FormEventType =
  | 'formLoad'
  | 'formUnload'
  | 'pageScroll'
  | 'formInactive'
  | 'formActive'
  | 'userIdle'
  | 'entry'
  | 'focus'
  | 'selection'
  | 'click'
  | 'error'
  | 'donationSubmit';

export interface FormEvent {
  type: FormEventType;
  givingFormId: string;
  givingFormVersion?: number;
  givingFormVariant?: string;
  organizationId?: string;
  organizationName?: string;
  campaignId?: string;
  referenceCode?: string;
  sessionId: string;
  visitorId: string;
  timestamp: string;
  url: string;
  details?: any;
}

declare global {
  interface Window {
    // allows access outside of the React context
    eventLog: FormEvent[];
  }
}

export const useEventTracker = (sessionId: string, visitorId: string) => {
  const givingFormData = useGivingFormData();
  const googleTagManager = useGoogleTagManager();
  const keenActivityTracking = useKeenActivityTracking();

  const {
    givingFormId,
    givingFormVersion,
    givingFormVariant,
    organizationId,
    campaignId,
    referenceCode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useMemo(() => givingFormData, []);

  const url = useMemo(() => document.referrer || window.location.href, []);

  useEffect(() => {
    window.eventLog = window.eventLog || [];
  }, []);

  const log = (
    type: FormEventType,
    details?: any,
    ignoreDuplicates = false,
    timestamp = new Date()
  ) => {
    if (ignoreDuplicates) {
      const lastEvent = window.eventLog[window.eventLog.length - 1];
      if (
        lastEvent &&
        lastEvent.type === type &&
        JSON.stringify(lastEvent.details) === JSON.stringify(details)
      )
        return;
    }

    const event: FormEvent = {
      type,
      givingFormId,
      givingFormVersion,
      givingFormVariant,
      organizationId,
      campaignId,
      referenceCode,
      visitorId,
      sessionId,
      timestamp: timestamp.toString(),
      url
    };

    if (details) {
      event.details = details;
    }

    window.eventLog.push(event);
    googleTagManager.sendEvent(event);
    keenActivityTracking.trackActivity(event);
  };

  return { log };
};
