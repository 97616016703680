import { resolvedEnvironment } from 'services/environment';

declare global {
  interface Window {
    _sift: string[][];
  }
}

// eslint-disable-next-line no-underscore-dangle
window._sift = window._sift || [];

let isSiftLoaded = false;

export const loadSiftLibrary = (sessionId: string) => {
  /* eslint-disable no-underscore-dangle */

  // prevent Sift from being loaded multiple times
  if (isSiftLoaded) {
    return;
  }

  isSiftLoaded = true;

  window._sift.push(['_setAccount', resolvedEnvironment.siftBeaconKey]);
  window._sift.push(['_setSessionId', sessionId]);
  window._sift.push(['_trackPageview']); // track initial load, without a userId

  function loadScript() {
    const e = document.createElement('script');
    e.src = 'https://cdn.sift.com/s.js';
    document.body.appendChild(e);
  }

  if (document.readyState === 'complete') {
    loadScript();
  } else {
    window.addEventListener('load', loadScript, false);
  }
};

export const submitSiftTracking = (userId: string) => {
  /* eslint-disable no-underscore-dangle */
  window._sift.push(['_setUserId', userId]);
  window._sift.push(['_trackPageview']);
};
