import { useState } from 'react';
import ComboBox from 'components/lib/ComboBox';
import Icon, { ICONS } from 'components/lib/Icon';
import { NumberTextField } from 'components/lib/TextField';
import { Designation, SelectedDesignation } from 'types';

export interface DesignationError {
  title?: {
    message: string;
  };
  amount?: {
    message: string;
  };
}

interface DesignationRowProps {
  allowAmountEdit?: boolean; // allowAmountEdit controls the two states of the designation row: having an editable amount/trash icon, and not
  designations: Designation[];
  designation: {
    title?: string;
    key?: string;
    id?: string;
    amount?: number;
  };
  onChange: (newData: Partial<SelectedDesignation>) => void;
  onDelete: () => void;
  onClear: () => void;
  showDelete: boolean;
  error?: DesignationError;
}

export const DesignationRow = ({
  allowAmountEdit = false,
  designations,
  designation,
  onChange,
  onDelete,
  onClear,
  showDelete,
  error
}: DesignationRowProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { title: comboBoxError, amount: amountError } = error || {};

  return (
    <div className="GF-DesignationPicker__Row">
      <ComboBox
        name="designation"
        value={designation as Designation}
        options={designations}
        getOptionLabel={(option) => option.title ?? ''}
        onChange={(_, value) => {
          if (value === null) {
            onClear();
            setInputValue('');
          } else {
            onChange(value as Designation);
          }
        }}
        inputValue={inputValue}
        onInputChange={(_, value) => setInputValue(value)}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        error={!!comboBoxError}
        helperText={comboBoxError?.message}
      />
      {allowAmountEdit && (
        <div className="GF-DesignationPicker__Price--Container">
          <NumberTextField
            name="designation"
            defaultValue={designation?.amount ?? ''}
            onValueChange={({ value }) => {
              onChange({
                amount: Number(value)
              });
            }}
            className="GF-DesignationPicker__Price"
            prefix="$"
            error={!!amountError}
            helperText={amountError?.message}
          />
          {showDelete && (
            <Icon
              icon={ICONS.TRASH}
              className="GF-DesignationPicker__Delete"
              onClick={onDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};
