import React from 'react';
import { TypographyProps } from '@mui/material';
import Typography from '@mui/material/Typography';

// Valid variant types for Text Component
export type TextVariants =
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h6Regular'
  | 'h7'
  | 'h8'
  | 'body'
  | 'caption';

// fixed types to be able to support 'component' prop
export type TextProps<C extends React.ElementType> = TypographyProps<
  C,
  { component?: C }
> & {
  variant: TextVariants;
};

/**
 * Base level GMS Text Component
 * More about Typography here - https://mui.com/api/typography/
 * @param props - Any valid MUI Typography props are forwarded to the child Typography component
 * @returns A GMS themed Typography component
 */
const Text = <T extends React.ElementType>(
  props: TextProps<T>
): JSX.Element => <Typography {...props} />;

export default Text;
