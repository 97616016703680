import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTP from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const apiKey = 'cEnF5KWbvQqjgpYKioaYLQ';
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
const detection = {
  order: ['path', 'query'],
  caches: []
};
const supportedLngs = ['en', 'es'];
const windowLanguage = window.navigator.language;
i18next
  .use(HTTP)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: windowLanguage,
    fallbackLng: 'en',
    detection,
    ns: ['default'],
    defaultNS: 'default',
    supportedLngs,
    backend: {
      loadPath
    }
  });
export default i18next;
