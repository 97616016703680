import { useState } from 'react';
import KeenTracking from 'keen-tracking';
import { useParams } from 'react-router-dom';
import { resolvedEnvironment } from 'services/environment';
import { useGivingFormData } from 'hooks';

export const usePageVisitTracking = ({ sessionId }: { sessionId: string }) => {
  const { givingFormId } = useParams();
  const {
    campaignId,
    givingFormVersion,
    givingFormVariant,
    organizationId,
    referenceCode
  } = useGivingFormData();
  const [hasTrackedPageVisit, setHasTrackedPageVisit] = useState(false);

  const trackPageVisit = async () => {
    try {
      const KeenClient = new KeenTracking({
        projectId: resolvedEnvironment.keenProjectId,
        writeKey: resolvedEnvironment.keenWriteKey
      });

      const commonFields = {
        session_id: sessionId,
        embed_id: givingFormId,
        embed_type: 'giving-form',
        organization_id: organizationId,
        campaign_id: campaignId,
        embed_url: window.location.href,
        reference_code: referenceCode
      };

      KeenClient.recordEvent('Sessions', {
        common: {
          ...commonFields,
          action: 'start',
          action_target: 'session',
          action_target_name: null,
          ...(givingFormVariant
            ? {
                giving_form_variant: givingFormVariant === 'VariantA' ? 0 : 1
              }
            : {}),
          giving_form_version: givingFormVersion
        }
      });

      KeenClient.recordEvent('PageHits', {
        common: {
          ...commonFields,
          action: 'load',
          action_target: 'page',
          action_target_name: 'start'
        },
        keen: {
          addons: [
            {
              name: 'keen:ip_to_geo',
              input: {
                ip: 'addon_support.ip_address'
              },
              output: 'ip_geo_info'
            },
            {
              name: 'keen:ua_parser',
              input: {
                ua_string: 'addon_support.user_agent'
              },
              output: 'parsed_user_agent'
            },
            {
              name: 'keen:url_parser',
              input: {
                url: 'addon_support.page_url'
              },
              output: 'parsed_page_url'
            },
            {
              name: 'keen:referrer_parser',
              input: {
                referrer_url: 'addon_support.referrer_url',
                page_url: 'addon_support.page_url'
              },
              output: 'referrer.info'
            }
          ]
        },
        addon_support: {
          // eslint-disable-next-line no-template-curly-in-string
          ip_address: '${keen.ip}',
          // eslint-disable-next-line no-template-curly-in-string
          user_agent: '${keen.user_agent}',
          page_url: window.location.href,
          referrer_url: window.location.href
        }
      });
    } catch (err) {
      // For now, we are not going to bother the user with an error here
    } finally {
      setHasTrackedPageVisit(true);
    }
  };

  return {
    hasTrackedPageVisit,
    trackPageVisit
  };
};
