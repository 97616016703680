import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Text from 'components/lib/Text';
import { NumberTextField } from 'components/lib/TextField';
import './CCPaymentInfo.scss';

const CCExpDate = () => {
  const { control } = useFormContext();

  return (
    <Grid className="GF__GridInputWrapper" item xs={6} sm={3}>
      <Text className="GF-CCPaymentInfo__heading" variant="h5">
        Expiration *
      </Text>
      <Controller
        name="creditCard.expirationDate"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <NumberTextField
            {...field}
            className="GF-CCPaymentInfo__textField GF-CCPaymentInfo__expiration-date"
            error={!!error}
            format="##/##"
            fullWidth
            helperText={error?.message ?? null}
            placeholder="MM/YY"
          />
        )}
      />
    </Grid>
  );
};

export default CCExpDate;
