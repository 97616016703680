import { Theme, createTheme } from '@mui/material';
import { createButtonTheme } from 'components/lib/Button';
import { createCheckboxTheme } from 'components/lib/Checkbox';
import { createDatePickerTheme } from 'components/lib/DatePicker';
import { createDialogTheme } from 'components/lib/Dialog';
import { createFormHelperTextTheme } from 'components/lib/FormHelperText';
import { createMenuTheme } from 'components/lib/Menu';
import { createRadioTheme } from 'components/lib/Radio';
import { createSelectTheme } from 'components/lib/Select';
import { createTypographyTheme } from 'components/lib/Text';
import { createTextFieldTheme } from 'components/lib/TextField';
import { createTooltipTheme } from 'components/lib/Tooltip';
import { IGivingFormFonts } from 'types';
import { createColorTheme } from './palette';

const initFoundationThemes = (currTheme: Theme): Theme => {
  let theme = currTheme;

  theme = createColorTheme(theme);
  theme = createTypographyTheme(theme);
  theme = createTextFieldTheme(theme);

  return theme;
};

export const initComponentThemes = (currTheme: Theme): Theme => {
  let theme = currTheme;

  theme = createButtonTheme(theme);
  theme = createCheckboxTheme(theme);
  theme = createTextFieldTheme(theme);
  theme = createDialogTheme(theme);
  theme = createMenuTheme(theme);
  theme = createTooltipTheme(theme);
  theme = createSelectTheme(theme);
  theme = createRadioTheme(theme);
  theme = createFormHelperTextTheme(theme);
  theme = createDatePickerTheme(theme);
  theme = createTypographyTheme(theme);

  return theme;
};

export const initFontThemes = (
  currTheme: Theme,
  font: IGivingFormFonts
): Theme => {
  let theme = currTheme;

  theme = createTheme(theme, {
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.GF-Header__title': {
              fontFamily: font.header
            },

            '&.GF-Header__subtitle': {
              fontFamily: font.headerDescription
            },

            '&.GF-GiftOptions__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-RecurringOptions__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-PaymentOptions__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-BillingInfo__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-CCPaymentInfo__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-CustomField__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-CoverTransactionFee__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-GiveAnonymously__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-Designations__heading': {
              fontFamily: font.formSubheadings
            },
            '&.GF-GiftOptions__subtext': {
              fontFamily: font.formBody
            },
            '&.GF-GiftOptions__tag': {
              fontFamily: font.formBody
            },
            '&.GF-Designations__subtext': {
              fontFamily: font.formBody
            },
            '&.GF-AdvancedPaymentOption__label': {
              fontFamily: font.formBody
            },
            '&.GF-CoverTransactionFee__message': {
              fontFamily: font.formBody
            },
            '&.GF-CoverTransactionFee__label': {
              fontFamily: font.formBody
            },
            '&.GF-GiveAnonymously__message': {
              fontFamily: font.formBody
            },
            '&.GF-GiveAnonymously__label': {
              fontFamily: font.formBody
            },

            '&.GF-GiftOptions__button': {
              fontFamily: font.selectors
            },
            '&.GF-Footer__text': {
              fontFamily: font.footer
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.GF-GiftOptions__button': {
              fontFamily: font.selectors
            },
            '&.GF-RecurringOptions__button': {
              fontFamily: font.selectors
            },
            '&.GF-Submit': {
              fontFamily: font.selectors
            }

            // TODO: CH - Add style overrides for submit button
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: `${font.selectors} !important`
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root': {
              '.MuiSelect-select p': {
                fontFamily: font.selectors
              },
              'fieldset legend': {
                fontFamily: font.selectors
              },
              '.MuiInputBase-input': {
                fontFamily: `${font.selectors} !important`
              }
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            p: {
              fontFamily: font.selectors
            }
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '+ span': {
              fontFamily: font.selectors
            }
          }
        }
      }
    }
  });

  return theme;
};

const initTheme = (): Theme => {
  /**
   * This theme setup follows the pattern referenced in
   * https://mui.com/customization/theming/#createtheme-options-args-theme
   */
  // initialize theme object with default spacing unit
  let theme = createTheme({ spacing: 16 });

  theme = initFoundationThemes(theme);
  theme = initComponentThemes(theme);

  return theme;
};

const theTheme = initTheme();
// console.log(theTheme);

export { theTheme };
