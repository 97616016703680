import { Theme, createTheme } from '@mui/material';

export const createDialogTheme = (currTheme: Theme): Theme => {
  const theme = createTheme(currTheme, {
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: `
            border-radius: 8px;
            max-width: 64rem
          `
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            ...currTheme.typography.h2,
            color: currTheme.palette.common.white,
            backgroundColor: currTheme.palette.primary.main,
            margin: 0,
            padding: '1.5rem 2rem',
            textAlign: 'center'
          }
        },
        defaultProps: {
          component: 'h1'
        }
      },
      MuiDialogContent: {
        defaultProps: {
          dividers: true
        },
        styleOverrides: {
          root: `
            padding: 2.5rem;
            border-top: 0;
            border-bottom: 0;

            ${currTheme.breakpoints.down(600)} {
              padding: 1.5rem;
            }
          `
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: currTheme.typography.body
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: `
            justify-content: flex-start;
            padding: 1.5rem 2.5rem;
            background: #F8F8FA;
          `
        }
      }
    }
  });

  return theme;
};
