import * as yup from 'yup';
import { ICustomFieldBlock } from 'types';

/**
 * Function to generate schema for a custom field.
 * @param customField - A custom field block to derive schema from
 * @returns - Schema object that can be concatonated into larger schema
 */
export const CustomFieldSchema = (customField: ICustomFieldBlock) => {
  /**
   * Mapping of custom field type to expected validation
   */
  const validationMap = {
    text: yup.string(),
    textarea: yup.string(),
    date: yup.date().typeError('Must be a valid date'),
    number: yup.number().typeError('Must be a valid number'),
    currency: yup.number().typeError('Must be a valid number'),
    singleChoice: yup.string(),
    dropdown: yup.string()
  };

  /**
   * Object derived from customField argument that represents each customField in the donation objecy and its
   * appropriate validation logic
   */
  const {
    fieldInfo: { type, required },
    reportingKey
  } = customField;

  const validation = required
    ? validationMap[type].required('This field is required')
    : validationMap[type].nullable(true);

  const schema = yup.object({
    [`customField-${reportingKey}`]: validation
  });

  return schema;
};
