export const enum FontOptions {
  Bodoni = '"Bodoni Moda", serif',
  CrimsonPro = '"Crimson Pro", serif',
  EbGaramond = '"EB Garamond", serif',
  Helvetica = '"Helvetica", sans-serif',
  HelveticaNeue = '"Helvetica Neue", sans-serif',
  Jost = '"Jost", sans-serif',
  Kameron = '"Kameron", serif',
  LibreBaskerville = '"Libre Baskerville", sans-serif',
  LibreFranklin = '"Libre Franklin", sans-serif',
  Montserrat = '"Montserrat", sans-serif',
  Nunito = '"Nunito", sans-serif',
  Poppins = '"Poppins", sans-serif',
  TimesNewRoman = '"Times New Roman", serif',
  Tinos = '"Tinos", serif'
}
