import * as yup from 'yup';

// custom validation written as date input is not a true date type field
const validDateString = (inputDate: string | undefined): boolean => {
  let valid = true;
  if (!inputDate) return valid;

  const inputMonth = parseInt(inputDate.slice(0, 2), 10);
  const inputYear = 2000 + parseInt(inputDate.slice(2), 10);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  if (Number.isNaN(inputMonth) || Number.isNaN(inputYear)) {
    valid = false;
  } else if (inputMonth < 1 || inputMonth > 12) {
    valid = false;
  } else {
    valid =
      inputYear > currentYear ||
      (inputYear === currentYear && inputMonth >= currentMonth);
  }

  return valid;
};

type CCPaymentInfoSchemaProps = {
  creditCardCc: string;
  creditCardCvv: string;
  isCardConnect?: boolean;
};

export const CCPaymentInfoSchema = ({
  creditCardCc,
  creditCardCvv,
  isCardConnect
}: CCPaymentInfoSchemaProps) =>
  yup.object({
    creditCard: yup
      .object()
      .when('paymentOption', {
        is: 'CREDIT',
        then: yup.object({
          ...{
            expirationDate: yup
              .string()
              .required('Expiration date is required')
              .test('valid-date', 'Invalid date', validDateString)
          },
          ...(creditCardCc !== 'valid'
            ? { cc: yup.string().required('Credit Card is required') }
            : {}),
          ...(creditCardCvv !== 'valid'
            ? { cvv: yup.string().required('CVV is required') }
            : {})
        })
      })
      .when('paymentOption', {
        is: 'DEBIT',
        then: yup.object({
          ...(isCardConnect
            ? {}
            : {
                expirationDate: yup
                  .string()
                  .required('Expiration date is required')
                  .test('valid-date', 'Invalid date', validDateString)
              })
        })
      })
  });

export default CCPaymentInfoSchema;
