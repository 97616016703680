import { EmailBlockBaseType } from '.';
import { HeaderBlock } from './HeaderBlock';

export enum EmailBlockTypes {
  Header = 'Header',
  CustomContent = 'CustomContent',
  DonationDetails = 'DonationDetails',
  DonationSummary = 'DonationSummary'
}

export enum EmailType {
  Receipt = 'Receipt'
}

export const enum EmailFontOptions {
  Helvetica = '"Helvetica", sans-serif',
  LibreBaskerville = '"Libre Baskerville", sans-serif',
  TimesNewRoman = '"Times New Roman", serif'
}
export interface EmailTheme {
  /**
   * A background theme color
   */
  background: string;

  /**
   * A text color
   */
  text?: string;

  /**
   * A theme font
   */
  font: EmailFontOptions;
}

export interface EmailConfig {
  header: HeaderBlock;
  blocks: EmailBlockBaseType[];
  subjectLine: string;
  theme: EmailTheme;
}
