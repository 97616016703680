import * as yup from 'yup';

export const BillingInfoSchema = ({
  enableCompanyName,
  enableInternationalDonations,
  enableMiddleName,
  enablePhone,
  enablePrefix
}: {
  enableCompanyName?: boolean;
  enableInternationalDonations?: boolean;
  enableMiddleName?: boolean;
  enablePhone?: boolean;
  enablePrefix?: boolean;
}) => {
  const schemaShape = {
    firstName: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
        isThirdPartyPaySelected
          ? schema.notRequired()
          : schema.required('First name is required')
      ),
    lastName: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
        isThirdPartyPaySelected
          ? schema.notRequired()
          : schema.required('Last name is required')
      ),
    email: yup
      .string()
      .email('Email must be valid')
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
        isThirdPartyPaySelected
          ? schema.notRequired()
          : schema.required('Email is required')
      ),
    confirmEmail: yup.string(),
    address1: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
        isThirdPartyPaySelected
          ? schema.notRequired()
          : schema.required('Address is required')
      ),
    address2: yup.string(),
    city: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
        isThirdPartyPaySelected
          ? schema.notRequired()
          : schema.required('City is required')
      ),
    state: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) => {
        if (isThirdPartyPaySelected) {
          // if google pay is selected, we want to always return "not required"
          return schema.notRequired();
        }

        // otherwise we need to return the schema with additional validation tacked on
        return schema.when('country', {
          is: 'US',
          then: yup.string().required('State is required'),
          otherwise: yup.string().notRequired()
        });
      }),
    province: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) => {
        if (isThirdPartyPaySelected) {
          // if google pay is selected, we want to always return "not required"
          return schema.notRequired();
        }

        // otherwise we need to return the schema with additional validation tacked on
        return schema.when('country', {
          is: 'US',
          then: yup.string().notRequired(),
          otherwise: yup.string().required('State is required')
        });
      }),
    postalCode: yup
      .string()
      .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) => {
        if (isThirdPartyPaySelected) {
          // if google pay is selected, we want to always return "not required"
          return schema.notRequired();
        }

        // postal code is required for all countries, but we also validate
        // the format for US, Canada, and Australia
        return schema
          .when('country', {
            is: 'US',
            // Allow 5 digit zip or 9 digit zip with hyphen or space
            // e.g., 75218 or 75218-3479 or 75218 3479
            then: yup
              .string()
              .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid postal code format')
          })
          .when('country', {
            is: 'CA',
            // Canadian postal code format is LetterDigitLetter DigitLetterDigit
            // and can have a hyphen or space or no space in the middle
            // e.g., K1A-0B1 or K1A 0B1 or K1A0B1
            then: yup
              .string()
              .matches(
                /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
                'Invalid postal code format'
              )
          })
          .when('country', {
            is: 'AU',
            // Australian postal code format is 4 digits
            then: yup
              .string()
              .matches(/^[0-9]{4}$/, 'Invalid postal code format')
          })
          .required('Postal Code is required');
      }),
    companyName: yup
      .string()
      .when('$isOrganizationRequired', (isOrganizationRequired, schema) =>
        isOrganizationRequired
          ? schema.required('Organization Name is required')
          : schema.notRequired()
      ),
    ...(enableInternationalDonations
      ? { country: yup.string().required('Country is required') }
      : {}),
    ...(enableMiddleName ? { middleName: yup.string() } : {}),
    ...(enablePhone
      ? {
          phoneNumber: yup.string().nullable()
          // note kstickel 4/7/22: phone number requirement will
          // eventually be a configurable in giving form editor,
          // pending new designs for editor. Until requirement
          // toggle is available, phone number will not be a
          // required field
          // .phone test prevents form from being submitted when
          // phone field is blank. needs to be refactored
          // .phone(undefined, undefined, 'Phone number must be valid')
          // .required('Phone number is required')
        }
      : {}),
    ...(enablePrefix ? { prefix: yup.string() } : {})
  };

  const billingSchema = yup.object({
    billing: yup.object(schemaShape).required()
  });

  return billingSchema;
};
