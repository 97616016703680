import { startOfToday } from 'date-fns';
import * as yup from 'yup';
import {
  AllPaymentOptions,
  IRecurringOptionsBlock,
  RecurringMethod,
  RecurringOptionsType
} from 'types';

export const RecurringOptionsSchema = ({
  showStartDate,
  recurringMethod
}: IRecurringOptionsBlock) =>
  yup.object({
    recurringOption: yup.string().when('paymentOption', {
      is: (paymentOptionValue: string) =>
        paymentOptionValue === AllPaymentOptions.APPLEPAY ||
        paymentOptionValue === AllPaymentOptions.GOOGLEPAY,
      then: yup.string(),
      otherwise: yup.string().required('Please select a donation frequency')
    }),
    startDate: showStartDate
      ? yup.string().when('recurringOption', {
          is: (recurringOption: RecurringOptionsType) =>
            recurringOption === RecurringOptionsType.Once,
          then: yup.string(),
          otherwise: yup
            .string()
            .test({
              name: 'startDate',
              test: (value, ctx) => {
                if (value && startOfToday() > new Date(value)) {
                  return ctx.createError({
                    message: 'Start date cannot be in the past'
                  });
                }
                return true;
              }
            })
            .required('Please select a start date')
        })
      : yup.string(),
    endDate: yup.string().test({
      name: 'endDate',
      test: (value, ctx) => {
        const { startDate, recurringOption } = ctx.parent;

        if (recurringOption === RecurringOptionsType.Once) {
          return true;
        }

        if (startDate && value && new Date(startDate) > new Date(value)) {
          return ctx.createError({
            message: 'End date cannot be less than start date'
          });
        }
        if (!startDate && value && startOfToday() > new Date(value)) {
          return ctx.createError({
            message: 'End date cannot be in the past'
          });
        }
        return true;
      }
    }),
    endCount:
      recurringMethod === RecurringMethod.Count
        ? yup.string().when('recurringOption', {
            is: (recurringOption: RecurringOptionsType) =>
              recurringOption === RecurringOptionsType.Once,
            then: yup.string(),
            otherwise: yup
              .string()
              .required('Please enter a number of donations')
              .test({
                name: 'endCount',
                test: (value, ctx) => {
                  if (value && parseInt(value, 10) < 1) {
                    return ctx.createError({
                      message: 'End count cannot be 0'
                    });
                  }
                  return true;
                }
              })
          })
        : yup.string()
  });
