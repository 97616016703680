import React, { createContext, useMemo, useState } from 'react';

export type CreditCardInfo = {
  brand?: string;
  lastFourDigits?: string;
};

type CreditCardValidationContext = {
  creditCardCc: string;
  setCreditCardCc(cc: string): void;
  creditCardCvv: string;
  setCreditCardCvv(cvv: string): void;
};

type CreditCardValidationProviderProps = {
  children: React.ReactNode;
};

export const CreditCardValidationContext =
  createContext<CreditCardValidationContext | null>(null);

export const CreditCardValidationProvider = ({
  children
}: CreditCardValidationProviderProps) => {
  const [creditCardCc, setCreditCardCc] = useState<string>('untouched');
  const [creditCardCvv, setCreditCardCvv] = useState<string>('untouched');

  const context = useMemo(
    () => ({
      creditCardCc,
      setCreditCardCc,
      creditCardCvv,
      setCreditCardCvv
    }),
    [creditCardCc, creditCardCvv]
  );

  return (
    <CreditCardValidationContext.Provider value={context}>
      {children}
    </CreditCardValidationContext.Provider>
  );
};
