import { Box } from '@mui/material';
import Text from 'components/lib/Text';
import './Header.scss';

type HeaderProps = {
  title: string;
  subtitle?: string;
};

const Header = ({ title, subtitle }: HeaderProps) => (
  <Box className="GF-Header" bgcolor="">
    <Text
      className="GF-Header__title"
      variant="h1"
      color="accent.main"
      textAlign="center"
    >
      {title}
    </Text>
    {!!subtitle && (
      <Text
        className="GF-Header__subtitle"
        variant="h8"
        color="accent.main"
        textAlign="center"
        mt={1}
      >
        {subtitle}
      </Text>
    )}
  </Box>
);

export default Header;
