import { Box } from '@mui/material';
import parse from 'html-react-parser';
import './HostedPageHeader.scss';

type HostedPageHeaderProps = {
  html: string;
  headerBanner?: string | null | undefined;
};

export const HostedPageHeader = ({
  html,
  headerBanner
}: HostedPageHeaderProps) => {
  const bannerUrl = headerBanner?.includes('data:image')
    ? headerBanner
    : `${headerBanner}?${new Date().getTime()}`;
  return (
    <Box className="HP-Header">
      <div
        className="HP-Header-banner-html-wrapper"
        style={{
          backgroundImage: `url(${bannerUrl})`
        }}
      >
        {parse(html)}
      </div>
    </Box>
  );
};
