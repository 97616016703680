/**
 * getAttributeWithDataFallback will use the data-* fallback if attribute does not exist using the qualifiedName
 * @param element - target element
 * @param qualifiedName - name of attribute
 * @returns any
 */
export const getAttributeWithDataFallback = (
  element: Element,
  qualifiedName: string
) =>
  element?.getAttribute(qualifiedName) ||
  element?.getAttribute(`data-${qualifiedName}`);

/**
 * supportsUserEntry returns true if provided HTML element allows for user entry
 * @param element - target element
 * @returns any
 */
export const supportsUserEntry = (element: Element): boolean => {
  if (element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
    return true;
  }

  if (element.hasAttribute('contenteditable')) {
    return true;
  }

  if (element.tagName === 'INPUT') {
    const inputEntryTypes = [
      'date',
      'datetime-local',
      'email',
      'month',
      'number',
      'password',
      'search',
      'tel',
      'text',
      'time',
      'url',
      'week'
    ];
    const type = (element.getAttribute('type') || 'text').toLowerCase();
    return inputEntryTypes.indexOf(type) > -1;
  }

  return false;
};
