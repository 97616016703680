import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTheme } from '@mui/material';
import clsx from 'clsx';
import { UseFormReset, useFormContext } from 'react-hook-form';
import { PlaidLinkError } from 'react-plaid-link';
import { ScrollPositions } from 'components/EventHub';
import { IGivingFormSchema } from 'components/GivingForm';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogContentTitle,
  DialogTitle
} from 'components/lib/Dialog';
import Text from 'components/lib/Text';
import { NumberTextField } from 'components/lib/TextField';
import { useGivingFormData } from 'hooks';
import { usePlaid } from 'hooks/usePlaid';
import { RecurringGiftEquation, RecurringOptionsType } from 'types';
import { usdFormatter } from 'utils';
import './RecurringGiftPrompt.scss';
import { RecurringGiftSvg } from './RecurringGiftSvg';

type RecurringGiftPromptProps = {
  description: string;
  emitFormPageChange: (scrollPosition: ScrollPositions) => void;
  equation: RecurringGiftEquation;
  hasAttemptedDonation: RecurringOptionsType | false;
  header: string;
  isEditMode: boolean;
  isPreviewMode: boolean;
  onClose: () => void;
  open: boolean;
  plaidIsComplete: boolean;
  resetForm: UseFormReset<IGivingFormSchema>;
  setAmountIsFromRecurringGiftPrompt: Dispatch<SetStateAction<boolean>>;
  setDonationComplete: Dispatch<SetStateAction<boolean>>;
  setHasAttemptedDonation: Dispatch<
    SetStateAction<false | RecurringOptionsType>
  >;
  setPlaidError: (val: PlaidLinkError) => void;
  setPlaidIsComplete: Dispatch<SetStateAction<boolean>>;
  submitDonation: (givingFormData: IGivingFormSchema) => Promise<void>;
};

const MINIMUM_DONATION_AMOUNT = 5;
const MAXIMUM_DONATION_AMOUNT = 250000;

export const RecurringGiftPrompt = ({
  description,
  emitFormPageChange,
  equation,
  hasAttemptedDonation,
  header,
  isEditMode,
  isPreviewMode,
  onClose,
  open,
  plaidIsComplete,
  resetForm,
  setAmountIsFromRecurringGiftPrompt,
  setDonationComplete,
  setHasAttemptedDonation,
  setPlaidError,
  setPlaidIsComplete,
  submitDonation
}: RecurringGiftPromptProps) => {
  // used theme and style-related props in this component due to the copious
  // amount of theming
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const primaryContrastColor = theme.palette.getContrastText(primaryColor) as
    | '#fff'
    | 'rgba(0, 0, 0, 0.87)';
  const isLightPrimaryColor = primaryContrastColor === 'rgba(0, 0, 0, 0.87)';
  const { watch, setValue, handleSubmit } = useFormContext<IGivingFormSchema>();
  const { showPlaidOnSubmit } = useGivingFormData();

  const { initPlaid } = usePlaid({
    resetForm,
    setValue,
    setPlaidError,
    setPlaidIsComplete
  });

  const giftAmount = watch('giftAmount');
  const [amountError, setAmountError] = useState<string>();
  const [calculatedRecurringGiftAmount, setCalculatedRecurringGiftAmount] =
    useState<number>(giftAmount);

  useEffect(() => {
    const calculated = Math.round((giftAmount * equation.percentage) / 100);
    setCalculatedRecurringGiftAmount(
      calculated < MINIMUM_DONATION_AMOUNT
        ? MINIMUM_DONATION_AMOUNT
        : calculated
    );
  }, [giftAmount, equation.percentage]);

  const isNoSelectedGiftAmount = !giftAmount;

  const makeRecurringDonation = (close: () => void) => {
    if (isEditMode) {
      return;
    }

    // if a donor selects a one time gift amount that is above the Plaid threshold,
    // then updates their donation to be recurring and the amount is below the threshold,
    // we will ignore the threshold and still present them with Plaid to complete their donation
    setAmountIsFromRecurringGiftPrompt(true);

    setValue('giftAmount', calculatedRecurringGiftAmount);
    setValue('recurringOption', RecurringOptionsType.Monthly);
    setValue('startDate', new Date().toISOString());
    setValue('coverTransactionFee', false);
    setHasAttemptedDonation(RecurringOptionsType.Monthly);

    close();
  };

  const makeSingleDonation = (close: () => void) => {
    if (isEditMode) {
      return;
    }

    setValue('recurringOption', RecurringOptionsType.Once);
    setHasAttemptedDonation(RecurringOptionsType.Once);

    close();
  };

  useEffect(() => {
    if (hasAttemptedDonation) {
      if (!isPreviewMode) {
        if (showPlaidOnSubmit && !plaidIsComplete) {
          initPlaid();
        } else {
          handleSubmit(submitDonation)();
        }
      } else {
        setDonationComplete(true);
        emitFormPageChange(ScrollPositions.top);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAttemptedDonation, isPreviewMode]);

  const isEditingOrPreviewing =
    isEditMode || (isPreviewMode && isNoSelectedGiftAmount);

  const modalRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (open) {
      const currentRef = modalRef.current;
      if (currentRef) {
        currentRef.scrollIntoView();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, modalRef.current]);

  return (
    <Dialog
      className={clsx('GF-RecurringGiftPrompt', {
        'edit-mode': isEditingOrPreviewing
      })}
      label="recurring-gift-dialog"
      open={open}
      onClose={() => (isEditMode ? null : onClose())}
    >
      <DialogTitle
        className="GF-RecurringGiftPrompt__title"
        onClick={(close) => close()}
      >
        Recurring Gift
      </DialogTitle>
      <DialogContent className="GF-RecurringGiftPrompt__content-container">
        <RecurringGiftSvg />
        <div className="left-form" ref={modalRef as RefObject<HTMLDivElement>}>
          {!!header && (
            <DialogContentTitle className="GF-RecurringGiftPrompt__header">
              {header}
            </DialogContentTitle>
          )}
          {!!description && (
            <Text
              variant="body"
              className="GF-RecurringGiftPrompt__description"
            >
              {description}
            </Text>
          )}
          <Text
            variant="h5"
            className="GF-RecurringGiftPrompt__recurring-amount-label"
          >
            Recurring Amount:&nbsp;&nbsp;
            <Text
              variant="h5"
              as="span"
              className="GF-RecurringGiftPrompt__recurring-amount-value"
            >
              {isEditingOrPreviewing && '$X.XX'}
              {!isEditingOrPreviewing && (
                <NumberTextField
                  name="recurringGiftAmount"
                  hiddenLabel
                  error={!!amountError}
                  helperText={amountError}
                  placeholder="$ Other"
                  isNumericString
                  thousandSeparator
                  prefix="$"
                  value={calculatedRecurringGiftAmount}
                  onChange={(value: string) => {
                    const castedAmount = Number(value);
                    if (castedAmount < MINIMUM_DONATION_AMOUNT) {
                      setAmountError(
                        `Amount must be at least ${usdFormatter.format(
                          MINIMUM_DONATION_AMOUNT
                        )}`
                      );
                    } else if (castedAmount > MAXIMUM_DONATION_AMOUNT) {
                      setAmountError(
                        `Amount must be less than ${usdFormatter.format(
                          MAXIMUM_DONATION_AMOUNT
                        )}`
                      );
                    } else {
                      setAmountError('');
                    }
                    setCalculatedRecurringGiftAmount(Number(value));
                  }}
                />
              )}
              &nbsp; /monthly
            </Text>
          </Text>
        </div>
      </DialogContent>
      <DialogActions className="GF-RecurringGiftPrompt__buttons">
        <DialogActionButton
          name="recurringGiftPrompt"
          className="GF-RecurringGiftPrompt__button"
          variant="primary"
          disabled={!!amountError}
          onClick={makeRecurringDonation}
        >
          {`Give ${
            isEditingOrPreviewing
              ? '$X.XX'
              : usdFormatter.format(calculatedRecurringGiftAmount)
          } Monthly`}
        </DialogActionButton>
        <DialogActionButton
          name="recurringGiftPrompt"
          className="GF-RecurringGiftPrompt__button"
          variant="text"
          onClick={makeSingleDonation}
          sx={{
            borderColor: isLightPrimaryColor
              ? primaryContrastColor
              : primaryColor,
            color: isLightPrimaryColor ? primaryContrastColor : primaryColor,
            background: 'none',
            '&:hover': {
              background: 'none'
            }
          }}
        >
          {`No Thanks, Give ${
            isEditingOrPreviewing
              ? '$X.XX'
              : `${usdFormatter.format(giftAmount)}`
          } Once`}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  );
};
