import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import allCountries from 'country-region-data/data.json';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { IGivingFormSchema } from 'components/GivingForm';
import Dialog, {
  DialogActionButton,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/lib/Dialog';
import Icon, { ICONS } from 'components/lib/Icon';
import Select, { SelectOption } from 'components/lib/Select';
import Text from 'components/lib/Text';
import TextField from 'components/lib/TextField';
import { TributeBlockType } from 'types';
import prefixes from 'utils/prefixes.json';
import { TributeSchema } from './Tribute.schema';
import './TributeModal.scss';

const COUNTRY_OPTIONS = allCountries.map((item) => ({
  label: item.countryName,
  value: item.countryShortCode
}));

interface TributeModalProps extends TributeBlockType {
  open: boolean;
  onClose: () => void;
  isTributeSelected: boolean;
  setIsTributeSelected: Dispatch<SetStateAction<boolean>>;
}

const GRID_SPACING = 0.75;

type TributeDialogFormValues = {
  tributePrefix: string;
  tributePurpose: 'honor' | 'memory';
  tributeFirstName: string;
  tributeLastName: string;
  tributeRecipientFirstName: string;
  tributeRecipientLastName: string;
  tributeEmail: string;
  tributeCountry: string;
  tributeAddress1: string;
  tributeAddress2: string;
  tributeCity: string;
  tributeState: string;
  tributePostalCode: string;
  tributeFromName: string;
  ecardOptIn: boolean;
  ecardCustomMessage: string;
  ecardImageUrl?: string;
};

export const TributeModal = ({
  open,
  onClose,
  isTributeSelected,
  setIsTributeSelected,
  tributeInstruction,
  isEmailEnabled,
  isTitleEnabled,
  isTributeMessageEnabled,
  isAddressEnabled,
  isDonorNameEnabled,
  ecard
}: TributeModalProps) => {
  const { setValue: setGFFormValue, watch: watchGFForm } =
    useFormContext<IGivingFormSchema>();
  const [tributeStep, setTributeStep] = useState<number>(0);

  const [donorFirstName, donorLastName, tribute] = watchGFForm([
    'billing.firstName',
    'billing.lastName',
    'tribute'
  ]);
  const donorName = `${donorFirstName}${' '}${donorLastName}`;

  const { control, handleSubmit, watch, setValue } =
    useForm<TributeDialogFormValues>({
      defaultValues: {
        tributePrefix: 'None',
        tributeFirstName: '',
        tributeLastName: '',
        tributeRecipientFirstName: '',
        tributeRecipientLastName: '',
        tributeEmail: '',
        tributeCountry: 'US',
        tributeAddress1: '',
        tributeAddress2: '',
        tributeCity: '',
        tributePostalCode: '',
        tributeFromName: donorName.length > 1 ? donorName : '',
        ...tribute
      },
      resolver: yupResolver(
        TributeSchema({
          isTitleEnabled,
          isEmailEnabled,
          isAddressEnabled,
          isDonorNameEnabled
        })
      )
    });

  const [
    ecardOptIn,
    ecardImageUrl,
    tributePurpose,
    tributeCountry,
    tributeFirstName,
    tributeLastName,
    tributeEmail,
    tributeFromName,
    tributeRecipientFirstName,
    tributeRecipientLastName
  ] = watch([
    'ecardOptIn',
    'ecardImageUrl',
    'tributePurpose',
    'tributeCountry',
    'tributeFirstName',
    'tributeLastName',
    'tributeEmail',
    'tributeFromName',
    'tributeRecipientFirstName',
    'tributeRecipientLastName'
  ]);

  useEffect(() => {
    if (!tributePurpose) {
      setValue('tributePurpose', 'honor');
      // make sure the first image is selected
      setValue('ecardImageUrl', ecard?.images?.[0]);
    }
    if (ecard?.isEnabled && ecardOptIn === undefined) {
      setValue('ecardOptIn', true);
    }
  }, [ecard?.images, ecard?.isEnabled, ecardOptIn, setValue, tributePurpose]);

  const onSubmit = (values: TributeDialogFormValues) => {
    setGFFormValue('tribute', values);
    setIsTributeSelected(true);
    onClose();
  };

  const handleNext = () => {
    if (ecard?.isEnabled) {
      if (tributeStep <= 1 || (tributeStep === 2 && ecardOptIn)) {
        setTributeStep(tributeStep + 1);
      } else {
        handleSubmit(onSubmit)();
      }
      return;
    }
    if (tributeStep === 0) {
      setTributeStep(tributeStep + 1);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const getStateOptions = () => {
    const states = allCountries.find(
      (item) => item.countryShortCode === tributeCountry
    );
    const regions = states?.regions.map((region) => ({
      label: region.name,
      value: region.shortCode
    }));

    if (regions) return regions;
    return [];
  };

  const zone = tributeCountry === 'US' ? 'State' : 'Province';

  const renderDialogContent = () => {
    switch (tributeStep) {
      case 1: {
        return (
          <DialogContent className="tribute-info-container">
            {tributeInstruction && (
              <Text className="tribute-instruction" variant="h3">
                {tributeInstruction}
              </Text>
            )}
            <Grid
              container
              rowSpacing={GRID_SPACING}
              columnSpacing={GRID_SPACING}
            >
              {isTitleEnabled && (
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="tributePrefix"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        color="primary"
                        className="tribute-prefix"
                        label="Prefix"
                        options={prefixes as SelectOption[]}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid
                item
                container
                rowSpacing={GRID_SPACING}
                columnSpacing={GRID_SPACING}
              >
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="tributeFirstName"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Tribute First Name *"
                        className="tribute-first-name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="tributeLastName"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Tribute Last Name *"
                        className="tribute-last-name"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                rowSpacing={GRID_SPACING}
                columnSpacing={GRID_SPACING}
              >
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="tributeRecipientFirstName"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Recipient First Name *"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="tributeRecipientLastName"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Recipient Last Name *"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {isEmailEnabled && (
                <Grid item xs={12}>
                  <Controller
                    name="tributeEmail"
                    control={control}
                    render={({
                      field: { ref, ...field },
                      fieldState: { error }
                    }) => (
                      <TextField
                        {...field}
                        fullWidth
                        className="tribute-email"
                        label="Recipient Email"
                        error={!!error}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                </Grid>
              )}
              {isAddressEnabled && (
                <Grid
                  item
                  container
                  rowSpacing={GRID_SPACING}
                  columnSpacing={GRID_SPACING}
                >
                  <Grid item xs={12}>
                    <Controller
                      name="tributeCountry"
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...field }
                      }) => (
                        <Select
                          {...field}
                          defaultValue={value}
                          className="tribute-country"
                          onChange={(event) => {
                            onChange(event);
                            // Clear the state/province if the country has changed
                            if (event.target.value !== value) {
                              setValue('tributeState', '');
                            }
                          }}
                          color="primary"
                          label="Country"
                          options={COUNTRY_OPTIONS as SelectOption[]}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="tributeAddress1"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          className="tribute-address-one"
                          fullWidth
                          label="Recipient Address 1"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="tributeAddress2"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          className="tribute-address-two"
                          fullWidth
                          label="Recipient Address 2"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Controller
                      name="tributeCity"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          className="tribute-city"
                          fullWidth
                          label="City"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name="tributeState"
                      control={control}
                      render={({
                        field: { ref, onChange, value, ...field }
                      }) => (
                        <Select
                          {...field}
                          key={value}
                          defaultValue={value}
                          label={zone}
                          className="tribute-state"
                          onChange={(event) => {
                            onChange(event);
                          }}
                          options={getStateOptions() as SelectOption[]}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="tributePostalCode"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          className="tribute-postal-code"
                          fullWidth
                          label="Postal Code"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              {isTributeMessageEnabled && (
                <Grid item xs={12}>
                  <Text className="tribute-ecard-custom-message" variant="h5">
                    Add a Custom Message
                  </Text>
                  <Controller
                    name="ecardCustomMessage"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        className="tribute-ecard-custom-message"
                        fullWidth
                        multiline
                        maxRows={3}
                        minRows={3}
                        placeholder="Enter Message"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="tributeFromName"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <TextField
                      {...field}
                      className="tribute-from-name"
                      defaultValue={value}
                      fullWidth
                      label='"From" Name *'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
        );
      }
      case 2: {
        return (
          <DialogContent className="tribute-ecard-optin-container">
            <Text variant="h2">
              Would you like to send an e-card to your tribute?
            </Text>
            <div className="tribute-ecard-optin-button-container">
              <button
                type="button"
                className={clsx('tribute-modal-circular-buttons', {
                  selected: ecardOptIn === true
                })}
                onClick={() => {
                  setValue('ecardOptIn', true);
                }}
              >
                <Text variant="h2">Yes!</Text>
              </button>
              <button
                type="button"
                className={clsx('tribute-modal-circular-buttons', {
                  selected: ecardOptIn === false
                })}
                onClick={() => {
                  setValue('ecardOptIn', false);
                }}
              >
                <Text variant="h2">Not Today</Text>
              </button>
            </div>
          </DialogContent>
        );
      }
      case 3: {
        return (
          <DialogContent className="tribute-ecard-container">
            <Text className="tribute-ecard-instruction" variant="h3">
              {ecard?.ecardInstruction}
            </Text>
            <div className="ecard-image-container">
              {ecard?.images.map((image) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={`image_${image}`}
                  className={clsx('ecard-image', {
                    selected: ecardImageUrl === image
                  })}
                  onClick={() => setValue('ecardImageUrl', image)}
                >
                  <img src={image} alt={image} />
                </div>
              ))}
            </div>
            <Grid
              container
              rowSpacing={GRID_SPACING}
              columnSpacing={GRID_SPACING}
            >
              {ecard?.isCustomMessageEnabled && (
                <Grid item xs={12}>
                  <Text className="tribute-ecard-custom-message" variant="h5">
                    Add a Custom Message
                  </Text>
                  <Controller
                    name="ecardCustomMessage"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        className="tribute-ecard-custom-message"
                        fullWidth
                        multiline
                        maxRows={3}
                        minRows={3}
                        placeholder="Enter Message"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Text className="tribute-ecard-email" variant="h5">
                  Email
                </Text>
                <Controller
                  name="tributeEmail"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      className="tribute-ecard-email"
                      fullWidth
                      placeholder="Enter Email *"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
        );
      }
      default: {
        return (
          <DialogContent className="tribute-purpose-container">
            <Text variant="h2">Select the purpose for your tribute.</Text>
            <div className="tribute-purpose-button-container">
              <button
                type="button"
                className={clsx(
                  'tribute-modal-circular-buttons tribute-purpose-button',
                  {
                    selected: tributePurpose === 'honor'
                  }
                )}
                onClick={() => {
                  setValue('tributePurpose', 'honor');
                }}
              >
                <Icon icon={ICONS.AWARD} />
                <Text variant="h5">In Honor</Text>
              </button>
              <button
                type="button"
                className={clsx(
                  'tribute-modal-circular-buttons tribute-purpose-button',
                  {
                    selected: tributePurpose === 'memory'
                  }
                )}
                onClick={() => {
                  setValue('tributePurpose', 'memory');
                }}
              >
                <Icon icon={ICONS.HEART} />
                <Text variant="h5">In Memory</Text>
              </button>
            </div>
          </DialogContent>
        );
      }
    }
  };

  const getPrimaryButtonText = () => {
    switch (tributeStep) {
      case 1: {
        if (ecard?.isEnabled) {
          return 'Next';
        }
        return isTributeSelected ? 'Update Tribute' : 'Add Tribute';
      }
      case 2: {
        if (ecardOptIn) {
          return 'Next';
        }
        return isTributeSelected ? 'Update Tribute' : 'Add Tribute';
      }
      case 3: {
        return isTributeSelected ? 'Update Tribute' : 'Add Tribute';
      }
      default: {
        return 'Next';
      }
    }
  };

  return (
    <Dialog
      classes={{ paper: 'tribute-modal' }}
      label="Tribute"
      open={open}
      onClose={() => onClose()}
      container={() => document.getElementById('tribute-modal-target')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Tribute</DialogTitle>
        {renderDialogContent()}

        <DialogActions>
          {tributeStep > 0 && (
            <DialogActionButton
              variant="secondary"
              onClick={() => setTributeStep(tributeStep - 1)}
              name="tribute"
            >
              Back
            </DialogActionButton>
          )}
          <DialogActionButton
            variant="primary"
            name="tribute"
            onClick={handleNext}
            disabled={
              (tributeStep === 1 &&
                (!tributeFirstName ||
                  !tributeLastName ||
                  !tributeFromName ||
                  !tributeRecipientFirstName ||
                  !tributeRecipientLastName)) ||
              (tributeStep === 3 && !tributeEmail)
            }
          >
            {getPrimaryButtonText()}
          </DialogActionButton>
          <DialogActionButton variant="text" name="tribute" onClick={onClose}>
            Cancel
          </DialogActionButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
