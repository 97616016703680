import BlockBaseType from './BlockBaseType';

export enum RecurringOptionsType {
  Once = 'Once',
  Weekly = 'Weekly',
  FirstAndFifteenth = '1st and 15th',
  EveryTwoWeeks = 'Every 2 Weeks',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnually = 'Semi-Annually',
  Annually = 'Annually'
}

export enum RecurringMethod {
  NoEnd = 'No End',
  EndDate = 'End Date',
  Count = 'Count'
}

export interface IRecurringOptionsBlock extends BlockBaseType {
  recurringOptions: RecurringOptionsType[];
  defaultOption?: RecurringOptionsType;
  showStartDate?: boolean;
  recurringMethod?: RecurringMethod;
  indefiniteEndNoticeMessage?: string;
}
