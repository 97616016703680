import { Dispatch, SetStateAction, useState } from 'react';
import { Stack } from '@mui/material';
import ACHPaymentInfo from 'components/ACHPaymentInfo';
import CCPaymentInfo from 'components/CCPaymentInfo';
import PaymentOptions from 'components/PaymentOptions';
import Icon, { ICONS } from 'components/lib/Icon';
import Text from 'components/lib/Text';
import { useGivingFormData } from 'hooks';
import { CreditCardTypes, IPaymentInfoSection } from 'types';
import BillingInfo from '../BillingInfo/BillingInfo';
import './PaymentSection.scss';

interface PaymentSectionProps extends IPaymentInfoSection {
  currentCardType: CreditCardTypes[];
  setCurrentCardType: Dispatch<SetStateAction<CreditCardTypes[]>>;
  showCCPaymentInfo: boolean;
  setShowCCPaymentInfo: Dispatch<SetStateAction<boolean>>;
  showGooglePay: boolean;
  setShowGooglePay: Dispatch<SetStateAction<boolean>>;
  showApplePay: boolean;
  setShowApplePay: Dispatch<SetStateAction<boolean>>;
  amountIsFromRecurringGiftPrompt: boolean;
}

const PaymentSection = ({
  billingInfoBlock,
  paymentOptionsBlock,
  paymentGateway,
  currentCardType,
  setCurrentCardType,
  showCCPaymentInfo,
  setShowCCPaymentInfo,
  showGooglePay,
  setShowGooglePay,
  showApplePay,
  setShowApplePay,
  amountIsFromRecurringGiftPrompt
}: PaymentSectionProps) => {
  const [showACHPaymentInfo, setShowACHPaymentInfo] = useState(false);
  const { showPlaidOnSubmit } = useGivingFormData();

  return (
    <Stack spacing={1.5}>
      <PaymentOptions
        {...paymentOptionsBlock}
        setShowCCPaymentInfo={setShowCCPaymentInfo}
        setShowACHPaymentInfo={setShowACHPaymentInfo}
        setShowGooglePay={setShowGooglePay}
        setShowApplePay={setShowApplePay}
        amountIsFromRecurringGiftPrompt={amountIsFromRecurringGiftPrompt}
      />
      {!showGooglePay && !showApplePay && !showPlaidOnSubmit && (
        <BillingInfo {...billingInfoBlock} />
      )}
      {(showACHPaymentInfo || showCCPaymentInfo) && (
        <div className="GF-PaymentSectionInputs">
          {showCCPaymentInfo && (
            <CCPaymentInfo
              paymentGateway={paymentGateway}
              currentCardType={currentCardType}
              setCurrentCardType={setCurrentCardType}
            />
          )}
          {showACHPaymentInfo && <ACHPaymentInfo />}
          <Text
            className="GF-PaymentSectionInputs-SecureText"
            id="GF-PaymentSectionInputs-SecureText"
            variant="h5"
          >
            <Icon icon={ICONS.LOCK} />
            Your payment information is secure
          </Text>
        </div>
      )}
    </Stack>
  );
};

export default PaymentSection;
