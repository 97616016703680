import { Theme, createTheme } from '@mui/material';

export const createRadioTheme = (currTheme: Theme): Theme => {
  const {
    palette: { primary }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      MuiRadio: {
        defaultProps: {
          // Disable default MUI animations
          disableRipple: false,
          disableFocusRipple: true
        },
        styleOverrides: {
          root: {
            span: {
              borderColor: primary.main,
              ':before': {
                backgroundColor: primary.main
              }
            },
            input: {
              ':hover + span': {
                borderColor: primary.main,
                ':before': {
                  backgroundColor: primary.main
                }
              }
            }
          }
        }
      }
    }
  });

  return theme;
};
