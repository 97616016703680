import { FC } from 'react';
import { SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';

export interface IconProps extends SvgIconProps {
  icon: FC;
}

/**
 * An Icon component meant to use icons from
 * https://tabler-icons.io with a 1.5px stroke width
 * Height and width are, by default, 1.5rem (24px).
 */
const Icon = ({ icon, sx, ...props }: IconProps): JSX.Element => (
  <MuiSvgIcon
    component={icon}
    sx={{
      strokeWidth: 1.5,
      stroke: 'currentColor',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      ...sx
    }}
    {...props}
  />
);

// the actual component
export default Icon;
