import clsx from 'clsx';
import { addDays, addMonths, addQuarters, addWeeks, addYears } from 'date-fns';
import { SubmitDonationDataType } from 'services/donationService';
import { BlockTypes, RecurringOptionsType } from 'types';
import BlockBaseType from 'types/givingForm/BlockBaseType';

export const getGivingFormPage = (
  blocks: React.ReactElement<BlockBaseType>[],
  pageIndex: number
) => {
  const paginatedBlocks = blocks.reduce(
    (
      acc: React.ReactElement<BlockBaseType>[][],
      block: React.ReactElement<BlockBaseType>
    ) => {
      acc[acc.length - 1].push(block);
      if (block.props.blockType === BlockTypes.PageBreakBlock) {
        acc.push([]);
      }

      return acc;
    },
    [[]]
  );

  return paginatedBlocks.map(
    (block: React.ReactElement<BlockBaseType>[], index: number) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={clsx('gf-page-segment', {
          'gf-hidden-block': index !== pageIndex
        })}
      >
        {block}
      </div>
    )
  );
};

/**
 * Takes !object and remaps the customField keys from being root-level to under a single customFields key.
 * Necessary for shaping the donation object to match expected Backend DTO.
 *
 * Note this will mutate your givingFormSubmission.
 */

/* eslint-disable no-param-reassign */
export const mapCustomFieldToDonation = (
  givingFormSubmission: Partial<SubmitDonationDataType>
) => {
  const submissionObjectKeys = Object.keys(givingFormSubmission);
  submissionObjectKeys.forEach((key) => {
    if (key.match(/^customField-/)) {
      // flatKey is the top-level temporary key to track custom fields, but backend expects them to be collected on the customFields object
      const flatKey = key as keyof typeof givingFormSubmission;
      const customFieldsKey = /^customField-(.*)/.exec(key)?.[1];
      if (customFieldsKey) {
        if (!givingFormSubmission.customFields) {
          givingFormSubmission.customFields = {};
        }
        givingFormSubmission.customFields[customFieldsKey] =
          givingFormSubmission[flatKey];
        delete givingFormSubmission[flatKey];
      }
    }
  });
};

export const mapRecurringEndCountToEndDate = (
  givingFormSubmission: Partial<SubmitDonationDataType>
) => {
  if (
    givingFormSubmission.recurringOption &&
    givingFormSubmission.recurringOption !== RecurringOptionsType.Once &&
    givingFormSubmission.endCount &&
    givingFormSubmission.startDate
  ) {
    const startDate = new Date(givingFormSubmission.startDate);
    const endCount = +givingFormSubmission.endCount;

    let endDate: Date | null = null;

    switch (givingFormSubmission.recurringOption) {
      case RecurringOptionsType.Weekly: {
        endDate = addWeeks(startDate, endCount - 1);
        break;
      }
      case RecurringOptionsType.FirstAndFifteenth: {
        endDate = addMonths(startDate, Math.floor((endCount - 1) / 2));

        if ((endCount - 1) % 2) {
          endDate = addDays(endDate, 15);
        }
        break;
      }
      case RecurringOptionsType.EveryTwoWeeks: {
        endDate = addWeeks(startDate, 2 * endCount);
        break;
      }
      case RecurringOptionsType.Quarterly: {
        endDate = addQuarters(startDate, endCount - 1);
        break;
      }
      case RecurringOptionsType.Monthly: {
        endDate = addMonths(startDate, endCount - 1);
        break;
      }
      case RecurringOptionsType.Annually: {
        endDate = addYears(startDate, endCount - 1);
        break;
      }
      case RecurringOptionsType.SemiAnnually: {
        endDate = addYears(startDate, 2 * (endCount - 1));
        break;
      }
      default:
    }

    if (endDate) {
      endDate = addDays(endDate, 1);
      givingFormSubmission.endDate = endDate.toISOString();
      delete givingFormSubmission.endCount;
    }
  }
};
/* eslint-enable no-param-reassign */

export const PAYMENT_OPTIONS_MAP: Record<string, string> = {
  paypal: 'PAYPAL',
  apple_pay: 'APPLEPAY',
  google_pay: 'GOOGLEPAY',
  echecks: 'ECHECK',
  creditcards: 'CREDIT',
  debitcards: 'DEBIT'
};
