import * as yup from 'yup';

export const GiftOptionsSchema = ({
  min = 5,
  max = 250000
}: {
  min?: number;
  max?: number;
}) => {
  const schemaShape = {
    giftAmount: yup
      .number()
      .typeError('Amount must be a number')
      .required('Please select a donation amount or specify a custom amount')
  };

  if (min) {
    schemaShape.giftAmount = yup
      .number()
      .concat(schemaShape.giftAmount)
      .when('$isCustomGift', (isCustomGift, schema) =>
        isCustomGift
          ? schema.min(min, `Amount must be at least $${min.toLocaleString()}`)
          : schema
      );
  }
  if (max) {
    schemaShape.giftAmount = yup
      .number()
      .concat(schemaShape.giftAmount)
      .max(max, `Amount must be at most $${max.toLocaleString()}`);
  }
  return yup.object(schemaShape);
};
