import * as yup from 'yup';
import 'yup-phone';
import { ACHPaymentInfoSchema } from 'components/ACHPaymentInfo';
import { BillingInfoSchema } from 'components/BillingInfo/BillingInfo.schema';
import { CCPaymentInfoSchema } from 'components/CCPaymentInfo';
import { CustomFieldSchema } from 'components/CustomField/CustomField.schema';
import { DesignationsSchema } from 'components/DesignationsBlock/Designations.schema';
import { GiftOptionsSchema } from 'components/GiftOptions/GiftOptions.schema';
import { PaymentOptionsSchema } from 'components/PaymentOptions/PaymentOptions.schema';
import { RecurringOptionsSchema } from 'components/RecurringOptions/RecurringOptions.schema';
import {
  AllPaymentOptions,
  BlockTypes,
  Designation,
  GivingFormBlockType,
  ICustomFieldBlock,
  IGiftOptionsBlock,
  IGivingFormConfig,
  IPaymentGateway,
  IPaymentInfoSection,
  IRecurringOptionsBlock,
  IThankYouGiftBlock
} from 'types';
import {
  getGivingFormBlock,
  recurringOnlyHasOnceOption
} from 'utils/blockUtils';

interface DesignationForSubmission extends Designation {
  amount: number;
}

export interface IGivingFormSchema {
  giftAmount: number;
  coverTransactionFee: boolean;
  recurringOption: string;
  paymentOption: string;
  creditCard?: {
    cc: string;
    cvv: string;
    expirationDate: string;
  };
  designation: DesignationForSubmission[];
  billing: {
    prefix?: string;
    firstName: string;
    middleName: string;
    lastName: string;
    companyName: string;
    email: string;
    phoneNumber?: string;
    country: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    province: string;
    postalCode: string;
  };
  ach?: {
    routingNumber: string;
    accountNumber: string;
    confirmAccountNumber: string;
  };
  anonymousGift?: boolean;
  corporateMatchingCompanyId?: string;
  tribute?: {
    tributePurpose: 'honor' | 'memory';
    tributePrefix: string;
    tributeFirstName: string;
    tributeLastName: string;
    tributeRecipientFirstName: string;
    tributeRecipientLastName: string;
    tributeEmail: string;
    tributeCountry: string;
    tributeAddress1: string;
    tributeAddress2: string;
    tributeCity: string;
    tributeState: string;
    tributePostalCode: string;
    tributeFromName: string;
    ecardCustomMessage: string;
  };
  thankYouGift?: Omit<IThankYouGiftBlock, 'id' | 'blockType' | 'isEnabled'>;
  /* eslint-disable camelcase */
  custom_note_1?: string;
  custom_note_2?: string;
  custom_note_3?: string;
  custom_note_4?: string;
  custom_note_5?: string;
  designation_note?: string;
  /* eslint-enable camelcase */
  startDate?: string;
  endDate?: string;
  endCount?: string;
}

export type IExtendedGivingFormSchema = IGivingFormSchema & {
  [customFields: string]: unknown;
};

export const createGivingFormSchema = (
  givingFormConfig: IGivingFormConfig | undefined,
  creditCardValidation: {
    creditCardCc: string;
    creditCardCvv: string;
  },
  paymentGateway?: IPaymentGateway
): { schema: yup.AnyObjectSchema; pages: yup.AnyObjectSchema[] } => {
  if (!givingFormConfig) {
    return { schema: yup.object(), pages: [] }; // To allow rest of application to load properly, return empty schema until a givingFormConfig is available
  }

  const isCardConnect = paymentGateway?.backendName === 'card_connect';

  // As we create the overall schema, we also want to create sub-page validation chunks for supporting multi-page forms
  // The sub-page schemas are tracked in the pages array and the index corresponds to the page number
  let schema = yup.object();
  const pages: yup.AnyObjectSchema[] = [yup.object()];
  givingFormConfig.blocks.forEach(
    ({ blockType, ...block }: GivingFormBlockType) => {
      const lastIndex = pages.length - 1;
      if (blockType === BlockTypes.PageBreakBlock) {
        pages.push(yup.object());
        return;
      }
      if (
        blockType === BlockTypes.RecurringOptions &&
        !recurringOnlyHasOnceOption(block as IRecurringOptionsBlock)
      ) {
        const subSchema = RecurringOptionsSchema(
          block as IRecurringOptionsBlock
        );
        schema = schema.concat(subSchema);
        pages[lastIndex] = pages[lastIndex].concat(subSchema);
        return;
      }
      if (blockType === BlockTypes.GiftOptionBlock) {
        const { min, max } = block as IGiftOptionsBlock;
        const subSchema = GiftOptionsSchema({ min, max });

        schema = schema.concat(subSchema);
        pages[lastIndex] = pages[lastIndex].concat(subSchema);
        return;
      }
      if (blockType === BlockTypes.DesignationsBlock) {
        const { max } = getGivingFormBlock(
          BlockTypes.GiftOptionBlock,
          givingFormConfig
        ) as IGiftOptionsBlock;
        const subSchema = DesignationsSchema({ min: 5, max });

        schema = schema.concat(subSchema);
        pages[lastIndex] = pages[lastIndex].concat(subSchema);
      }
      if (blockType === BlockTypes.CustomFieldBlock) {
        const subSchema = CustomFieldSchema(block as ICustomFieldBlock);

        schema = schema.concat(subSchema);
        pages[lastIndex] = pages[lastIndex].concat(subSchema);
      }
      if (blockType === BlockTypes.PaymentSection) {
        const {
          enableCompanyName,
          enableInternationalDonations,
          enableMiddleName,
          enablePhone,
          enablePrefix
        } = (block as IPaymentInfoSection).billingInfoBlock;

        const subSchema = PaymentOptionsSchema()
          .concat(PaymentOptionsSchema())
          .concat(
            BillingInfoSchema({
              enableCompanyName,
              enableInternationalDonations,
              enableMiddleName,
              enablePhone,
              enablePrefix
            })
          )
          .concat(
            CCPaymentInfoSchema({ ...creditCardValidation, isCardConnect })
          )
          .concat(ACHPaymentInfoSchema())
          .when('paymentOption', {
            is: (paymentOption: string) =>
              paymentOption === AllPaymentOptions.CREDIT ||
              paymentOption === AllPaymentOptions.DEBIT,
            then: CCPaymentInfoSchema({
              ...creditCardValidation,
              isCardConnect
            }).required(),
            otherwise: CCPaymentInfoSchema({
              ...creditCardValidation,
              isCardConnect
            }).notRequired()
          })
          .when('paymentOption', {
            is: AllPaymentOptions.ECHECK,
            then: ACHPaymentInfoSchema().required(),
            otherwise: ACHPaymentInfoSchema().notRequired()
          });

        schema = schema.concat(subSchema);
        pages[lastIndex] = pages[lastIndex].concat(subSchema);
      }
    }
  );

  // If there are no page breaks, pages is length 1.  In that case, return an empty array:
  return { schema, pages: pages.length > 1 ? pages : [] };
};
