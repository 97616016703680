import * as yup from 'yup';

// ACH input fields not required when processing through Plaid (isThirdPartyPaySelected)
export const ACHPaymentInfoSchema = () =>
  yup.object({
    ach: yup.object().when('paymentOption', {
      is: 'ECHECK',
      then: yup.object({
        routingNumber: yup
          .string()
          .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
            isThirdPartyPaySelected
              ? schema.notRequired()
              : schema
                  .required('Routing number is required')
                  .matches(/^[0-9]+$/, 'Numerical values only')
                  .length(9, 'Routing number must be 9 digits')
          ),
        accountNumber: yup
          .string()
          .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
            isThirdPartyPaySelected
              ? schema.notRequired()
              : schema
                  .required('Account number is required')
                  .matches(/^[0-9]+$/, 'Numerical values only')
                  .min(4, 'Minimum 4 digits')
                  .max(17, 'Maximum 17 digits')
          ),
        confirmAccountNumber: yup
          .string()
          .when('$isThirdPartyPaySelected', (isThirdPartyPaySelected, schema) =>
            isThirdPartyPaySelected
              ? schema.notRequired()
              : schema.required('Confirm account number is required')
          )
          .test(
            'account-number-match',
            'Account numbers must match',
            // eslint-disable-next-line func-names
            function (value) {
              return this.parent.accountNumber === value;
            }
          )
      })
    })
  });
