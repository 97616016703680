import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import {
  GooglePayHandlerType,
  getGooglePayHandler,
  isApplePaySupported
} from 'services/idonateSdk';
import { IGivingFormSchema } from 'components/GivingForm';
import Button from 'components/lib/Button';
import FormHelperText from 'components/lib/FormHelperText';
import Icon from 'components/lib/Icon';
import { useGivingFormData, useQueryParam } from 'hooks';
import {
  AllPaymentOptions,
  ICONS,
  IPaymentOptionsBlock,
  PaymentIcons,
  PaymentOptionsWithIcons,
  RecurringOptionsType
} from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import { getDonationAmount } from 'utils/donationUtils';
import './PaymentOptions.scss';

interface PaymentOptionsProps extends Omit<IPaymentOptionsBlock, 'blockType'> {
  setShowCCPaymentInfo: (value: boolean) => void;
  setShowACHPaymentInfo: (value: boolean) => void;
  setShowGooglePay: (value: boolean) => void;
  setShowApplePay: (value: boolean) => void;
  amountIsFromRecurringGiftPrompt: boolean;
}

const paymentOptionIcons: PaymentIcons = {
  PAYPAL: ICONS.PAYPAL,
  APPLEPAY: ICONS.APPLEPAY,
  GOOGLEPAY: ICONS.GOOGLEPAY,
  ECHECK: ICONS.PAYPAL
};

const PaymentOptions = ({
  paymentOptions,
  setShowCCPaymentInfo,
  setShowACHPaymentInfo,
  setShowGooglePay,
  setShowApplePay,
  amountIsFromRecurringGiftPrompt
}: PaymentOptionsProps) => {
  const { control } = useFormContext<IGivingFormSchema>();
  const paymentOptionQueryParam = useQueryParam(
    IDonateQueryParam.PaymentType
  )?.toUpperCase();
  const {
    organizationId,
    organizationName,
    digitalWalletMerchantId,
    isPlaidEnabled,
    plaidThreshold,
    setShowPlaidOnSubmit
  } = useGivingFormData();
  const [googlePaySupported, setGooglePaySupported] = useState<boolean>(false);
  const [selected, setSelected] = useState<AllPaymentOptions>();
  const [echeckIsSelected, setEcheckIsSelected] = useState(false);
  const [hideNonRecurringTypes, setHideNonRecurringTypes] = useState(false);

  let giftAmount = useWatch({ control, name: 'giftAmount' });
  const designation = useWatch({ control, name: 'designation' });
  const recurringOption = useWatch({ control, name: 'recurringOption' });
  const nonRecurringTypes = [
    AllPaymentOptions.APPLEPAY,
    AllPaymentOptions.GOOGLEPAY,
    AllPaymentOptions.PAYPAL
  ];

  // the giftAmount field on the form does not get updated when donors
  // change their donation amount through multidesignations and must be calculated
  // separately. Using that gift amount to ensure Plaid logic is applied
  // correctly when donation amount is updated through multidesignations
  giftAmount = getDonationAmount(designation, giftAmount);

  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    name: 'paymentOption',
    control
  });

  useEffect(() => {
    const verifyGooglePay = async () => {
      const payHandler: GooglePayHandlerType = await getGooglePayHandler(
        organizationId as string,
        organizationName as string,
        digitalWalletMerchantId as string
      );

      // check to see if google pay is supported
      const isSupported = await payHandler.client.isReadyToPay(
        payHandler.googlePay.getGoogleIsReadyToPayRequest()
      );

      setGooglePaySupported(isSupported.result);
    };

    verifyGooglePay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptionChanged = (option: AllPaymentOptions) => {
    setShowCCPaymentInfo(
      option === AllPaymentOptions.CREDIT || option === AllPaymentOptions.DEBIT
    );
    setShowGooglePay(option === AllPaymentOptions.GOOGLEPAY);
    setShowApplePay(option === AllPaymentOptions.APPLEPAY);
    setEcheckIsSelected(option === AllPaymentOptions.ECHECK);
  };

  useEffect(() => {
    // handle logic for plaid
    // recurring gift prompt selection should not update Plaid
    if (amountIsFromRecurringGiftPrompt) return;
    if (echeckIsSelected) {
      if (isPlaidEnabled && giftAmount >= plaidThreshold) {
        setShowPlaidOnSubmit(true);
        setShowACHPaymentInfo(false);
      } else {
        setShowACHPaymentInfo(true);
        setShowPlaidOnSubmit(false);
      }
    } else {
      setShowPlaidOnSubmit(false);
      setShowACHPaymentInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaidEnabled, plaidThreshold, echeckIsSelected, giftAmount]);

  useEffect(() => {
    /**
     * If there is a payment option query param within paymentOptions, use it.
     * Otherwise, default to the first payment option
     */
    setSelected(
      paymentOptions.includes(paymentOptionQueryParam as AllPaymentOptions)
        ? (paymentOptionQueryParam as AllPaymentOptions)
        : paymentOptions?.[0]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptionQueryParam]);

  useEffect(() => {
    /**
     * This effect handles setting either the payment option from the query param
     * or the first one in an editor giving form as well as on giving form reset
     * due to config update in edit mode
     */
    if (selected) {
      // eslint-disable-next-line no-underscore-dangle
      control._defaultValues.paymentOption = selected;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selected) {
      // This line handles setting selected payment option on a live giving form
      field.onChange(selected);

      // This line handles expanding the appropriate additional fields in live
      // and editor giving form, e.g. card info inputs
      handleOptionChanged(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (recurringOption && recurringOption !== RecurringOptionsType.Once) {
      setHideNonRecurringTypes(true);
      // since we may have hid a selected non-recurring option, we need to check
      // and then set the first item as selected if it was
      if (nonRecurringTypes.includes(field.value as AllPaymentOptions)) {
        const firstVisibleOption = paymentOptions.filter(
          (p) => !nonRecurringTypes.includes(p)
        )[0];
        field.onChange(firstVisibleOption);
        handleOptionChanged(firstVisibleOption);
      }
    } else {
      setHideNonRecurringTypes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurringOption]);

  const mappedOptions = (
    <Stack
      className="GF-PaymentOptions__button--container"
      key="paymentStack"
      justifyContent="flex-start"
      alignItems="stretch"
      flexWrap="wrap"
      direction="row"
    >
      {paymentOptions?.map((option) => {
        if (nonRecurringTypes.includes(option) && hideNonRecurringTypes) {
          return null;
        }

        if (option === AllPaymentOptions.GOOGLEPAY && !googlePaySupported) {
          return null;
        }

        if (option === 'APPLEPAY' && !isApplePaySupported) {
          return null;
          // do not show ApplePay button if browser is not supported.
        }
        const baseButtonClass = 'GF-PaymentOptions__button';
        const isSelected = option === field.value;
        const buttonClass = clsx(
          baseButtonClass,
          {
            [`${baseButtonClass}--selected`]: isSelected
          },
          {
            [`${baseButtonClass}--googlepay`]:
              option === AllPaymentOptions.GOOGLEPAY
          }
        );

        return (
          <Button
            key={option}
            name="paymentOption"
            className={buttonClass}
            size="medium"
            variant="tertiary"
            title={option}
            onClick={() => {
              field.onChange(option);
              handleOptionChanged(option);
            }}
          >
            {option in paymentOptionIcons ? (
              <Icon icon={ICONS[option as PaymentOptionsWithIcons]} />
            ) : (
              option
            )}
          </Button>
        );
      })}
    </Stack>
  );

  return (
    <Box className="GF-PaymentOptions" id="GF-PaymentOptions">
      {!!error?.message && (
        <FormHelperText error>{error?.message}</FormHelperText>
      )}
      {mappedOptions}
    </Box>
  );
};
export default PaymentOptions;
