/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useMemo, useState } from 'react';
import { TokenProviderProps } from 'types';

const defaultState = {
  paymentProviderToken: '',
  setPaymentProviderToken: null as any,
  tokenResolver: null as any,
  tokenPromise: null as any
};

export const TokenContext = createContext(defaultState);

export const TokenProvider = ({ children }: TokenProviderProps) => {
  const [paymentProviderToken, setPaymentProviderToken] = useState<string>('');

  const context = useMemo(
    () => {
      let tokenResolver;
      const tokenPromise = new Promise((resolve) => {
        tokenResolver = resolve;
      });

      return {
        paymentProviderToken,
        setPaymentProviderToken,
        tokenPromise,
        tokenResolver
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentProviderToken]
  );
  return (
    <TokenContext.Provider value={context}>{children}</TokenContext.Provider>
  );
};
