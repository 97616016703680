import { Designation, IDesignationsBlock } from 'types/givingForm';
import { DesignationPicker } from './DesignationPicker';
import './Designations.scss';

type DesignationsBlockProps = IDesignationsBlock & {
  remountGiftOptions?: () => void;
};

const DesignationsBlock = ({
  designations,
  defaultDesignation,
  promptText,
  remountGiftOptions,
  singleDesignation
}: DesignationsBlockProps) => {
  const designationsToIdArray = (): string[] => {
    if (!designations || designations.length === 0) return [];
    if (typeof designations[0] === 'string') return designations as string[];

    // handle old designations data structure
    return designations.map((d) => (d as Designation).id);
  };

  const getDefaultDesignationId = () => {
    if (!defaultDesignation) return undefined;
    if (typeof defaultDesignation === 'string')
      return defaultDesignation as string;
    return defaultDesignation.id;
  };
  return (
    <DesignationPicker
      designationsFromConfig={designationsToIdArray()}
      promptText={promptText}
      defaultDesignationId={getDefaultDesignationId()}
      singleDesignation={singleDesignation}
      remountGiftOptions={remountGiftOptions}
    />
  );
};

export default DesignationsBlock;
