// cc type strings currently reflecting strings returned by Spreedly.
// will need to revisit in future when other payment providers are implemented
export enum CreditCardTypes {
  VISA = 'visa',
  MASTERCARD = 'master',
  DISCOVER = 'discover',
  AMEX = 'american_express'
}

export const acceptedCreditCards = [
  CreditCardTypes.VISA,
  CreditCardTypes.MASTERCARD,
  CreditCardTypes.DISCOVER,
  CreditCardTypes.AMEX
];
