import Button, { ButtonProps } from '../Button';
import { useDialogContext } from './Dialog';

type DialogActionButtonProps = {
  /**
   * Fired when the button is clicked.
   * A close() callback is passed for convenience to close the dialog.
   */
  onClick: (onClose: () => void) => void;
} & Omit<ButtonProps, 'onClick'>;

const DialogActionButton = ({
  onClick,
  children,
  ...props
}: DialogActionButtonProps): JSX.Element => {
  const { onClose, isSuccessVariant } = useDialogContext();
  return (
    <Button
      onClick={() => onClick(() => onClose('closeButtonClick'))}
      fullWidth={isSuccessVariant}
      {...props}
    >
      {children}
    </Button>
  );
};

export default DialogActionButton;
