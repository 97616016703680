/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { GlobalStyles, Theme, ThemeProvider, createTheme } from '@mui/material';
import clsx from 'clsx';
import {
  DragDropContext,
  DragStart,
  DragUpdate,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable
} from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import {
  getGivingFormIdFromHostedSlugs,
  getGivingFormIdFromVanityDomain
} from 'services/givingFormService';
import CheckeredBackgroundImage from 'assets/checkered-background.png';
import CustomCSS from 'components/CustomCSS';
import { HostedPageEditWrapper } from 'components/EditWrapper';
import useEventHub, {
  EditorEventTypes,
  EventHubEvent,
  ZoomEmitValues
} from 'components/EventHub';
import { HostedPageBlockOrderUpdatePayload } from 'components/EventHub/EventHub.types';
import { GoalMeter } from 'components/GoalMeter';
import { HostedPageCustomContentBlock } from 'components/HostedPageCustomContentBlock';
import { HostedPageFooter } from 'components/HostedPageFooter';
import { HostedPageHeader } from 'components/HostedPageHeader';
import { useGivingFormData } from 'hooks';
import { initComponentThemes, initFontThemes, theTheme } from 'theme';
import { GoalMeterConfig, IGivingFormConfig } from 'types';
import {
  BannerSelections,
  HostedPageBlockBaseType,
  HostedPageBlockTypes,
  HostedPageLayout,
  HostedPageSections,
  IHostedPageCustomContentBlock
} from 'types/givingForm/HostedPage';
import './HostedPage.scss';

const EDIT_PAGE_WIDTH = '1280px'; // Full size width used for edit mode zoom and calculations.  This value is also replicated in the scss file, any updates should be reflected in both locations
const EDIT_ZOOM_STEP = 0.07; // Relative step amount for zoom calculations, e.g. 0.07 would be a 7% change at each zoom level

// use giving form config theme value to create new MUI theme
const { augmentColor } = theTheme.palette;

export const createGivingFormTheme = (
  currTheme: Theme,
  givingFormConfig: IGivingFormConfig
) => {
  let theme = createTheme(currTheme, {
    palette: {
      primary: augmentColor({
        color: { main: givingFormConfig.theme.primary }
      }),
      accent: augmentColor({
        color: { main: givingFormConfig.theme.accent ?? '#FFF' }
      })
    }
  });
  theme = initComponentThemes(theme);
  theme = initFontThemes(theme, givingFormConfig.theme.font);
  return theme;
};

interface HostedPageProps {
  vanityDomain?: string | null;
}

export const HostedPage = ({
  vanityDomain = null
}: HostedPageProps): JSX.Element => {
  const {
    givingFormId: givingFormIdFromRoute,
    organizationSlug,
    hostedPageSlug,
    vanitySlug
  } = useParams();

  const {
    config: givingFormConfig,
    overwriteConfig,
    loadingError,
    givingFormId,
    setGivingFormId,
    isLoaded,
    givingFormName
  } = useGivingFormData();

  const [hostedPageGivingFormId, setHostedPageGivingFormId] = useState<string>(
    givingFormIdFromRoute || ''
  );

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [highlightedEditBlock, setHighlightedEditBlock] = useState<string>('');
  const [headerBannerImage, setHeaderBannerImage] = useState<string>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeVisible, setIframeVisible] = useState<boolean>(false);
  const [iframeHeight, setIFrameHeight] = useState<string>('');
  const [iframeReady, setIframeReady] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number | null>(0);
  const zoomRef = useRef(zoomLevel); // useRef to keep zoom level in sync with zoomLevel state
  const sideDropzoneRef = useRef<HTMLDivElement | null>(null); // Ref to side section droppable, needed for layout effect update and calculations
  const [currentDragEvent, setCurrentDragEvent] = useState<
    DragStart | DragUpdate | null
  >(null); // Information from the onDragStart and onDragUpdate needed for conditional rendering checks
  const draggedElementHeightRef = useRef<number | null>(null); // Height of the block being dragged, used for offset preview display

  let lastBodyHeightTS: number; // Track last time a height update occurred for syncing with scroll events
  // This setup is for the GF iframe <-> HP eventHub
  const setupEventHandlers = (eventHub: any, iframe: HTMLIFrameElement) => {
    eventHub.subscribe(EditorEventTypes.BodyHeightUpdate, (height: string) => {
      lastBodyHeightTS = Date.now();
      setIFrameHeight(height);
    });

    eventHub.subscribe(EditorEventTypes.PageLoaded, () => {
      setIframeVisible(true);
    });

    if (isEditMode || isPreviewMode) {
      eventHub.emit('ConfigurationUpdate', givingFormConfig);
    }

    eventHub.subscribe(
      EditorEventTypes.FormPageChange,
      (scrollPosition: string) => {
        // The scroll event is initially received prior to body height updates, need to ensure we take action after that occurs
        const currentTime = Date.now();
        const WAIT_FOR_HEIGHT_EVENT_TIMEOUT = 1000;
        const scroll = (blockValue: ScrollLogicalPosition) => {
          iframe.scrollIntoView({
            block: blockValue,
            inline: 'nearest',
            behavior: 'smooth'
          });
        };
        const id = setInterval(() => {
          // Take action once body height event has occurred, or until we reach a timeout
          if (
            lastBodyHeightTS > currentTime ||
            Date.now() - currentTime > WAIT_FOR_HEIGHT_EVENT_TIMEOUT
          ) {
            clearInterval(id);
            // Need iframe position to determine if top/bottom has left the viewport
            const { top: topPosition, bottom: bottomPosition } =
              iframe.getBoundingClientRect();
            if (scrollPosition === 'top') {
              // Only scroll if the top of the embed is off screen
              if (
                topPosition < 0 ||
                topPosition > document.documentElement.clientHeight
              ) {
                scroll('start');
              }
            } else if (scrollPosition === 'bottom') {
              // Only scroll if the bottom of the embed is off screen
              if (
                bottomPosition < 0 ||
                bottomPosition > document.documentElement.clientHeight
              ) {
                scroll('end');
              }
            }
          }
        }, 250);
      }
    );
  };
  const wireEventHub = (iframe: any, previewMode: boolean) =>
    new Promise((resolve) => {
      const messageChannel = new MessageChannel();
      const subscribers: Record<string, Array<(e: any) => void>> = {};

      const eventHub = {
        emit: (name: string, payload: any) => {
          const event = { name, payload };
          // console.log(`🔽 Event: "${event.name}"`, event.payload);
          messageChannel.port1.postMessage(event);
        },
        subscribe: (name: string, callback: () => void) => {
          subscribers[name] = subscribers[name] || [];
          subscribers[name].push(callback);
        }
      };

      setupEventHandlers(eventHub, iframe);

      messageChannel.port1.onmessage = (e) => {
        // console.log(`🔼 Event: "${event.name}"`, event.payload);
        const callbacks = subscribers[e.data.name] || [];
        callbacks.forEach((x) => x(e.data.payload));
      };
      iframe?.contentWindow.postMessage(
        { name: 'EventHub', props: previewMode ? { mode: 'PREVIEW' } : {} },
        window.location.origin,
        [messageChannel.port2]
      );

      resolve(eventHub);
    });

  useEffect(() => {
    if (iframeReady || !givingFormConfig) {
      return;
    }
    const iframeReadyListener = (message: MessageEvent) => {
      if (message.data.name !== EditorEventTypes.PageReady) {
        return;
      }

      // Preview mode needs to be conditional on the mode of the hosted page
      wireEventHub(
        iframeRef.current,
        // givingFormId is the slug and if in edit mode it will include edit
        isEditMode ||
          isPreviewMode ||
          !!hostedPageGivingFormId?.includes('edit')
      );
      setIframeReady(true);
      window.removeEventListener('message', iframeReadyListener);
    };

    window.addEventListener('message', iframeReadyListener);
    // Destructor incase we need cleanup before it happens inside iframeReadyListener handler:
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('message', iframeReadyListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingFormConfig]);

  // The clientHeight of the side drop area is dependent on the iframeheight as well, so must use layout effect to capture this change after the dom updates
  useLayoutEffect(() => {
    if (sideDropzoneRef.current) {
      const sideDropDiv = sideDropzoneRef.current;
      const sideContainer = sideDropDiv.parentElement;
      if (sideContainer) {
        sideContainer.style.minHeight = `max(${iframeHeight}, ${sideDropDiv.clientHeight}px)`;
      }
    }
  }, [iframeHeight, givingFormConfig?.hostedPageConfig?.sideBlocks]);

  // After clicking the "Fit to Height" button, the zoomlevel will be null. If a user were to then zoom in or out, that
  // new zoom level would be based on the previous zoom level. Instead, we need to estimate what zoomlevel fitting to height
  // gives us so that our subsequent zoom in/ zoom out will be based on this estimated value
  const calculateClosestZoom = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fullHeight = window.document
      .querySelector('#root')!
      .getBoundingClientRect().height;
    const bodyHeight = window.document.body.getBoundingClientRect().height;
    const heightScaleRatio = bodyHeight / fullHeight;
    const fullWidth = Number.parseInt(EDIT_PAGE_WIDTH, 10);
    const width = window.innerWidth;
    const widthScaleRatio = width / fullWidth;
    const closestZoomLevel =
      Math.log(heightScaleRatio / widthScaleRatio) /
      Math.log(1 + EDIT_ZOOM_STEP);
    return Math.round(closestZoomLevel);
  };

  // This setup is for the editor <-> HP iframe eventHub:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { eventHub } = useEventHub((initializeEventHub: any) => {
    if (initializeEventHub) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      initializeEventHub.then((eventHubRef: any) => {
        if (eventHubRef.props?.mode === 'EDIT') {
          setIsEditMode(true);
        } else if (eventHubRef.props?.mode === 'PREVIEW') {
          setIsPreviewMode(true);
        }
        eventHubRef.subscribe(
          EditorEventTypes.ConfigurationUpdate,
          ({ payload }: EventHubEvent) => {
            overwriteConfig(payload as IGivingFormConfig);
            return eventHubRef;
          }
        );
        eventHubRef.subscribe(
          EditorEventTypes.SetBannerImage,
          ({ payload }: EventHubEvent) => {
            setHeaderBannerImage(payload as string);
            return eventHubRef;
          }
        );
        eventHubRef.subscribe(
          EditorEventTypes.EditBlock,
          ({ payload }: EventHubEvent) => {
            setHighlightedEditBlock(payload.id);
            return eventHubRef;
          }
        );
        eventHubRef.subscribe(EditorEventTypes.DeselectBlock, () => {
          setHighlightedEditBlock('');
          return eventHubRef;
        });
        eventHubRef.subscribe(
          EditorEventTypes.PreviewZoom,
          ({ payload }: EventHubEvent) => {
            switch (payload.zoomInstruction) {
              case ZoomEmitValues.Increase:
                if (zoomRef.current !== null) {
                  setZoomLevel((currZoom) => {
                    if (currZoom !== null) {
                      return currZoom + 1;
                    }
                    return currZoom;
                  });
                  break;
                }
                setZoomLevel(calculateClosestZoom() + 1);

                break;
              case ZoomEmitValues.Decrease:
                if (zoomRef.current !== null) {
                  setZoomLevel((currZoom) => {
                    if (currZoom !== null) {
                      return currZoom - 1;
                    }
                    return currZoom;
                  });
                  break;
                }
                setZoomLevel(calculateClosestZoom() - 1);
                break;
              case ZoomEmitValues.FitToWidth:
                setZoomLevel(0);
                break;
              case ZoomEmitValues.FitToHeight:
                setZoomLevel(null);
                break;
              default:
                // eslint-disable-next-line no-console
                console.log(
                  'Unknown zoom instruction:',
                  payload.zoomInstruction
                );
            }
          }
        );

        const monitorBodyHeight = () => {
          const targetNode = document.body;
          let lastHeight = targetNode.offsetHeight;
          if (window.ResizeObserver) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const observer = new ResizeObserver((entries: any) => {
              const height = entries[0].target.offsetHeight;
              if (height && height !== lastHeight) {
                eventHubRef.emit(
                  EditorEventTypes.BodyHeightUpdate,
                  `${height}px`
                );
                lastHeight = height;
              }
            });
            observer.observe(targetNode);
          }

          if (lastHeight) {
            eventHubRef.emit(
              EditorEventTypes.BodyHeightUpdate,
              `${lastHeight}px`
            );
          }
        };

        monitorBodyHeight();
      });
    }
  });

  useEffect(() => {
    // Not `emit` since this message is sent prior to EventHub initialization
    window.parent.postMessage({ name: EditorEventTypes.PageReady }, '*');
  }, []);

  const zoomLevelCalculation = () => {
    const zoomExponent = zoomRef.current;
    // Special case where we want to zoom for height to fit in viewport
    if (zoomExponent === null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fullHeight = window.document
        .querySelector('#root')!
        .getBoundingClientRect().height;
      const height = window.innerHeight;
      const scaleRatio = height / fullHeight / 2;
      return scaleRatio;
    }
    const fullWidth = Number.parseInt(EDIT_PAGE_WIDTH, 10);
    const width = window.innerWidth;
    const scaleRatio = width / fullWidth;
    const scaleAmount = (1 + EDIT_ZOOM_STEP) ** zoomExponent * scaleRatio;
    return scaleAmount;
  };
  useEffect(() => {
    if (eventHub?.props?.mode === 'EDIT') {
      window.document.body.style.width = EDIT_PAGE_WIDTH;
      window.document.body.style.transformOrigin = 'top left';
      window.document.documentElement.style.backgroundImage = `url(${CheckeredBackgroundImage})`;
      window.document.documentElement.style.backgroundSize = '26px';
    }
  }, [eventHub?.props?.mode]);

  useEffect(() => {
    if (eventHub?.props?.mode === 'EDIT') {
      // Initial scale in edit mode is set so that EDIT_PAGE_WIDTH is fully visible in the given iframe viewport
      // zoomRef used so resize observer can get current value instead of old closure around initial value
      zoomRef.current = zoomLevel;
      window.document.body.style.transform = `scale(${zoomLevelCalculation()})`;
      window.document.body.style.maxHeight = `${
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        window.document.querySelector('#root')!.getBoundingClientRect().height
      }px`;
      const totalWidth = window.innerWidth;
      const hostedPageWidth =
        window.document.body.getBoundingClientRect().width;
      const extraWidth = totalWidth - hostedPageWidth;
      if (extraWidth > 0) {
        window.document.body.style.marginLeft = `${extraWidth / 2}px`;
      } else {
        window.document.body.style.marginLeft = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHub?.props?.mode, zoomLevel]);

  useEffect(() => {
    if (isEditMode) {
      const monitorViewport = () => {
        const targetNode = document.documentElement;
        if (window.ResizeObserver) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const observer = new ResizeObserver(() => {
            window.document.body.style.transform = `scale(${zoomLevelCalculation()})`;
          });
          observer.observe(targetNode);
        }
      };
      monitorViewport();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    if (givingFormIdFromRoute) {
      setGivingFormId(givingFormIdFromRoute);
    } else if (organizationSlug && hostedPageSlug) {
      getGivingFormIdFromHostedSlugs(organizationSlug, hostedPageSlug).then(
        (gfId) => {
          setGivingFormId(gfId);
        }
      );
    } else if (vanityDomain && vanitySlug) {
      getGivingFormIdFromVanityDomain(vanityDomain, vanitySlug).then((gfId) => {
        setGivingFormId(gfId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingFormId]);

  useEffect(() => {
    setHostedPageGivingFormId(givingFormId);
  }, [givingFormId]);

  useEffect(() => {
    if (isLoaded && eventHub) {
      eventHub.emit(EditorEventTypes.PageLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, eventHub]);

  if (loadingError) {
    // May want design to create a 404-esque page to display in the unfortunate event we cannot load a hosted page for the donor
    return <div>Unexpected error</div>;
  }

  if (!givingFormConfig || !isLoaded) {
    return hostedPageGivingFormId?.includes('edit') ? (
      // This should never be seen, but just incase it's visible in error want to show it is trying to load/pending data
      <div>Waiting for data...</div>
    ) : (
      <div>
        {/* Displayed while loading a life Hosted Page.  May want to add a loading experience in the future. */}
      </div>
    );
  }

  const emitEditEvent = (id: string) => {
    setHighlightedEditBlock(id);
    eventHub.emit(EditorEventTypes.EditBlock, { id });
  };

  const layout = givingFormConfig.hostedPageConfig?.layout;

  const emitDropEvent = (payload: HostedPageBlockOrderUpdatePayload) => {
    eventHub.emit(EditorEventTypes.BlockOrderUpdate, payload);
  };

  const dropWrapper = (
    blocks: (JSX.Element | null)[],
    droppableId: HostedPageSections
  ) => {
    // renderItem is used to display the preview that follows your mouse while dragging
    const renderItem = (
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot,
      rubric: DraggableRubric
    ) => {
      const item = blocks[rubric.source.index];
      // Due to scaling the page using `transform: scale(...)`, but many of the
      // reference values either being unaware of the scroll position of the
      // page and/or using unscaled values, we have to normalize the behavior
      // with a number of calculations and value overrides in this section for
      // the draggable item

      const zoomPercentage = zoomLevelCalculation();

      // Normally the top value used by rbd is the offset of the item from the top of the page
      // We want to preserve that, but then also account for the scroll position of the iframe
      const draggableInitialTopValue = (
        provided.draggableProps.style as DraggingStyle
      ).top;
      const offset = 20; // Slight offset to help give a more natural placement of the item over the preview space
      const topValue =
        (draggableInitialTopValue + window.scrollY + offset) / zoomPercentage;

      // Intercept the transform value from rbd so we can freeze the x-axis position, and then adjust the y-position with respect to the zoom level
      let transformFromDnd = provided.draggableProps.style?.transform || '';
      transformFromDnd = transformFromDnd.replace(
        /translate\((.+), (.+)\)/,
        `translate(0px, calc($2 / ${zoomPercentage}))`
      ); // fixed x

      const { draggingOver } = snapshot;

      const stylesToApply: CSSProperties = {
        ...provided.draggableProps.style,
        // Override certain provided styles:
        top: `${topValue}px`,
        transform: transformFromDnd,
        width: undefined, // This is overwitten by CSS styles
        left: undefined, // This is overwriten by CSS styles
        height: undefined // Remove the height value from rbd so it can expand to its content
      };

      return (
        <div
          onTransitionEnd={provided.draggableProps.onTransitionEnd}
          data-rbd-draggable-context-id={
            provided.draggableProps['data-rbd-draggable-context-id']
          }
          data-rbd-draggable-id={
            provided.draggableProps['data-rbd-draggable-id']
          }
          {...provided.dragHandleProps}
          ref={(ref) => {
            const height = (ref?.firstChild as HTMLDivElement)?.clientHeight;
            draggedElementHeightRef.current = height;
            provided.innerRef(ref);
          }}
          style={{ ...stylesToApply }}
          className={clsx('drag-preview-item', {
            [layout as string]: draggingOver === HostedPageSections.side
          })}
        >
          {item}
        </div>
      );
    };

    // Ensure the `side` droppable zone is at least as tall as the iframe so there are no intermittent dead zones
    const droppableStyle: CSSProperties = {};
    if (
      droppableId === HostedPageSections.side &&
      layout !== HostedPageLayout.FullWidth
    ) {
      droppableStyle.minHeight = `max(${iframeHeight}, 5rem)`;
    }

    // Next section is to determine when we need to add an element to increase the space of the droppable zone while dragging:
    let placeholderSpacer: React.ReactElement | null = null;
    const destination = (currentDragEvent as DragUpdate)?.destination;
    const source = currentDragEvent?.source;
    // The if statement is a bit complex, grabbing a few predicates into variables to make it easier to read:
    const isOverThisDroppable = destination?.droppableId === droppableId;
    const isTheSourceDroppable =
      destination && destination.droppableId === source?.droppableId;
    const isNotSameIndex = destination && destination.index !== source?.index;
    if (isOverThisDroppable) {
      if ((isTheSourceDroppable && isNotSameIndex) || !isTheSourceDroppable) {
        placeholderSpacer = (
          <div
            className="placeholder-spacer"
            style={{
              height: `${draggedElementHeightRef.current}px`
            }}
          />
        );
      }
    }

    return (
      <Droppable droppableId={droppableId} renderClone={renderItem}>
        {(droppableProvided) => (
          <div
            ref={(ref) => {
              if (droppableId === HostedPageSections.side) {
                sideDropzoneRef.current = ref;
              }
              droppableProvided.innerRef(ref);
            }}
            {...droppableProvided.droppableProps}
            style={droppableStyle}
            className={clsx({ active: currentDragEvent })}
          >
            {blocks.map((block, index) => {
              const editWrapperProps = {
                isDraggable: true,
                onEdit: () => emitEditEvent(block?.key as string),
                className: clsx({
                  'edit-options-wrapper--selected':
                    highlightedEditBlock === block?.key,
                  'is-dragging':
                    currentDragEvent?.draggableId === `${block?.key}-rbd`,
                  'disable-hover': currentDragEvent
                }),
                dndProps: {
                  blockKey: block?.key as string,
                  section: droppableId,
                  index,
                  dragEvent: currentDragEvent,
                  draggedElementHeightRef
                }
              };

              return (
                <HostedPageEditWrapper {...editWrapperProps} key={block?.key}>
                  {block}
                </HostedPageEditWrapper>
              );
            })}
            {droppableProvided.placeholder}
            {placeholderSpacer}
          </div>
        )}
      </Droppable>
    );
  };

  const convertBlocktypesToElements = (
    blocks: HostedPageBlockBaseType[] | undefined,
    side?: boolean
  ) => {
    if (!blocks) {
      return [];
    }

    return blocks.map((block: HostedPageBlockBaseType) => {
      const { id, blockType } = block;
      switch (blockType) {
        case HostedPageBlockTypes.CustomContent:
          return (
            <HostedPageCustomContentBlock
              key={id}
              {...(block as IHostedPageCustomContentBlock)}
            />
          );
        case HostedPageBlockTypes.GoalMeterBlock: {
          if ((block as GoalMeterConfig).isEnabled) {
            return (
              <GoalMeter key={id} side={side} {...(block as GoalMeterConfig)} />
            );
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return null;
        }
        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <React.Fragment key={id} />;
      }
    });
  };
  const headerBlock = givingFormConfig.hostedPageConfig?.header;
  let headerBannerImageSrc;
  if (
    headerBlock?.banner.isEnabled &&
    headerBlock?.banner.selectedBanner === BannerSelections.IMAGE
  ) {
    if (isEditMode || isPreviewMode) {
      if (headerBannerImage) {
        headerBannerImageSrc = headerBannerImage;
      } else if (headerBannerImage === null) {
        headerBannerImageSrc = null;
      } else if (headerBlock.banner.croppedImageUrl) {
        headerBannerImageSrc = headerBlock.banner.croppedImageUrl;
      }
    } else {
      headerBannerImageSrc = headerBlock.banner.croppedImageUrl;
    }
  }
  const headerBlockElement = headerBlock ? (
    <HostedPageHeader
      key={headerBlock.id}
      html={headerBlock.html}
      headerBanner={headerBannerImageSrc}
    />
  ) : null;
  const topBlockElements = convertBlocktypesToElements(
    givingFormConfig.hostedPageConfig?.topBlocks
  );
  const sideBlockElements = convertBlocktypesToElements(
    givingFormConfig.hostedPageConfig?.sideBlocks,
    true
  );
  const bottomBlockElements = convertBlocktypesToElements(
    givingFormConfig.hostedPageConfig?.bottomBlocks
  );
  const footerBlock = givingFormConfig.hostedPageConfig?.footer;
  const footerBlockElement = footerBlock ? (
    <HostedPageFooter key={footerBlock.id} html={footerBlock.html} />
  ) : null;

  const theme = createGivingFormTheme(theTheme, givingFormConfig);
  const hostedPageElement = (
    <div
      className={clsx('hosted-page-blocks', layout, {
        'edit-mode': isEditMode
      })}
      onPointerMove={(e: React.PointerEvent<HTMLDivElement>) => {
        const { pageX, clientX } = e;
        const hpBlocksDiv = e.currentTarget;
        const hpWidth = hpBlocksDiv.getBoundingClientRect().width;
        // scroll left
        if (currentDragEvent && window.scrollX !== 0) {
          if (1 - (hpWidth - pageX) / pageX <= 0.3) {
            window.scroll({ left: -(hpWidth - clientX), behavior: 'smooth' });
          }
        }

        // scroll right
        if (currentDragEvent && window.scrollX === 0) {
          if (1 - (hpWidth - pageX) / pageX >= 0.3) {
            window.scroll({ left: hpWidth - clientX, behavior: 'smooth' });
          }
        }
      }}
    >
      {headerBlockElement && (
        <div
          className={clsx('hosted-page-header', {
            disabled: !givingFormConfig?.hostedPageConfig?.header.isEnabled
          })}
        >
          {isEditMode ? (
            <HostedPageEditWrapper
              onEdit={() => emitEditEvent(headerBlockElement.key as string)}
              className={clsx({
                'edit-options-wrapper--selected':
                  highlightedEditBlock === headerBlockElement.key,
                'disable-hover': currentDragEvent
              })}
              compress
              isDraggable={false}
            >
              {headerBlockElement}
            </HostedPageEditWrapper>
          ) : (
            headerBlockElement
          )}
        </div>
      )}
      <DragDropContext
        onDragUpdate={(dragUpdate) => {
          setCurrentDragEvent(dragUpdate);
        }}
        onDragStart={(dragStart) => {
          setCurrentDragEvent(dragStart);
        }}
        onDragEnd={(x) => {
          setCurrentDragEvent(null);

          if (!x.destination) {
            return;
          }

          const target = {
            index: x.destination.index,
            section: x.destination.droppableId as HostedPageSections
          };
          const from = {
            index: x.source.index,
            section: x.source.droppableId as HostedPageSections
          };
          emitDropEvent({ from, target });
        }}
      >
        <div className="top-blocks">
          {isEditMode
            ? dropWrapper(topBlockElements, HostedPageSections.top)
            : topBlockElements}
        </div>
        <div className="iframe-container">
          <div
            className={clsx('iframe-cover', {
              active: currentDragEvent
            })}
          />
          <iframe
            className="giving-form-iframe"
            name="Giving Form iFrame"
            title="Giving Form iFrame"
            src={`${window.origin}/giving-form/${hostedPageGivingFormId}${window.location.search}`}
            frameBorder="0"
            allow="payment"
            sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
            width="100%"
            style={{
              height: iframeHeight ?? '400px',
              visibility: iframeVisible ? 'visible' : 'hidden'
            }}
            ref={iframeRef}
          />
        </div>
        <div className="side-blocks">
          {isEditMode
            ? dropWrapper(sideBlockElements, HostedPageSections.side)
            : sideBlockElements}
        </div>
        <div className="bottom-blocks">
          {isEditMode
            ? dropWrapper(bottomBlockElements, HostedPageSections.bottom)
            : bottomBlockElements}
        </div>
      </DragDropContext>
      {footerBlockElement && (
        <div
          className={clsx('hosted-page-footer', {
            disabled: !givingFormConfig.hostedPageConfig?.footer.isEnabled
          })}
        >
          {isEditMode ? (
            <HostedPageEditWrapper
              onEdit={() => emitEditEvent(footerBlockElement.key as string)}
              className={clsx({
                'edit-options-wrapper--selected':
                  highlightedEditBlock === footerBlockElement.key,
                'disable-hover': currentDragEvent
              })}
              compress
              isDraggable={false}
            >
              {footerBlockElement}
            </HostedPageEditWrapper>
          ) : (
            footerBlockElement
          )}
        </div>
      )}
      {!!givingFormConfig.hostedPageConfig?.customCss && (
        <CustomCSS css={givingFormConfig.hostedPageConfig.customCss} />
      )}
    </div>
  );

  return (
    <>
      {givingFormName && (
        <Helmet>
          <title>
            {givingFormConfig?.hostedPageConfig?.pageTitle || givingFormName}
          </title>
        </Helmet>
      )}
      <Helmet>
        <link
          rel="icon"
          href={
            givingFormConfig?.hostedPageConfig?.pageFavicon ?? '/favicon.png'
          }
        />
      </Helmet>
      {/* wraps the giving form in a new theme provider with the newly created theme */}
      <ThemeProvider theme={theme}>
        {/**
         * Here global styles maps some CSS variables to the configured colors of the giving
         * form's theme. This is so we can access the theme in our SCSS files!
         */}
        <GlobalStyles
          styles={{
            ':root': {
              '--primary-color': theme.palette.primary.main,
              '--accent-color': theme.palette.accent.main,
              '--body-font': givingFormConfig.theme.font.formBody
            }
          }}
        />

        {hostedPageElement}
      </ThemeProvider>
    </>
  );
};
