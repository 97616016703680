import { Theme, createTheme } from '@mui/material';

export const createTooltipTheme = (currTheme: Theme): Theme => {
  const {
    palette: { black }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: black.main,
            padding: '0.5rem',
            borderRadius: '0.5rem'
          },
          arrow: {
            color: black.main
          }
        },
        defaultProps: {
          arrow: true
        }
      }
    }
  });

  return theme;
};
