import { Stack } from '@mui/material';
import clsx from 'clsx';
import Text from 'components/lib/Text';
import './TableCell.scss';

type PlaceholderTableCell = {
  label: string;
  value: string;
};

export type PlaceholderTableCellProps = PlaceholderTableCell;

const PlaceholderTableCell = ({
  label,
  value
}: PlaceholderTableCellProps): JSX.Element => {
  const valueClassNames = clsx('TableCell__content', {
    'TableCell__content--placeholder': /{{.*?}}/.test(value)
  });
  return (
    <Stack>
      <Text variant="body" color="darkGray.main">
        {label}
      </Text>
      <Text
        variant="h5"
        as="pre"
        classes={{
          root: valueClassNames
        }}
      >
        {value}
      </Text>
    </Stack>
  );
};

export default PlaceholderTableCell;
