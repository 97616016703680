import Icon, { IconProps } from './Icon';
import type { IconType } from './icons';
import { ICONS } from './icons';

// default component export
export default Icon;
// constants
export { ICONS };
// type exports
export type { IconType, IconProps };
