import { Theme, createTheme } from '@mui/material';
import { initComponentThemes, initFontThemes, theTheme } from 'theme';
import { IGivingFormConfig } from 'types';

const { augmentColor } = theTheme.palette;

// use giving form config theme value to create new MUI theme
export const createGivingFormTheme = (
  currTheme: Theme,
  givingFormConfig: IGivingFormConfig
) => {
  let theme = createTheme(currTheme, {
    palette: {
      primary: augmentColor({
        color: { main: givingFormConfig.theme.primary }
      }),
      accent: augmentColor({
        color: { main: givingFormConfig.theme.accent ?? '#FFF' }
      })
    }
  });
  theme = initComponentThemes(theme);
  theme = initFontThemes(theme, givingFormConfig.theme.font);
  return theme;
};
