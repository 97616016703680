// A pared-down version of this:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
// to determine if localStorage is available and usable.
export const isLocalStorageAvailable = () => {
  try {
    const storage = window.localStorage;
    const storageKey = '_storage_test_';
    storage.setItem(storageKey, storageKey);
    storage.removeItem(storageKey);
    return true;
  } catch (e) {
    return false;
  }
};
