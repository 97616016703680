import * as React from 'react';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { GivingFormProvider } from 'components/GivingForm/GivingFormProvider';
import {
  DisabledOverlayProvider,
  ExtraFormInfoProvider,
  GlobalDisabledOverlay,
  RecaptchaCheckboxProvider
} from 'hooks';
import { theTheme } from 'theme';
import { CreditCardValidationProvider } from './CreditCardValidationContext';
import { TokenProvider } from './SpreedlyCreditCardContext';

type ProvidersProps = {
  children: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps): JSX.Element => (
  <BrowserRouter>
    <ThemeProvider theme={theTheme}>
      <GivingFormProvider>
        <ExtraFormInfoProvider>
          <TokenProvider>
            <CreditCardValidationProvider>
              <RecaptchaCheckboxProvider>
                <CssBaseline />
                <DisabledOverlayProvider>
                  <GlobalDisabledOverlay />
                  {/* Localization Provider required to handle dates in MUI date picker */}
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    {children}
                  </LocalizationProvider>
                </DisabledOverlayProvider>
              </RecaptchaCheckboxProvider>
            </CreditCardValidationProvider>
          </TokenProvider>
        </ExtraFormInfoProvider>
      </GivingFormProvider>
    </ThemeProvider>
  </BrowserRouter>
);

export default Providers;
