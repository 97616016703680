import { Route, Routes } from 'react-router-dom';
import { EmailPreview } from 'components/EmailPreview';
import GivingForm from 'components/GivingForm';
import { HostedPage } from 'components/HostedPage';

export const AppRoutes = (): JSX.Element => (
  <Routes>
    <Route path="/" element={<div>Invalid URL.</div>} />
    <Route path="/giving-form/:givingFormId" element={<GivingForm />} />
    <Route path="/hosted-page/:givingFormId" element={<HostedPage />} />
    <Route path="/email-preview" element={<EmailPreview />} />
    {
      // slug-supporting route for Hosted Pages is only active while on the hosted pages domain
      window.location.hostname.startsWith('gift.') && (
        <Route
          path="/:organizationSlug/:hostedPageSlug"
          element={<HostedPage />}
        />
      )
    }
    {
      // check if we're off-domain, and if so, use a vanity domain
      // TODO: refactor to use an env var instead of a hardcoded idonate.com suffix
      !window.location.hostname.endsWith('idonate.com') && (
        <Route
          path="/:vanitySlug"
          element={<HostedPage vanityDomain={window.location.hostname} />}
        />
      )
    }
  </Routes>
);
