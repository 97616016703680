import { useEffect, useState } from 'react';
import { Card, CardContent, Chip } from '@mui/material';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { IGivingFormSchema } from 'components/GivingForm';
import Text from 'components/lib/Text';
import { IThankYouGiftBlock, RecurringOptionsType } from 'types';
import './ThankYouGiftBlock.scss';

export const ThankYouGiftBlock = (block: IThankYouGiftBlock) => {
  const { id, blockType, isEnabled, ...giftConfig } = block;
  const [giftSelected, setGiftSelected] = useState(true);

  const { control, setValue } = useFormContext<IGivingFormSchema>();
  const giftAmount = useWatch({ control, name: 'giftAmount' });
  const recurringOption = useWatch({ control, name: 'recurringOption' });

  const isGiftEligible =
    !!giftAmount &&
    isEnabled &&
    ((recurringOption === RecurringOptionsType.Once &&
      giftConfig.oneTimeThreshold &&
      giftAmount >= giftConfig.oneTimeThreshold) ||
      (recurringOption !== RecurringOptionsType.Once &&
        giftConfig.recurringThreshold &&
        giftAmount >= giftConfig.recurringThreshold));

  useEffect(() => {
    if (giftSelected && isGiftEligible) {
      setValue('thankYouGift', giftConfig);
    } else {
      setValue('thankYouGift', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(giftConfig), giftSelected, isGiftEligible, setValue]);

  const minimumDonation =
    (recurringOption === RecurringOptionsType.Once
      ? giftConfig.oneTimeThreshold
      : giftConfig.recurringThreshold) ?? 0;

  return (
    <div className="tyg-block">
      {isEnabled && (
        <div className="tyg-prompt">
          <div className="tyg-content">
            <div className="tyg-info-container">
              <Card
                className={clsx('tyg-card', {
                  selected: giftSelected && isGiftEligible,
                  disabled: !isGiftEligible
                })}
              >
                <CardContent>
                  {giftConfig.image && (
                    <img src={giftConfig.image} alt="Gift" />
                  )}
                  <Text
                    variant="body"
                    component="div"
                    className="tyg-donation-amount"
                  >
                    <Chip
                      label={`$${minimumDonation} minimum`}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  </Text>
                  <Text variant="h4">{giftConfig.giftName}</Text>
                  <Text variant="body">{giftConfig.description}</Text>
                  <Chip
                    label={
                      giftSelected && isGiftEligible
                        ? 'Opt-out of gift'
                        : 'Claim your gift!'
                    }
                    color="primary"
                    variant="outlined"
                    size="medium"
                    clickable
                    onClick={() => setGiftSelected((prev) => !prev)}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
