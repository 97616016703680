/**
 * This component shows examples of using external svgs, and icon packages
 */
import {
  IconAlertCircle,
  IconAlien,
  IconAward,
  IconBlockquote,
  IconBold,
  IconCalendarPlus,
  IconCalendarTime,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconCircle,
  IconCircleCheck,
  IconCircleDotted,
  IconCircleSquare,
  IconCircleX,
  IconCopy,
  IconCurrencyDollar,
  IconDotsCircleHorizontal,
  IconFile,
  IconGift,
  IconHeart,
  IconInfoCircle,
  IconItalic,
  IconLink,
  IconList,
  IconListNumbers,
  IconLock,
  IconPencil,
  IconPlus,
  IconSquare,
  IconSquareCheck,
  IconSquareMinus,
  IconTable,
  IconTrash,
  IconTriangle,
  IconUnderline,
  IconX
} from '@tabler/icons';
import { ReactComponent as IconEllipse } from 'assets/icons/Intersect.svg';
import { ReactComponent as IconDodecagonCheck } from 'assets/icons/dodecagon-check.svg';
import { ReactComponent as IconDodecagon } from 'assets/icons/dodecagon.svg';
import { ReactComponent as IconIdonateLogo } from 'assets/icons/iDonateLogo.svg';

// if using TS, use enums
export const ICONS = {
  AWARD: IconAward,
  DOLLAR: IconCurrencyDollar,
  ALERT: IconAlertCircle,
  ALIEN: IconAlien,
  BLOCKQUOTE: IconBlockquote,
  BOLD: IconBold,
  CALENDAR: IconCalendarPlus,
  CALENDAR_TIME: IconCalendarTime,
  CHECK: IconCheck,
  CHEVRON_DOWN: IconChevronDown,
  CHEVRON_RIGHT: IconChevronRight,
  CIRCLE: IconCircle,
  CIRCLE_CHECK: IconCircleCheck,
  CIRCLE_SQUARE: IconCircleSquare,
  COPY: IconCopy,
  DOT_CIRCLE_HORIZONTAL: IconDotsCircleHorizontal,
  DODECAGON: IconDodecagon,
  DODECAGON_CHECK: IconDodecagonCheck,
  ELLIPSE: IconEllipse,
  FILE: IconFile,
  HEART: IconHeart,
  IDONATE_LOGO: IconIdonateLogo,
  INFO: IconInfoCircle,
  ITALIC: IconItalic,
  LINK: IconLink,
  LIST: IconList,
  LIST_NUMBERS: IconListNumbers,
  LOCK: IconLock,
  PENCIL: IconPencil,
  PLUS: IconPlus,
  SQUARE: IconSquare,
  SQUARE_CHECK: IconSquareCheck,
  SQUARE_MINUS: IconSquareMinus,
  TABLE: IconTable,
  TRASH: IconTrash,
  TRIANGLE: IconTriangle,
  UNDERLINE: IconUnderline,
  X: IconX,
  GIFT: IconGift,
  CIRCLE_DOTTED: IconCircleDotted,
  CIRCLE_X: IconCircleX
};

export type IconType = keyof typeof ICONS;
