import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitDonationDataType } from 'services/donationService';
import { CustomContentBlock } from 'components/CustomContentBlock';
import Button from 'components/lib/Button';
import { usePlaceholderData } from 'hooks/usePlaceholderData';
import { getPrimaryWithAlpha } from 'theme/utils';
import { Donation, IThankYouAccountInvitationBlock } from 'types';
import './ThankYouAccountInvitationBlock.scss';

export type IAccountInvitationBlockProps = Omit<
  IThankYouAccountInvitationBlock,
  'blockType'
> & {
  givingFormSubmission: SubmitDonationDataType | null;
  donationResponse: Donation | null;
  isEditMode: boolean;
};

export const ThankYouAccountInvitationBlock = ({
  id,
  isEditMode,
  accountInvitationUrl,
  html,
  givingFormSubmission,
  donationResponse
}: IAccountInvitationBlockProps) => {
  const { t } = useTranslation();
  const getPlaceholder = usePlaceholderData(
    givingFormSubmission,
    donationResponse,
    isEditMode
  );

  const handleGetStartedAction = () => {
    if (!isEditMode) {
      // redirect current or parent window to configured URL
      window.parent.location.href = accountInvitationUrl;
    }
  };

  const adjustedHTML = isEditMode
    ? html
    : html.replace(/{{(.*?)}}/g, (_match: string, placeholder) =>
        getPlaceholder(placeholder)
      );

  return (
    <Box className="TY-AccountInvitation" bgcolor={getPrimaryWithAlpha(0.15)}>
      <CustomContentBlock html={adjustedHTML} id={id} />
      <Button
        name="accountInvitation"
        className="TY-AccountInvitation__cta"
        variant="secondary"
        fullWidth
        onClick={handleGetStartedAction}
      >
        {t('thankYouMessageViewer.getStarted')}
      </Button>
    </Box>
  );
};
