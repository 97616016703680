export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const rgbToHex = (rgb: string) =>
  rgb.length === 7
    ? rgb.substring(1).toUpperCase()
    : rgb
        ?.match?.(/[0-9|.]+/g)
        ?.map((x, i) =>
          i === 3
            ? (255 * parseInt(x, 10)).toString(16)
            : parseInt(x, 10).toString(16)
        )
        ?.map((x) => (Number(x) < 10 ? `0${x}` : x))
        .join('')
        .toUpperCase()
        .substring(0, 6);
