import { AxiosError } from 'axios';
import axios from 'services/axiosClient';

export const getGivingFormById = async (
  givingFormId: string,
  currentVariant?: string
) => {
  try {
    const response = await axios.get(
      `/givingForms/${givingFormId}?includeTransactionStats=true`,
      {
        params: {
          includeAbTests: true,
          abTestVariant: currentVariant
        }
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      (error as AxiosError).response?.data.message ||
        'Unknown error trying to load Giving Form data'
    );
  }
};

export const getGivingFormIdFromHostedSlugs = async (
  organizationSlug: string,
  givingFormSlug: string
): Promise<string> => {
  try {
    const { data } = await axios.get(`/givingForms/getGivingFormIdFromSlugs`, {
      params: { organizationSlug, givingFormSlug }
    });

    return data.givingFormId;
  } catch (error) {
    throw new Error(
      (error as AxiosError).response?.data.message ||
        'Unknown error trying locate Giving Form ID'
    );
  }
};

export const getGivingFormIdFromVanityDomain = async (
  vanityDomain: string,
  vanitySlug: string
): Promise<string> => {
  const { data } = await axios.get(`/givingForms/getGivingFormIdFromSlugs`, {
    params: { vanityDomain, givingFormSlug: vanitySlug }
  });

  return data.givingFormId;
};
