import * as React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { resolvedEnvironment } from 'services/environment';
import Providers from 'components/Providers';
import { AppRoutes } from 'routes/AppRoutes';
import './i18n.js';
import reportWebVitals from './reportWebVitals';

const loadReactRender = () => {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback="loading">
        <Providers>
          <AppRoutes />
        </Providers>
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

window.onload = async () => {
  // add sentry logging
  Sentry.init({
    dsn: resolvedEnvironment.sentry.dsn,
    integrations: [new BrowserTracing()],
    environment: resolvedEnvironment.sentry.environment,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });

  // load react
  loadReactRender();
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};
