import Icon, { ICONS, IconType } from 'components/lib/Icon';
import Text from 'components/lib/Text';
import './Impact.scss';

type ImpactProps = {
  impactStatement: string;
  icon?: IconType; // TODO kstick: this may need to be updated to its own enum when icons are implemented
};

export const Impact = ({ impactStatement, icon }: ImpactProps) => (
  <div className="impact">
    <div className="impact-background" />
    <div className="impact-content">
      <Icon
        className="left-ellipse"
        icon={ICONS.ELLIPSE}
        viewBox="0 0 120 120"
      />
      {icon && <Icon className="impact-icon" icon={ICONS[icon]} />}
      <Text className="impact-statement" variant="h2">
        {impactStatement}
      </Text>
      <Icon
        className="right-ellipse"
        icon={ICONS.ELLIPSE}
        viewBox="0 0 120 120"
      />
    </div>
  </div>
);
