import { HostedPageBlockTypes } from './HostedPage';

export enum GoalMeterType {
  CIRCLE = 'CIRCLE',
  PROGRESS = 'PROGRESS',
  SPEEDOMETER = 'SPEEDOMETER'
}

export enum GoalSource {
  CAMPAIGN = 'CAMPAIGN',
  CHANNEL = 'CHANNEL'
}

export interface GoalMeterConfig {
  id: 'goalMeter';
  blockType: HostedPageBlockTypes.GoalMeterBlock;
  isEnabled: boolean;
  goalMeterType: GoalMeterType;
  goalSource: GoalSource;
  goalAmount: number;
  sectionTitle?: string;
  goalEndDate?: string;
  showAmountRaised: boolean;
  showGoalAmount: boolean;
  showDaysLeft: boolean;
}
