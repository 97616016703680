import { useCallback, useEffect, useState } from 'react';
import { util } from '@idonatedev/idonate-sdk';
import { UseFormReset, UseFormSetValue } from 'react-hook-form';
import {
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink
} from 'react-plaid-link';
import { createLinkToken, exchangePlaidTokens } from 'services/plaidService';
import { IGivingFormSchema } from 'components/GivingForm';
import { PlaidResponse } from 'types';
import { useGivingFormData } from './useGivingFormData';
import { useUserInfo } from './useUserInfo';

type PlaidProps = {
  resetForm: UseFormReset<IGivingFormSchema>;
  setPlaidError: (val: PlaidLinkError) => void;
  setPlaidIsComplete: (val: boolean) => void;
  setValue: UseFormSetValue<IGivingFormSchema>;
};

export const usePlaid = ({
  resetForm,
  setPlaidError,
  setPlaidIsComplete,
  setValue
}: PlaidProps) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const { getVisitorId } = useUserInfo();
  const visitorId = getVisitorId();
  const { showPlaidOnSubmit, setShowPlaidOnSubmit } = useGivingFormData();

  useEffect(() => {
    if (showPlaidOnSubmit && !linkToken) {
      createLinkToken(visitorId).then((token) => {
        setLinkToken(token);
      });
    }
  }, [visitorId, showPlaidOnSubmit, linkToken]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken: string) => {
    exchangePlaidTokens(publicToken)
      .then((plaidResponse: PlaidResponse) => {
        const billingName = util.splitName(
          plaidResponse?.name || 'Account Owner'
        );
        //  append data from plaid to form data
        setValue('billing.firstName', billingName.firstName);
        setValue('billing.lastName', billingName.lastName);
        setValue('billing.email', plaidResponse?.email || '');
        setValue('billing.address1', plaidResponse?.address?.street || '');
        setValue('billing.city', plaidResponse?.address?.city || '');
        setValue('billing.state', plaidResponse?.address?.state || '');
        setValue('billing.postalCode', plaidResponse?.address?.zipCode || '');
        setValue('ach.routingNumber', plaidResponse?.routingNumber);
        setValue('ach.accountNumber', plaidResponse?.accountNumber);
        setValue('ach.confirmAccountNumber', plaidResponse?.accountNumber);
      })
      .then(() => {
        setPlaidIsComplete(true);
      });
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null) => {
      // Link tokens expire after ~30 minutes; this likely won't happen, but if it does we can just fetch a new one
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        createLinkToken(visitorId).then((token) => {
          setLinkToken(token);
        });
      } else if (
        error != null &&
        error.error_code === 'INSUFFICIENT_CREDENTIALS'
      ) {
        // if donor does not provide their credentials, exit and reset the form to force them to choose a different payment method
        resetForm();
        setShowPlaidOnSubmit(false);
        setPlaidError(error);
      }
    },
    []
  );
  const plaidOptions: PlaidLinkOptions = {
    onSuccess,
    onExit,
    token: linkToken
  };

  const {
    open: openPlaid,
    exit: exitPlaid,
    ready: isPlaidReady
  } = usePlaidLink(plaidOptions);

  const initPlaid = () => {
    if (isPlaidReady) {
      openPlaid();
    }
  };

  return { initPlaid };
};
