import { useContext } from 'react';
import { CreditCardValidationContext } from 'components/Providers/CreditCardValidationContext';

export const useCreditCardValidation = () => {
  const context = useContext(CreditCardValidationContext);
  if (!context) {
    throw new Error(
      'You must use the CreditCardValidationContext within the CreditCardValidationContextProvider'
    );
  }
  return context;
};
