import { AxiosError } from 'axios';
import axios from 'services/axiosClient';

export const getOrganizationData = async (givingFormId: string) => {
  try {
    const response = await axios.get(
      `/organizations/settings-for-donations/${givingFormId}`
    );

    return response.data;
  } catch (error) {
    throw new Error(
      (error as AxiosError).response?.data.message ||
        'Unknown error trying to load donor fee data'
    );
  }
};
