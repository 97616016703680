import { LinearProgress, LinearProgressProps } from '@mui/material';
import './ProgressBar.scss';

type ProgressBarProps = LinearProgressProps;

export const ProgressBar = (props: ProgressBarProps) => (
  <div className="ProgressBar">
    <LinearProgress {...props} />
  </div>
);
