import * as yup from 'yup';
import { TributeBlockType } from 'types';

export const TributeSchema = ({
  isTitleEnabled,
  isEmailEnabled,
  isAddressEnabled,
  isDonorNameEnabled
}: Pick<
  TributeBlockType,
  | 'isTitleEnabled'
  | 'isEmailEnabled'
  | 'isAddressEnabled'
  | 'isDonorNameEnabled'
>) => {
  const schemaShape = {
    // no tribute values should be set to required via yup because this
    // will prevent the form from being submitted if there is a tribute
    // block and the donor chose not to fill it out
    // donor will be required to fill out some fields to progress through
    // the modal, but validation will not be triggered on form submit
    // prefixing all fields with 'tribute' to prevent confusion with billing info fields
    tributePurpose: yup.string(),
    ...(isTitleEnabled ? { tributePrefix: yup.string() } : {}),
    tributeFirstName: yup.string(),
    tributeLastName: yup.string(),
    ...(isEmailEnabled
      ? {
          tributeEmail: yup.string().email('Email must be valid').nullable()
        }
      : {}),
    ...(isAddressEnabled
      ? {
          tributeCountry: yup.string(),
          tributeAddress1: yup.string(),
          tributAddress2: yup.string(),
          tributeCity: yup.string(),
          tributeState: yup.string(),
          tributePostalCode: yup.string()
        }
      : {}),
    ...(isDonorNameEnabled ? { tributeFromName: yup.string() } : {})
  };

  const tributeSchema = yup.object(schemaShape);

  return tributeSchema;
};
