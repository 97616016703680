import { useContext } from 'react';
import { GivingFormContext } from 'components/GivingForm/GivingFormProvider';

/**
 * Hook that consumes and returns the Giving Form Context and related data. Use this in your components to access/set form config.
 */
export const useGivingFormData = () => {
  const context = useContext(GivingFormContext);
  if (!context) {
    throw new Error('Something went wrong. No context found for Giving Form');
  }
  return context;
};
