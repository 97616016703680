import KeenTracking from 'keen-tracking';
import { resolvedEnvironment } from 'services/environment';
import { FormEvent } from './useEventTracker';

export const useKeenActivityTracking = () => {
  const trackActivity = async (event: FormEvent) => {
    try {
      const KeenClient = new KeenTracking({
        projectId: resolvedEnvironment.keenProjectId,
        writeKey: resolvedEnvironment.keenWriteKey
      });

      const common = {
        session_id: event.sessionId,
        visitor_id: event.visitorId,
        embed_id: event.givingFormId,
        embed_type: 'giving-form',
        organization_id: event.organizationId,
        campaign_id: event.campaignId,
        embed_url: event.url,
        reference_code: event.referenceCode,
        ...(event.givingFormVariant
          ? {
              giving_form_variant:
                event.givingFormVariant === 'VariantA' ? 0 : 1
            }
          : {}),
        giving_form_version: event.givingFormVersion
      };

      KeenClient.recordEvent('PageActivities', {
        event: event.type,
        common,
        details: event.details
      });
    } catch (err) {
      // catch
    }
  };

  return { trackActivity };
};
