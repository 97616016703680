import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import Icon, { ICONS } from '../Icon';

/**
 * Base level GMS Checkbox
 * More about checkboxes here - https://mui.com/components/checkboxes/
 * @param props - Any valid MUI Checkbox props are forwarded to the child Checkbox component
 * @returns A GMS themed Checkbox component
 */
const Checkbox = (props: CheckboxProps): JSX.Element => (
  <MuiCheckbox
    icon={<Icon icon={ICONS.SQUARE} />}
    checkedIcon={<Icon icon={ICONS.SQUARE_CHECK} />}
    indeterminateIcon={<Icon icon={ICONS.SQUARE_MINUS} />}
    {...props}
  />
);

export default Checkbox;
