import { Dispatch, SetStateAction } from 'react';
import { Box, Grid } from '@mui/material';
import { CreditCardTypes, IPaymentGateway } from 'types';
import { CardConnectCreditCard, SpreedlyCreditCard } from '../PaymentProviders';

type CCPaymentInfoProps = {
  paymentGateway?: IPaymentGateway;
  currentCardType: CreditCardTypes[];
  setCurrentCardType: Dispatch<SetStateAction<CreditCardTypes[]>>;
};

const CCPaymentInfo = ({
  paymentGateway,
  currentCardType,
  setCurrentCardType
}: CCPaymentInfoProps) => (
  <Box className="GF-CCPaymentInfo">
    <Grid container item xs={12} columnSpacing="0.75rem" rowSpacing="0.5rem">
      {(!paymentGateway || paymentGateway?.backendName === 'spreedly') && (
        <SpreedlyCreditCard
          currentCardType={currentCardType}
          setCurrentCardType={setCurrentCardType}
        />
      )}
      {paymentGateway?.backendName === 'card_connect' && (
        <CardConnectCreditCard />
      )}
    </Grid>
  </Box>
);

export default CCPaymentInfo;
