import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { IGivingFormSchema } from 'components/GivingForm';
import { useGivingFormData } from 'hooks';
import { IDonateQueryParam } from 'types/QueryParams';

/**
 * This custom hook extracts query params from the URL, maps them to
 * react-hook-form's giving form schema, and sets the default values within
 * form state using react-hook-form's `methods.reset()`
 *
 * Note: the default values for `giftAmount`, `paymentOption`, and `designation` are
 * handled within their respective block components
 */
export const useQueryParamMapper = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<IGivingFormSchema, any>
) => {
  const { isLoaded } = useGivingFormData();
  const location = useLocation();

  useEffect(() => {
    if (isLoaded) {
      const queryStrings = new window.URLSearchParams(location.search);

      /* eslint-disable camelcase */
      const {
        first_name,
        middle_initial,
        last_name,
        company_name,
        address1,
        address2,
        city,
        state,
        zip_code,
        country,
        email,
        phone,
        gift_amount,
        gift_arrays,
        payment_type,
        designation,
        designations,
        anonymous,
        corporate_match_employer,
        cash_default,
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
        utm_term,
        ...customFields
      } = Object.fromEntries(queryStrings) as {
        [key in IDonateQueryParam]: string;
      };

      const mappedValues: Record<string, unknown> = {
        billing: {
          firstName: first_name,
          middleName: middle_initial,
          lastName: last_name,
          companyName: company_name,
          address1,
          address2,
          city,
          state,
          postalCode: zip_code,
          country: country || 'US',
          email,
          phoneNumber: phone
        },
        anonymousGift:
          // eslint-disable-next-line no-nested-ternary
          anonymous === 'true'
            ? true
            : anonymous === 'false'
            ? false
            : undefined,
        corporateMatchingCompanyId: corporate_match_employer,
        // TODO: BKC: p2p_fundraiser_id - support this when the feature is added

        // parses and spreads the customFields from the queryParams into the form state
        ...Object.entries(customFields).reduce(
          (acc, [key, value]) => ({
            ...acc,
            // checking if key is a custom field
            [key.endsWith('*') ? `customField-${key.slice(0, -1)}` : '']:
              key.endsWith('*') ? value : undefined
          }),
          {}
        )
      };
      methods.reset({
        ...methods.getValues(),
        ...mappedValues
      });
    }
  }, [location, methods, isLoaded]);
};
