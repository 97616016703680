import { TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Icon, { ICONS } from '../Icon';
import TextField from '../TextField';
import './ComboBox.scss';

type ComboBoxProps<T> = Omit<
  AutocompleteProps<T, false, false, true>,
  'renderInput'
> & {
  name: string;
  placeholder?: string | JSX.Element;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
};
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ComboBox = <T extends unknown>({
  options,
  name,
  placeholder = 'Select',
  error,
  helperText,
  ...rest
}: ComboBoxProps<T>): JSX.Element => (
  <Autocomplete
    id="GMS-ComboBox"
    options={options}
    {...rest}
    popupIcon={<Icon icon={ICONS.CHEVRON_DOWN} />}
    renderInput={(params) => (
      <TextField
        {...params}
        name={name}
        helperText={helperText}
        error={error}
        placeholder={placeholder}
      />
    )}
  />
);

export default ComboBox;
