import { Box } from '@mui/material';
import parse from 'html-react-parser';
import './HostedPageFooter.scss';

type HostedPageFooterProps = {
  html: string;
};

export const HostedPageFooter = ({ html }: HostedPageFooterProps) => (
  <Box className="HP-Footer">{parse(html)}</Box>
);
