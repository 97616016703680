import { FormHelperText, Stack } from '@mui/material';
import clsx from 'clsx';
import Button from 'components/lib/Button';
import Text from 'components/lib/Text';
import BlockBaseType from 'types/givingForm/BlockBaseType';
import './PageBreak.scss';

export interface PageBreakProps extends Partial<BlockBaseType> {
  isEditMode: boolean; // Used to indicate if we should render horizontal bar and disable button actions
  onNext?(): void;
  onBack?(): void;
  submissionSlot?: boolean; // Used to indicate this is rendering next to the submission button
  hasValidationErrors?: boolean;
}

export const PageBreak = ({
  isEditMode,
  onBack,
  onNext,
  submissionSlot = false,
  hasValidationErrors = false
}: PageBreakProps) => (
  <Stack className={clsx('page-break-block', { 'edit-mode': isEditMode })}>
    <div className="page-break-actions">
      {onBack && (
        <Button
          className="back-button"
          variant="secondary"
          onClick={() => onBack?.()}
          name="back"
        >
          Back
        </Button>
      )}
      {onNext && (
        <Button className="next-button" onClick={() => onNext?.()} name="next">
          Next
        </Button>
      )}
    </div>
    {hasValidationErrors && (
      <FormHelperText className="validation-message" error>
        Please fill out all required fields
      </FormHelperText>
    )}
    {isEditMode && !submissionSlot && (
      <Text variant="caption" className="page-break-separator">
        PAGE BREAK
      </Text>
    )}
  </Stack>
);
