import { Theme, createTheme } from '@mui/material';

export const createCheckboxTheme = (currTheme: Theme): Theme => {
  const {
    palette: { primary, common, lightestGray }
  } = currTheme;
  const theme = createTheme(currTheme, {
    components: {
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true
        },
        styleOverrides: {
          root: {
            width: '2.25rem',
            height: '2.25rem',
            color: primary.main,

            // apply new fill for unchecked box on hover
            '&:hover .MuiSvgIcon-root': {
              fill: lightestGray.main
            },

            // no state, make the checkbox square the primary color
            '& .MuiSvgIcon-root rect': {
              stroke: primary.main
            },

            // when focused via tab navigation...
            // removes the original focus circle
            // and opts to put the focus ring around the actual checkbox
            '&.Mui-focusVisible': {
              outline: 'none',

              // selecting the checkbox square
              '& .MuiSvgIcon-root rect': {
                outline: `solid 2px ${primary.main}`,
                borderRadius: '0.1875rem'
              }
            },

            '&.Mui-checked': {
              // when checked, select the checkbox svg
              '& .MuiSvgIcon-root': {
                fill: primary.main,

                // change the color of the checkmark or line itself to white
                '& path:last-of-type, & line': {
                  stroke: common.white
                }
              },
              // apply new fill/stroke for checked box on hover
              '&:hover .MuiSvgIcon-root': {
                fill: primary.main,
                '& rect': {
                  stroke: primary.main
                }
              }
            },

            '&.Mui-disabled': {
              opacity: 0.3
            }
          }
        }
      }
    }
  });

  return theme;
};
