import { Theme, createTheme } from '@mui/material';

export const createMenuTheme = (currTheme: Theme): Theme => {
  const {
    palette: { lightestGray }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      MuiMenu: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              boxShadow: '0px 2px 8px rgba(89, 102, 132, 0.18)',
              boxSizing: 'border-box',
              borderRadius: '8px',
              border: `1px solid ${lightestGray.main}`
            },
            '& .MuiMenuItem-root': {
              margin: '0.5rem',
              ':hover': {
                backgroundColor: lightestGray.main,
                borderRadius: '0.5rem'
              },
              '&.Mui-selected': {
                backgroundColor: lightestGray.main,
                borderRadius: '0.5rem'
              },
              '&.Mui-focusVisible': {
                // Built in accessibility highlight for keyboard selection
                borderRadius: '0.5rem',
                backgroundColor: lightestGray.main
              }
            }
          }
        }
      }
    }
  });

  return theme;
};
