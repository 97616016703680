/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import EditWrapper from 'components/EditWrapper';
import { renderBlockDropzone } from 'components/EditWrapper/EditWrapperUtils';
import useEventHub, {
  EditorEventTypes,
  EventHubEvent
} from 'components/EventHub';
import {
  generateBlockHtmlMap,
  generateHeaderHtml
} from 'components/lib/BackendLib/EmailHtmlBuilder';
import { EmailBlockBaseType, EmailConfig } from 'types/email';
import './EmailPreview.scss';

type OrgInfo = {
  orgName: string;
  orgLogoUrl: string;
};

export const EmailPreview = (): JSX.Element => {
  const [config, setConfig] = useState<EmailConfig>();
  const [highlightedEditBlock, setHighlightedEditBlock] = useState<string>('');
  const [isDragging, setIsDragging] = useState<string | null>(null);
  const [orgInfo, setOrgInfo] = useState<OrgInfo>({
    orgName: '',
    orgLogoUrl: ''
  });

  const { eventHub } = useEventHub((initializeEventHub: any) => {
    if (initializeEventHub) {
      initializeEventHub.then((eventHubRef: any) => {
        eventHubRef.subscribe(
          EditorEventTypes.ConfigurationUpdate,
          ({ payload }: EventHubEvent) => {
            setConfig(payload as EmailConfig);
            return eventHubRef;
          }
        );
        eventHubRef.subscribe(
          EditorEventTypes.EditBlock,
          ({ payload }: EventHubEvent) => {
            setHighlightedEditBlock(payload.id);
            return eventHubRef;
          }
        );
        eventHubRef.subscribe(EditorEventTypes.DeselectBlock, () => {
          setHighlightedEditBlock('');
          return eventHubRef;
        });
        eventHubRef.subscribe(
          EditorEventTypes.OrgInfoUpdate,
          ({ payload }: EventHubEvent) => {
            setOrgInfo(payload.orgInfo);
            return eventHubRef;
          }
        );

        const monitorBodyHeight = () => {
          const targetNode = document.body;
          let lastHeight = targetNode.offsetHeight;
          if (window.ResizeObserver) {
            const observer = new ResizeObserver((entries: any) => {
              const height = entries[0].target.offsetHeight;
              if (height && height !== lastHeight) {
                eventHubRef.emit(
                  EditorEventTypes.BodyHeightUpdate,
                  `${height}px`
                );
                lastHeight = height;
              }
            });
            observer.observe(targetNode);
          }

          if (lastHeight) {
            eventHubRef.emit(
              EditorEventTypes.BodyHeightUpdate,
              `${lastHeight}px`
            );
          }
        };

        monitorBodyHeight();
        eventHubRef.emit(EditorEventTypes.PageLoaded);
      });
    }
  });

  useEffect(() => {
    // Not `emit` since this message is sent prior to EventHub initialization
    window.parent.postMessage({ name: EditorEventTypes.PageReady }, '*');
  }, []);

  if (!config) {
    return <div>Waiting for data...</div>;
  }

  const emitEditEvent = (id: string) => {
    setHighlightedEditBlock(id);
    eventHub.emit(EditorEventTypes.EditBlock, { id });
  };

  const emitDropEvent = (blockOrder: string[]) => {
    eventHub.emit(EditorEventTypes.BlockOrderUpdate, { blockOrder });
  };

  const editWrapper = (
    blocks: JSX.Element[],
    {
      showDropzones = true,
      compressWrapper = false
    }: { showDropzones?: boolean; compressWrapper?: boolean } = {}
  ) => {
    if (isDragging && showDropzones) {
      return [
        ...blocks.flatMap((block, idx) => [
          ...renderBlockDropzone(
            blocks,
            idx,
            block.key as string,
            isDragging,
            emitDropEvent
          ),
          <EditWrapper
            onEdit={() => emitEditEvent(block.key as string)}
            isEditable
            isDraggable={showDropzones}
            setIsDragging={setIsDragging}
            key={block.key}
            dragKey={block.key as string}
            className={
              (block.key as string) === highlightedEditBlock
                ? 'edit-options-wrapper--selected'
                : ''
            }
          >
            {block}
          </EditWrapper>
        ]),
        ...renderBlockDropzone(
          blocks,
          blocks.length,
          'last_dropzone',
          isDragging,
          emitDropEvent
        )
      ];
    }
    return blocks.map((block) => {
      const { blockType } = block.props;
      if (
        blockType === 'DesignationsBlock' &&
        block.props.designations.length <= 1
      ) {
        return null;
      }
      return (
        <EditWrapper
          onEdit={() => emitEditEvent(block.key as string)}
          isEditable
          setIsDragging={setIsDragging}
          isDraggable={showDropzones}
          key={block.key}
          dragKey={block.key as string}
          className={`${
            (block.key as string) === highlightedEditBlock
              ? 'edit-options-wrapper--selected'
              : ''
          } ${compressWrapper ? 'compress-wrapper' : ''}`}
        >
          {block}
        </EditWrapper>
      );
    });
  };

  const blocks: JSX.Element[] = config?.blocks.map(
    (block: EmailBlockBaseType) => {
      const { id, blockType } = block;
      const html = generateBlockHtmlMap[blockType](
        block,
        config.theme,
        orgInfo
      );
      // eslint-disable-next-line react/no-danger
      return <div key={id} dangerouslySetInnerHTML={{ __html: html }} />;
    }
  );

  return (
    /**
     * Currently we're using `bgcolor` as its the only spot where we're
     * having to style background with a configured color. If we do this more
     * in the future, we can abstract it into a css/scss variable and apply
     * it through stylesheets
     */
    <Stack
      className={`email-container ${isDragging ? 'drag-active' : ''}`}
      bgcolor={config.theme.background}
    >
      {config?.header &&
        editWrapper(
          [
            <div
              key={config.header.id}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: generateHeaderHtml(config.header)
              }}
            />
          ],
          { showDropzones: false }
        )}
      {editWrapper(blocks)}
    </Stack>
  );
};
