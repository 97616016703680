import { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import pluralize from 'pluralize';
import {
  Controller,
  useController,
  useForm,
  useFormContext
} from 'react-hook-form';
import { IGivingFormSchema } from 'components/GivingForm';
import { Impact } from 'components/Impact';
import Button from 'components/lib/Button';
import FormHelperText from 'components/lib/FormHelperText';
import { IconType } from 'components/lib/Icon';
import Text from 'components/lib/Text';
import { NumberTextField } from 'components/lib/TextField';
import { useExtraFormInfo, useQueryParam } from 'hooks';
import useWindowSize from 'hooks/useWindowSize';
import { IGiftOptionsBlock } from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import { chunk } from 'utils';
import './GiftOptions.scss';

const formatCurrency = (value: number) => {
  if (Number.isInteger(value)) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }).format(value);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(value);
};

type GiftOptionsProps = Omit<IGiftOptionsBlock, 'blockType'> & {
  isEditMode?: boolean;
  unmount?: () => void;
};

const MAX_DONATION = 250000;

/**
 * Component to handle the mapping of gift options block types to UI.
 */
const GiftOptions = ({
  giftOptions,
  defaultOption,
  allowRecurringGiftOptions,
  recurringGiftOptions,
  defaultRecurringOption,
  subtext,
  enableCustomAmount,
  customImpactStatement,
  customAmountTag,
  min,
  isEditMode,
  unmount
}: GiftOptionsProps): JSX.Element => {
  const { width } = useWindowSize();
  const {
    control,
    setValue,
    clearErrors,
    setError,
    trigger,
    resetField,
    watch
  } = useFormContext<IGivingFormSchema>();
  const { register } = useForm();
  const { extraContext, setIsCustomGiftSelected } = useExtraFormInfo();

  const selectedFrequency = watch('recurringOption');
  const isRecurringFrequency = useMemo(
    () => selectedFrequency && selectedFrequency !== 'Once',
    [selectedFrequency]
  );

  // state for toggling recurring/non-recurring
  const [oneTimeOption, setOneTimeOption] = useState<
    number | string | null | undefined
  >();
  const [recurringOption, setRecurringOption] = useState<
    number | string | null | undefined
  >();

  const {
    field: { onChange, value: fieldValue },
    fieldState: { error }
  } = useController({
    name: 'giftAmount',
    control,
    defaultValue: isRecurringFrequency
      ? defaultRecurringOption || defaultOption || undefined
      : defaultOption || undefined
  });
  // Watch the designations and giftAmount to update the selected option/custom amount in the scenario where the
  // amount of designations goes back down from many to one
  const designations = watch('designation');
  let giftAmountValue = watch('giftAmount');
  giftAmountValue =
    designations?.reduce((acc, curr) => acc + (curr?.amount ?? 0), 0) ||
    giftAmountValue;

  const giftAmountQueryParam = useQueryParam(IDonateQueryParam.GiftAmount);
  // cash_default is the legacy version of gift_amount and takes priority
  const cashDefaultQueryParam = useQueryParam(IDonateQueryParam.CashDefault);
  const giftArraysQueryParam = useQueryParam(IDonateQueryParam.GiftArrays);
  // formats the array of params, removes invalid NaN amounts, amounts < 5 and amounts > 250000
  const formattedGiftArraysQueryParams = giftArraysQueryParam
    ?.replace(/[[\]"]+/g, '') // remove brackets and quotes from encoded value
    .split(',')
    .map((value: string) => ({ amount: parseInt(value, 10), tag: '' }))
    .filter((value) => value.amount >= 5 && value.amount <= 250000);

  const giftOptionsForFrequency = useMemo(() => {
    if (
      isRecurringFrequency &&
      allowRecurringGiftOptions &&
      recurringGiftOptions
    ) {
      return recurringGiftOptions;
    }

    return giftOptions;
  }, [
    giftOptions,
    allowRecurringGiftOptions,
    recurringGiftOptions,
    isRecurringFrequency
  ]);

  const [selected, setSelected] = useState<number | string | null>(() => {
    // if we have a value set from QP use that
    if (giftAmountValue) {
      return giftAmountValue;
    }

    if (
      allowRecurringGiftOptions &&
      isRecurringFrequency &&
      defaultRecurringOption
    ) {
      return defaultRecurringOption;
    }

    return defaultOption || null;
  });

  // when donor selects a gift option, only the amount is saved to state in 'selected';
  // separately saving the entire gift option object forthe selected amount to determine
  // if impact statement should be rendered and pass props to it
  const [currentOption, setCurrentOption] = useState<{
    amount: number | string;
    tag?: string;
    impactStatement?: string;
    icon?: IconType;
  } | null>(null);

  const [customAmount, setCustomAmount] = useState<number | undefined>(
    undefined
  );

  const [isEditingCustom, setIsEditingCustom] = useState<boolean>(false);

  const options: Array<{ amount: number | string; tag?: string }> = [
    ...(formattedGiftArraysQueryParams?.length
      ? formattedGiftArraysQueryParams // if gift_array param exists then use those options
      : giftOptionsForFrequency),
    ...(enableCustomAmount ? [{ amount: 'custom', tag: customAmountTag }] : [])
  ];

  // when form is below mobile breakpoint (600px), we set the payment options
  // to be rows of 2 so that the tag text does not wrap
  const chunkedOptions = chunk<{
    amount: number | string;
    tag?: string;
    impactStatement?: string;
    icon?: IconType;
  }>(options, width < 600 ? 2 : 3);

  if (min && min > MAX_DONATION) {
    throw new Error('Min cannot be larger than max');
  }

  /**
   * onMount, we want to initialize the 2 state values if we have them
   */
  useEffect(() => {
    if (defaultOption) {
      setOneTimeOption(defaultOption);
    }

    if (defaultRecurringOption) {
      setRecurringOption(defaultRecurringOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Watch changes to isRecurring. If we swap arrays, we need to swap selected values
   */
  useEffect(() => {
    // we only need to swap if we know we have 2 arrays
    if (recurringGiftOptions && recurringGiftOptions.length) {
      if (isRecurringFrequency && recurringOption) {
        // we've toggled TO a recurring frequency, update selected
        setSelected(recurringOption);
      } else if (oneTimeOption) {
        // we've toggled back to a one-time option. set from there
        setSelected(oneTimeOption);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecurringFrequency]);

  /**
   * This monitors `extraContext`, which currently holds the flag for if we're using
   * the customAmount field. This enables min amount validation.
   */
  useEffect(() => {
    if (selected && !giftAmountQueryParam) {
      trigger('giftAmount');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraContext]);

  /**
   * Conditionally toggle the "are we using customAmount" based on what's selected.
   * We don't force validation yet because we want to wait for the changes to propagate
   * into the extraContext object.
   */
  useEffect(() => {
    if (selected) {
      if (selected === 'custom') {
        setIsCustomGiftSelected(true);
      } else {
        setIsCustomGiftSelected(false);
      }

      if (
        isRecurringFrequency &&
        recurringGiftOptions &&
        recurringGiftOptions.length
      ) {
        setRecurringOption(selected);
      } else {
        setOneTimeOption(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // Update the impact statement when in edit mode
  useEffect(() => {
    if (selected === defaultOption) {
      const defaultOptionObject = giftOptions.find(
        (option) => option.amount === defaultOption
      );
      setCurrentOption(defaultOptionObject || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption]);

  useEffect(() => {
    // ensure new impact statement is rendered in the viewer if updated in element library
    const currentOptionObject = giftOptionsForFrequency.find(
      (option) => option.amount === selected
    );
    setCurrentOption(currentOptionObject || null);
  }, [giftOptionsForFrequency, selected]);

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      const designationLength = value?.designation?.length ?? 0;
      if (designationLength > 1) {
        unmount?.();
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This last useEffect checks URL params to see if a gift_amount passed in.
   * Should override defaultOption from the giving form config.
   */
  useEffect(
    () => {
      if (giftAmountQueryParam || cashDefaultQueryParam) {
        // cash default takes priority
        const giftAmount = !cashDefaultQueryParam
          ? Number(giftAmountQueryParam)
          : Number(cashDefaultQueryParam);
        setCurrentOption(null); // remove impact statement

        if (giftOptionsForFrequency.find((opt) => opt.amount === giftAmount)) {
          // select a gift option if it exists
          setSelected(giftAmount);
        } else if (enableCustomAmount) {
          resetField('giftAmount', {
            defaultValue: giftAmount
          });
          setSelected('custom');
          setCustomAmount(giftAmount);
        }
      } else if (
        !isRecurringFrequency &&
        defaultOption &&
        (isEditMode || !oneTimeOption)
      ) {
        setValue('giftAmount', defaultOption);
        setSelected(defaultOption);
        onChange(defaultOption);
        const currentOptionObject = giftOptions.find(
          (option) => option.amount === defaultOption
        );
        setCurrentOption(currentOptionObject || null);
      } else if (
        isRecurringFrequency &&
        defaultRecurringOption &&
        (isEditMode || !recurringOption)
      ) {
        setValue('giftAmount', defaultRecurringOption);
        setSelected(defaultRecurringOption);
        onChange(defaultRecurringOption);
        const currentOptionObject = recurringGiftOptions?.find(
          (option) => option.amount === defaultRecurringOption
        );
        setCurrentOption(currentOptionObject || null);
      }
    },
    // TODO: remove below and add dependencies to an array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cashDefaultQueryParam,
      enableCustomAmount,
      giftAmountQueryParam,
      giftOptions,
      onChange,
      resetField,
      setValue,
      trigger,
      defaultOption,
      recurringGiftOptions,
      defaultRecurringOption,
      isRecurringFrequency,
      giftOptionsForFrequency
    ]
  );

  useEffect(() => {
    const giftAmount = selected === 'custom' ? customAmount : selected;
    if (giftAmount && giftAmount !== selected) {
      setValue('giftAmount', giftAmount as number);
      onChange(giftAmount);
      trigger('giftAmount');
    }
  }, [customAmount, onChange, selected, setValue, trigger]);

  const showCustomFieldButtonStyles =
    !isEditingCustom && // If user is not currently editing...
    customAmount !== undefined && // The input field has a value...
    !error;
  // errors.length <= 0; // And there are no active errors on the field...
  // ...Then we want to display the text field's text the same as the buttons

  const hasError = !!error && error.type !== 'required';

  const calculateCustomImpactStatement = () => {
    // Need to determine how many "labels" we can afford. `label` and `equationAmount` are required
    // and by now we know we have a config so just need to default these values to
    // something sensible that won't break.
    const calculatedAmount =
      (customAmount || 0) / (customImpactStatement?.equationAmount || 1);
    return `$${customAmount} = ${Number(
      calculatedAmount.toFixed(2)
    )} ${pluralize(customImpactStatement?.label || '', calculatedAmount)}`;
  };

  const mappedOptions = chunkedOptions.map((buttonChunk) => {
    // after:
    const key = buttonChunk.reduce((acc, curr) => `${acc}${curr.amount}`, '');
    return (
      <Grid
        key={key}
        item
        container
        xs={12}
        rowSpacing={0.75}
        columnSpacing={0.75}
        alignItems="flex-end"
      >
        {buttonChunk.map((option) => {
          const columns = 12 / buttonChunk.length;
          if (option.amount === 'custom') {
            const baseClass = 'GF-GiftOptions__custom-amount';
            const className = clsx(baseClass, {
              [`${baseClass}--selected`]: selected === 'custom',
              [`${baseClass}--valid`]: showCustomFieldButtonStyles,
              [`${baseClass}--error`]: hasError
            });

            return (
              <Grid key={option.amount} item xs={columns}>
                {!!customAmountTag && (
                  <Text className="GF-GiftOptions__tag" variant="h5">
                    {option.tag}
                  </Text>
                )}
                <Controller
                  name="giftAmount"
                  control={control}
                  defaultValue={0}
                  render={({
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    field: { ref, onBlur, ...field }
                  }) => (
                    <NumberTextField
                      {...field}
                      key={option.amount}
                      id={baseClass}
                      className={className}
                      hiddenLabel
                      error={!!error && error.type !== 'required'}
                      helperText={
                        (error?.type !== 'required' && error?.message) ?? null
                      }
                      placeholder="$ Other"
                      fullWidth
                      isNumericString
                      thousandSeparator
                      prefix="$"
                      value={customAmount}
                      decimalScale={2}
                      fixedDecimalScale={!Number.isInteger(customAmount)}
                      onChange={(value: string) => {
                        // only process if we don't find this value in the current array
                        const found = giftOptionsForFrequency.find(
                          (o) => o.amount === +value
                        );
                        if (!found) {
                          setCurrentOption(null);
                        }
                        // always set local state
                        if (Number(value)) setCustomAmount(Number(value));
                        else setCustomAmount(undefined);
                      }}
                      onBlur={() => {
                        setCurrentOption(null);
                        const value = Number(customAmount);
                        if (value && value !== 0) {
                          setSelected('custom');
                          setValue('giftAmount', value);
                          trigger('giftAmount');
                        } else {
                          setSelected(null);
                          onChange(undefined);
                          clearErrors();
                          trigger('giftAmount');
                        }
                        onBlur();
                        if (!fieldValue && !selected) {
                          setError('giftAmount', {
                            message:
                              'Please select a donation amount or specify a custom amount',
                            type: 'required'
                          });
                        }
                        setIsEditingCustom(false);
                      }}
                      onFocus={() => {
                        if (customAmount && selected !== 'custom') {
                          onChange(Number(customAmount));
                        }
                        if (customAmount) {
                          setCurrentOption(null); // remove impact statement
                          setSelected('custom');
                        }
                        setIsEditingCustom(true);
                      }}
                    />
                  )}
                />
              </Grid>
            );
          }

          const baseButtonClass = 'GF-GiftOptions__button';
          const isSelected = option.amount === selected;
          // additionally passes 'GF-GiftOptions__button--selected' class to the button that is selected
          const buttonClass = clsx(baseButtonClass, {
            [`${baseButtonClass}--selected`]: isSelected
          });

          return (
            <Grid item xs={columns} key={option.amount}>
              {option.tag && (
                <Text className="GF-GiftOptions__tag" variant="h5">
                  {option.tag}
                </Text>
              )}
              <Button
                {...register('giftAmount')}
                ref={null}
                className={buttonClass}
                onClick={() => {
                  onChange(option.amount as number);
                  trigger('giftAmount');
                  setSelected(option.amount as number);
                  setCurrentOption(option);
                }}
                fullWidth
              >
                {formatCurrency(Number(option.amount))}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  });

  return (
    <Box className="GF-GiftOptions" id="GF-GiftOptions">
      {!!subtext && (
        <Text variant="body" className="GF-GiftOptions__subtext">
          {subtext}
        </Text>
      )}
      <Grid
        className="GF-GiftOptions__button-container"
        container
        rowSpacing={0.75}
        columnSpacing={0.75}
      >
        {mappedOptions}
      </Grid>
      {error?.type === 'required' ? (
        <FormHelperText error>{error.message}</FormHelperText>
      ) : null}
      {currentOption?.impactStatement && (
        <Impact
          impactStatement={currentOption.impactStatement}
          icon={currentOption.icon}
        />
      )}
      {customAmount && currentOption === null && !!customImpactStatement && (
        <Impact impactStatement={calculateCustomImpactStatement()} />
      )}
    </Box>
  );
};

export default GiftOptions;
