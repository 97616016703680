import { SubmitDonationDataType } from 'services/donationService';
import {
  CustomContentBlock,
  ICustomContentBlockProps
} from 'components/CustomContentBlock/CustomContentBlock';
import { usePlaceholderData } from 'hooks/usePlaceholderData';
import { Donation } from 'types';
import './ThankYouCustomContent.scss';

type ThankYouCustomContentProps = ICustomContentBlockProps & {
  givingFormSubmission: SubmitDonationDataType | null;
  donationResponse: Donation | null;
  isEditMode: boolean;
};

const ThankYouCustomContent = ({
  html,
  donationResponse,
  givingFormSubmission,
  isEditMode,
  ...props
}: ThankYouCustomContentProps) => {
  const getPlaceholder = usePlaceholderData(
    givingFormSubmission,
    donationResponse,
    isEditMode
  );

  const adjustedHTML = isEditMode
    ? html
    : html.replace(/{{(.*?)}}/g, (_match: string, placeholder) =>
        getPlaceholder(placeholder)
      );

  const className = isEditMode
    ? 'TY-Custom-Content-Preview'
    : 'TY-Custom-Content';

  return (
    <div className={className}>
      <CustomContentBlock html={adjustedHTML} {...props} />
    </div>
  );
};

export default ThankYouCustomContent;
