import { Grid, GridProps } from '@mui/material';
import type { PlaceholderTableCellProps } from 'components/TableCell';
import PlaceholderTableCell from 'components/TableCell';

export type PlaceholderTableData = PlaceholderTableCellProps[];

type TableProps = {
  tableData: PlaceholderTableData;
} & Pick<GridProps, 'className'>;

const Table = ({ tableData, className }: TableProps) => (
  <Grid className={className} container columns={3} width="100%" rowGap={1}>
    {tableData?.map((cell) => (
      <Grid key={cell.label} item xs={3} sm={1}>
        <PlaceholderTableCell {...cell} />
      </Grid>
    )) ?? null}
  </Grid>
);

export default Table;
