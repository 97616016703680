import Text, { TextProps, TextVariants } from './Text';

export const TEXT_VARIANTS: { [key: string]: TextVariants } = {
  hero: 'hero',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h6Regular: 'h6Regular',
  h7: 'h7',
  h8: 'h8',
  body: 'body',
  caption: 'caption'
};

export * from './Text.theme';
export type { TextProps };

export default Text;
