/**
 * chunkItems accepts an array of items and a max length (n), and will divide your array into subarrays of length n while preserving order.
 * @param items - an array of items to chunk
 * @param chunkSize - a max chunk size. will divide `items` into subarrays of this length
 * @returns An array of subarrays of length chunkSize
 */
const chunkItems = <T>(items: T[], chunkSize: number) =>
  items.reduce((chunks: T[][], item: T, index) => {
    const chunk = Math.floor(index / chunkSize);
    // eslint-disable-next-line no-param-reassign
    chunks[chunk] = ([] as T[]).concat(chunks[chunk] || [], item);
    return chunks;
  }, []);

export default chunkItems;
