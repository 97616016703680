import { useCallback } from 'react';
import { SubmitDonationDataType } from 'services/donationService';
import { Donation, RecurringOptionsType } from 'types';
import { getISO8601DateString } from 'utils/dateUtils';
import { useGivingFormData } from './useGivingFormData';

const useDoubleTheDonation = () => {
  const { doubleTheDonationKey, doubleTheDonationPartnerKey } =
    useGivingFormData();

  const registerDonation = useCallback(
    (
      donationData: SubmitDonationDataType & Pick<Donation, 'transactionId'>
    ) => {
      if (!doubleTheDonationKey || !doubleTheDonationPartnerKey) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { doublethedonation } = window as any;

      if (doublethedonation) {
        // Don't break your page if our plugin doesn't load for any reason
        try {
          doublethedonation.integrations.core.register_donation({
            '360matchpro_public_key': doubleTheDonationKey,
            partner_identifier: doubleTheDonationPartnerKey,
            campaign: donationData.campaignId,
            donation_identifier: donationData.transactionId,
            donation_amount: donationData.giftAmount,
            donor_first_name: donationData.billing.firstName,
            donor_last_name: donationData.billing.lastName,
            donor_email: donationData.billing.email,
            donor_address: {
              zip: donationData.billing.postalCode,
              city: donationData.billing.city,
              state: donationData.billing.state,
              address1: donationData.billing.address1,
              address2: donationData.billing.address2,
              country: donationData.billing.country
            },
            donor_phone: donationData.billing.phoneNumber,
            donation_datetime: getISO8601DateString(new Date()),
            anonymous: (donationData.anonymousGift ?? false).toString(),
            in_memoriam: (!!donationData.tribute?.tributeFirstName).toString(),
            // athletic: 'true', // According to the documentation - this one is related to a donation to an athletic institution. Pretty sure that we don’t want to set it to true, but not sure if we need to determine a valid value or just omit this since it’s optional?
            recurring: (
              donationData.recurringOption !== RecurringOptionsType.Once
            ).toString(),
            doublethedonation_company_id:
              donationData.corporateMatchingCompanyId // Only needed if the donor used the streamlined search field.
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('doublethedonation submit failed.', err);
        }
      }
    },
    [doubleTheDonationKey, doubleTheDonationPartnerKey]
  );

  return { registerDonation };
};

export default useDoubleTheDonation;
