import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Text from 'components/lib/Text';
import { NumberTextField } from 'components/lib/TextField';
import './CCPaymentInfo.scss';

const CCCvv = () => {
  const { control } = useFormContext();

  return (
    <Grid className="GF__GridInputWrapper" item xs={6} sm={3}>
      <Text className="GF-CCPaymentInfo__heading" variant="h5">
        CVV *
      </Text>
      <Controller
        name="creditCard.cvv"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <NumberTextField
            {...field}
            className="GF-CCPaymentInfo__textField GF-CCPaymentInfo__cvv"
            error={!!error}
            format="####"
            fullWidth
            helperText={error?.message ?? null}
            placeholder="CVV"
          />
        )}
      />
    </Grid>
  );
};

export default CCCvv;
