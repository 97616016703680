import { useCallback, useLayoutEffect } from 'react';
import { SubmitDonationDataType } from 'services/donationService';
import { useGivingFormData } from 'hooks';
import { Donation, IThankYouCorporateMatchingBlock } from 'types';
import './ThankYouCorporateMatchingBlock.scss';

type ThankYouCorporateMatchingBlockProps = IThankYouCorporateMatchingBlock & {
  givingFormSubmission: SubmitDonationDataType | null;
  donationResponse: Donation | null;
};

export const ThankYouCorporateMatchingBlock = ({
  isEnabled,
  givingFormSubmission,
  donationResponse
}: ThankYouCorporateMatchingBlockProps): JSX.Element => {
  const { doubleTheDonationKey } = useGivingFormData();

  const loadCorporateMatchingGiftPlugin = useCallback(() => {
    if (isEnabled && doubleTheDonationKey) {
      const companyId = givingFormSubmission?.corporateMatchingCompanyId;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gWindow = window as any;
      gWindow.DDCONF = {
        API_KEY: doubleTheDonationKey,
        COMPANY: companyId || null
      };

      if (gWindow.doublethedonation) {
        gWindow.doublethedonation.plugin.load_config();
        if (donationResponse) {
          gWindow.doublethedonation.plugin.set_donation_identifier(
            donationResponse.transactionId
          );
        }
        gWindow.doublethedonation.plugin.set_donation_campaign(
          givingFormSubmission?.campaignId
        );
        if (companyId) {
          gWindow.doublethedonation.plugin.set_company(companyId);
        } else if (givingFormSubmission?.billing?.email) {
          const domain =
            gWindow.doublethedonation.integrations.core.strip_domain(
              givingFormSubmission.billing.email
            );
          gWindow.doublethedonation.plugin.email_domain(domain);
        }
        gWindow.doublethedonation.plugin.load_plugin();
      }
    }
  }, [donationResponse, doubleTheDonationKey, givingFormSubmission, isEnabled]);

  useLayoutEffect(() => {
    loadCorporateMatchingGiftPlugin();
  }, [loadCorporateMatchingGiftPlugin]);

  return (
    <div className="gf-thank-you-corporate-matching">
      <div id="dd-container" />
    </div>
  );
};
