import { AllPaymentOptions, DonorFees } from 'types';

// Calculate transaction amount for display only.
// Backend performs the same calculation for final donation submission
// Any changes to this calculation should be propagated to the backend repo in src/donations/donations.utils.ts
export const calculateTransactionAmount = (
  giftAmount: number,
  paymentOption: AllPaymentOptions,
  donorFees: DonorFees
) => {
  const roundHalfEven = (value: number) => {
    // If number doesn't end in .5, then round normally:
    if (value % 0.5 !== 0) {
      return Math.round(value);
    }
    // Otherwise apply round half to even rule/"banker's rounding": 1.5 -> 2, 2.5 -> 2, 3.5 -> 4 etc
    return Math.floor(value) % 2 === 0 ? Math.floor(value) : Math.round(value);
  };

  const calculateFee = (amount: number, ratio: number, fixed: number) =>
    roundHalfEven((amount * ratio + fixed) * 100.0) / 100.0;

  switch (paymentOption) {
    case AllPaymentOptions.ECHECK:
      return calculateFee(
        giftAmount,
        donorFees.echeck.ratio,
        donorFees.echeck.fixed
      );
    case AllPaymentOptions.DEBIT:
    case AllPaymentOptions.CREDIT:
    default: // Currently, anything that isn't echeck is handled as "cash"
      return calculateFee(
        giftAmount,
        donorFees.cash.ratio,
        donorFees.cash.fixed
      );
  }
};
