import { FunctionComponent } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as IconAmexInactive } from 'assets/icons/Amex-Deselect.svg';
import { ReactComponent as IconAmex } from 'assets/icons/Amex-Select.svg';
import { ReactComponent as IconDiscoverInactive } from 'assets/icons/Discover-Deselect.svg';
import { ReactComponent as IconDiscover } from 'assets/icons/Discover-Select.svg';
import { ReactComponent as IconMastercardInactive } from 'assets/icons/Mastercard-Deselect.svg';
import { ReactComponent as IconMastercard } from 'assets/icons/Mastercard-Select.svg';
import { ReactComponent as IconVisaInactive } from 'assets/icons/Visa-Deselect.svg';
import { ReactComponent as IconVisa } from 'assets/icons/Visa-Select.svg';
import './Icon.scss';

export const ICONS = {
  AMEX: IconAmex,
  AMEX_INACTIVE: IconAmexInactive,
  DISCOVER: IconDiscover,
  DISCOVER_INACTIVE: IconDiscoverInactive,
  MASTERCARD: IconMastercard,
  MASTERCARD_INACTIVE: IconMastercardInactive,
  VISA: IconVisa,
  VISA_INACTIVE: IconVisaInactive
};

export const CreditCardIcon = ({
  activeIcon,
  inactiveIcon,
  active,
  className,
  ...props
}: SvgIconProps & {
  activeIcon: FunctionComponent;
  inactiveIcon: FunctionComponent;
  className?: string;
  active?: boolean;
}): JSX.Element => (
  <SvgIcon
    component={active ? activeIcon : inactiveIcon}
    viewBox="0 0 34 24"
    className={`GF-CCPaymentInfo__cc-icon-${
      active ? 'active' : 'inactive'
    } ${className}`}
    {...props}
  />
);

export type IconType = keyof typeof ICONS;
