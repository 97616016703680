import { useContext } from 'react';
import { TokenContext } from 'components/Providers/SpreedlyCreditCardContext';

export const useCreditCardToken = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('You must use the Token Context within the Token Provider');
  }
  return context;
};
