import { CSSProperties } from 'react';
import { Theme, createTheme } from '@mui/material';

/**
 * Utility function to create default, focus, and hover styles for an element
 */
const updateElementStates = (styles: CSSProperties) => ({
  ...styles,
  '&:focus': {
    ...styles
  },
  '&:hover': {
    ...styles
  }
});

export const createDatePickerTheme = (currTheme: Theme) => {
  const {
    typography: { h3, h5, h8 },
    palette: {
      primary: { main: primary },
      darkGray: { main: darkGray },
      black: { main: black },
      white: { main: white }
    }
  } = currTheme;

  const theme = createTheme(currTheme, {
    components: {
      /**
       * General styles for the calendar widget
       */
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            fontFamily: 'Roobert',
            '& .MuiTypography-caption': {
              ...h3,
              color: darkGray,
              opacity: 0.4
            },
            '& .PrivatePickersFadeTransitionGroup-root': {
              ...h5,
              color: black
            }
          }
        }
      },
      /**
       * Styles for the individual day components
       */
      MuiPickersDay: {
        styleOverrides: {
          root: {
            ...h8,
            color: black,
            '&.MuiPickersDay-today': {
              border: 'none',
              '&:focus': {
                background: 'none'
              }
            },
            '&.Mui-selected': {
              ...updateElementStates({
                backgroundColor: primary
              })
            }
          }
        }
      },
      /**
       * Styles for the year dropdown
       */
      MuiYearPicker: {
        styleOverrides: {
          root: {
            '& .PrivatePickersYear-root': {
              '& button': {
                ...h8,
                color: black
              },
              '& .Mui-selected': {
                ...updateElementStates({
                  backgroundColor: primary,
                  color: white
                })
              }
            }
          }
        }
      },
      /**
       * Styles for the mobile date picker's action buttons
       */
      MuiModal: {
        styleOverrides: {
          root: {
            '&.DatePickerDialog .MuiDialogActions-root': {
              background: 'none',
              justifyContent: 'space-evenly',
              '& .MuiButton-root': {
                width: '100%',
                '&:nth-of-type(1)': {
                  border: '1px solid rgba(0, 0, 0, .15)',
                  borderRadius: '4px',
                  color: black
                },
                '&:nth-of-type(2)': {
                  background: primary,
                  color: white,
                  borderRadius: '4px'
                }
              }
            }
          }
        }
      }
    }
  });

  return theme;
};
