import { useState } from 'react';
import { CalendarPickerProps } from '@mui/lab';
import MuiDatePicker from '@mui/lab/DatePicker';
import { Box, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import clsx from 'clsx';
import Icon, { ICONS } from 'components/lib/Icon';
import TextField from 'components/lib/TextField';
import useWindowSize from 'hooks/useWindowSize';
import './DatePicker.scss';

type DatePickerProps = {
  /**
   * Callback to handle date change events. This gets called on each date selection event,
   * INCLUDING if a user picks a date on mobile date picker but doesnt click apply
   */
  onChange?: (
    date: string | null,
    keyboardInputValue?: string | undefined
  ) => void;
  /**
   * Callback to handle date selection events. This gets called if a date is clicked on Desktop picker,
   * or if a user has clicked a date and clicks apply on mobile.
   */
  onAccept?: (date: string | null) => void;
  /**
   * Custom error boolean to overwrite built in error handling
   */
  error?: boolean;
  /**
   * Optional controlled value
   */
  value?: string | null;
  /**
   * Props to be forwarded to the Textfield
   */
  TextFieldProps?: MuiTextFieldProps;
  /**
   * Primary color to override theme
   */
  primaryColor?: string;
  /**
   * Class name to be forwarded to the Dialog component for the mobile date picker.
   */
  dialogClassName?: string;
  /**
   * Input form name
   */
  name: string;
} & Partial<CalendarPickerProps<string>>;

const InputAdornment = () => <Icon icon={ICONS.CALENDAR} />;

/**
 * GMS Themed Date Picker component. This component renders MUI's Desktop Date Picker and
 * Mobile Date Picker based on pointer accuracy.
 *
 * Read more at https://mui.com/components/date-picker/
 */
const DatePicker = ({
  onChange,
  onAccept,
  error,
  name,
  value,
  TextFieldProps = {},
  dialogClassName,
  ...datePickerProps
}: DatePickerProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const { width } = useWindowSize();
  const isSmallMobile = width < 600;

  return (
    <Box className="date-picker-box">
      <MuiDatePicker
        {...datePickerProps}
        value={value ?? selectedDate}
        inputFormat="MM/dd/yyyy"
        className="idonateDatePicker-root"
        cancelText="Cancel"
        onChange={(date, keyboardInput) => {
          onChange?.(date, keyboardInput);
        }}
        onAccept={(date) => {
          setSelectedDate(date);
          onAccept?.(date);
        }}
        okText="Apply"
        showToolbar={false}
        DialogProps={{
          disablePortal: isSmallMobile,
          classes: {
            root: clsx('DatePickerDialog', dialogClassName)
          }
        }}
        renderInput={({ inputProps, ...params }) => {
          const hasError = error ?? params.error;
          return (
            <TextField
              name={name}
              {...params}
              {...TextFieldProps}
              inputProps={{
                ...inputProps,
                placeholder: undefined // Removes the plaeholder the Date Picker injects by default
              }}
              error={hasError}
            />
          );
        }}
        components={{
          OpenPickerIcon: InputAdornment
        }}
      />
    </Box>
  );
};

export default DatePicker;
