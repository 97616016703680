import { Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from 'components/lib/Checkbox';
import Text from 'components/lib/Text';
import { IEmailOptInBlock } from 'types';
import './EmailOptIn.scss';

const EmailOptIn = ({ prompt, message }: IEmailOptInBlock): JSX.Element => {
  const { control } = useFormContext();

  return (
    <div id="GF-EmailOptIn">
      {!!prompt && (
        <Text variant="h3" className="GF-EmailOptIn__heading">
          {prompt}
        </Text>
      )}

      <Stack direction="row" alignItems="center" spacing={0.25}>
        <Controller
          name="emailOptIn"
          defaultValue={false}
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <Checkbox
              {...field}
              value={value}
              className={`GF-EmailOptIn__checkbox${value ? '--selected' : ''}`}
            />
          )}
        />

        <Text variant="caption" className="GF-EmailOptIn__label">
          {message}
        </Text>
      </Stack>
    </div>
  );
};

export default EmailOptIn;
