import { useEffect, useState } from 'react';

export const useEventHub = (initializationCallback: any) => {
  const [hubInitPromise, setHubInitPromise] = useState<Promise<any>>(
    async () => null
  );
  const [eventHub, setEventHub] = useState<any>();

  let initializeEventHub: any;
  const wireEventHub = () => {
    if (
      window !== window.parent ||
      window.location.href.includes('screenshot-service')
    ) {
      initializeEventHub = () =>
        new Promise((resolve, reject) => {
          window.addEventListener(
            'message',
            (event) => {
              if (event.data && event.data.name === 'EventHub') {
                const subscribers: any = {};
                const eventHubRef = {
                  name: event.data.name,
                  props: event.data.props || {},
                  emit: (name: string, payload: any) => {
                    event.ports[0].postMessage({
                      name,
                      payload
                    });
                  },
                  subscribe: (name: string, callback: any) => {
                    subscribers[name] = subscribers[name] || [];
                    subscribers[name].push(callback);
                  }
                };
                // eslint-disable-next-line no-param-reassign
                event.ports[0].onmessage = (e) => {
                  const callbacks = subscribers[e.data.name] || [];
                  try {
                    callbacks.forEach((x: any) => x(e.data));
                  } catch (err) {
                    console.warn('EventHub callback throw: ', err);
                  }
                };

                // store parent's origin for things like Apple Pay
                eventHubRef.props.origin = event.origin;

                setEventHub(eventHubRef);
                resolve(eventHubRef);
              }
            },
            true
          );
        });
    }
  };
  useEffect(() => {
    wireEventHub();
    if (initializeEventHub) {
      const eventHubInitializer = initializeEventHub();
      setHubInitPromise(eventHubInitializer);
      eventHubInitializer.then((eventHubRef: any) => {
        initializationCallback(eventHubInitializer);
        return eventHubRef;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { eventHub, hubInitPromise };
};

export default useEventHub;
