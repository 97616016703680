import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Text from 'components/lib/Text';
import { NumberTextField } from 'components/lib/TextField';
import './ACHPaymentInfo.scss';

const ACHPaymentInfo = () => {
  const { control } = useFormContext();
  return (
    <Box className="GF-ACHPaymentInfo">
      <Text className="GF-ACHPaymentInfo__heading" variant="h5">
        Payment Info
      </Text>
      <Grid container columnSpacing="0.75rem" rowSpacing="0.75rem">
        <Grid className="GF__GridInputWrapper" item xs={12}>
          <Controller
            name="ach.routingNumber"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <NumberTextField
                {...field}
                allowLeadingZeros
                className="GF-ACHPaymentInfo__numberTextField GF-ACHPaymentInfo__routing-number"
                error={!!error}
                fullWidth
                helperText={error?.message ?? null}
                label="Routing Number *"
              />
            )}
          />
        </Grid>
        <Grid className="GF__GridInputWrapper" item sm={6} xs={12}>
          <Controller
            name="ach.accountNumber"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <NumberTextField
                {...field}
                className="GF-ACHPaymentInfo__numberTextField GF-ACHPaymentInfo__account-number"
                error={!!error}
                fullWidth
                helperText={error?.message ?? null}
                label="Account Number *"
                allowLeadingZeros
              />
            )}
          />
        </Grid>
        <Grid className="GF__GridInputWrapper" item sm={6} xs={12}>
          <Controller
            name="ach.confirmAccountNumber"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <NumberTextField
                {...field}
                className="GF-ACHPaymentInfo__numberTextField GF-ACHPaymentInfo__confirm-account-number"
                error={!!error}
                fullWidth
                helperText={error?.message ?? null}
                label="Confirm Account Number *"
                allowLeadingZeros
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ACHPaymentInfo;
