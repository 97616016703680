import { CSSProperties } from 'react';
import { Theme, createTheme } from '@mui/material';

export const createTextFieldTheme = (currTheme: Theme): Theme => {
  const {
    palette: { lightGray, darkGray, red },
    typography: { caption, h6 }
  } = currTheme;

  /**
   * Helper function to update default, hover, and disabled styles for the TextField fieldset
   */
  const addFieldsetStyles = (
    styles: CSSProperties = {},
    hoverStyles: CSSProperties = {},
    disabledStyles: CSSProperties = {}
  ) => ({
    '& fieldset': styles,
    '&:hover fieldset': hoverStyles,
    '&.Mui-disabled fieldset': disabledStyles
  });

  const theme = createTheme(currTheme, {
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            // Set default width and height
            height: '100%',
            // Target the input field's container
            '& .MuiOutlinedInput-root': {
              borderRadius: '0.5rem',
              minHeight: '3rem',
              // Change font color when input field is focused.
              '&.Mui-focused': {
                // Add border/font styles to the fieldset when input field is focused
                ...addFieldsetStyles(
                  { borderWidth: '1px', color: darkGray.main },
                  { borderColor: darkGray.main, borderWidth: '1px' }
                )
              },
              // Target the input field itself
              '& .MuiOutlinedInput-input': {
                // Add styles to the actual field plus all the same text properties as the caption typography
                padding: '0.625rem 1rem',
                ...caption,
                fontSize: '1rem',
                letterSpacing: 'inherit'
              },
              // Set default styles, hover styles, and disabled styles for border
              ...addFieldsetStyles(
                { border: `1px solid ${lightGray.main}` },
                {
                  borderColor: lightGray.main,
                  borderWidth: '1px'
                },
                {
                  borderColor: lightGray.main,
                  opacity: 0.5
                }
              ),

              // Fix padding if input contains icon
              '&.MuiInputBase-adornedStart .MuiOutlinedInput-input': {
                paddingLeft: 0
              },
              '&.MuiInputBase-adornedEnd .MuiOutlinedInput-input': {
                paddingRight: 0
              },
              // Show not allowed cursor for diabled fields
              '& .Mui-disabled': {
                cursor: 'not-allowed'
              }
            }
          }
        },
        defaultProps: {
          color: 'darkGray'
        },
        variants: [
          {
            props: { error: true },
            style: {
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused': {
                  // Add styles for error variant when field is focused
                  ...addFieldsetStyles(
                    { borderWidth: '1px' },
                    {
                      borderColor: `${red.main} !important`,
                      borderWidth: '1px'
                    }
                  )
                },
                // Add styles for error variant when field is not focused.
                ...addFieldsetStyles(
                  { border: `1px solid ${red.main}` },
                  {
                    borderColor: `${red.main} !important`,
                    borderWidth: '1px'
                  },
                  {
                    borderColor: red.main,
                    opacity: 0.5
                  }
                )
              }
            }
          },
          {
            props: { multiline: true },
            style: {
              // Update padding rules for multiline TextFields
              '&&': {
                '& .MuiOutlinedInput-root': {
                  padding: 0,
                  maxHeight: 'unset',
                  '& .MuiInputBase-input': {
                    padding: '.625rem 1rem',
                    fontSize: '1rem',
                    lineHeight: '1.25rem'
                  }
                }
              }
            }
          },
          {
            props: { hiddenLabel: true },
            style: {
              // Hide label if prop is passed.
              '& .MuiInputLabel-root': {
                display: 'none',
                visibility: 'hidden'
              },
              '& .MuiInputBase-root fieldset': {
                top: '0',
                legend: {
                  display: 'none',
                  visibility: 'hidden'
                }
              }
            }
          }
        ]
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 8,
            marginLeft: 0
          }
        }
      }
    }
  });

  return theme;
};
