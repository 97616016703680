import * as React from 'react';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps
} from '@mui/material';
import { convertToId } from 'utils';

type DialogCloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

interface DialogContextInterface {
  /**
   * The callback used to close the dialog. The reason is accessible through the param.
   */
  onClose: (reason: DialogCloseReason) => void;

  /**
   * The label that is passed in as a prop to the <Dialog /> within the
   * SuccessDialog component gets converted to an html-esque id,
   * and set to this property in the context.
   */
  computedLabel: string;

  /**
   * The isSuccess prop that is passed is set to this property in the context
   */
  isSuccessVariant: boolean;
}

/**
 * This DialogContext is used by children components of Dialog to share the
 * onClose handler, the a11y `computedLabel` and the `isSuccessVariant` prop
 * e.g. DialogTitle, DialogContentDescription, DialogActionButton
 */
const DialogContext = React.createContext<DialogContextInterface | null>(null);

/**
 * Hook that returns the DialogContext
 */
export const useDialogContext = (): DialogContextInterface => {
  const context = React.useContext(DialogContext);
  if (!context) {
    throw new Error(
      `Dialog compound components cannot be rendered outside the Dialog component`
    );
  }
  return context;
};

export interface DialogProps extends MuiDialogProps {
  /**
   * Used for a11y purposes
   */
  label: string;

  /**
   * The handler used to close the dialog.
   */
  onClose: (dialogCloseReason: DialogCloseReason) => void;

  /**
   * This is set to true in the <SuccessDialog /> component.
   * This value is added to the Dialog context so the action buttons
   * know to take up the full width.
   */
  isSuccess?: boolean;
}

/**
 * Base level GMS Dialog component
 * Opted to go for a more composable Dialog pattern instead of passing a bunch of
 * configs and/or props for ultimate flexibility. (and also, this method is closest to the original
 * implementation of MUI's Dialog component)
 * More about Dialog here - https://mui.com/components/dialogs/
 */
const Dialog = ({
  open,
  onClose,
  label,
  children,
  isSuccess = false,
  ...props
}: DialogProps): JSX.Element => {
  const [computedLabel] = React.useState(() => convertToId(label));
  const [isSuccessVariant] = React.useState(() => isSuccess);

  // context object
  const context = React.useMemo(
    () => ({
      computedLabel,
      isSuccessVariant,
      onClose
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [computedLabel, isSuccessVariant]
  );

  return (
    <DialogContext.Provider value={context}>
      <MuiDialog
        open={open}
        onClose={(_event, reason) => onClose(reason)}
        scroll="paper"
        aria-labelledby={`${computedLabel}-dialog-title`}
        aria-describedby={`${computedLabel}-dialog-description`}
        {...props}
      >
        {children}
      </MuiDialog>
    </DialogContext.Provider>
  );
};

export default Dialog;
