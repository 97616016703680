import { createContext, useContext, useMemo, useState } from 'react';

/**
 * Shape of extra form information. Anything required for processing/validation
 * but that should not be sent to the backend onSubmit
 */
type ExtraFormInfo = {
  /**
   * Is the default gift option selected. This is used to conditionally
   * validate the min/max amount when buttons are selected.
   */
  isCustomGift: boolean;

  /**
   * Is a 3rd party payment method enabled or selected? If so we need to
   * handle conditional name/email/address validation.
   */
  isThirdPartyPaySelected: boolean;

  /**
   * Is the On Behalf of Organization feature toggled on, and has the
   * user selected Organization? If so, organization name is a required field.
   */
  isOrganizationRequired: boolean;
};

type ExtraFormInfoContextType = {
  setIsCustomGiftSelected: (isSelected: boolean) => void;
  setIsThirdPartyPaySelected: (isSelected: boolean) => void;
  setIsOrganizationRequired: (isRequired: boolean) => void;
  extraContext: ExtraFormInfo;
};

const ExtraFormInfoContext = createContext<ExtraFormInfoContextType | null>(
  null
);

export const useExtraFormInfo = (): ExtraFormInfoContextType => {
  const context = useContext(ExtraFormInfoContext);
  if (!context) {
    throw new Error(
      'Something went wrong. No context found for Extra Form Info.'
    );
  }
  return context;
};

type ExtraFormInfoProviderProps = {
  children: React.ReactNode;
};

export const ExtraFormInfoProvider = ({
  children
}: ExtraFormInfoProviderProps): JSX.Element => {
  const [extraContext, setExtraContext] = useState<ExtraFormInfo>({
    isCustomGift: false,
    isThirdPartyPaySelected: false,
    isOrganizationRequired: false
  });
  const value: ExtraFormInfoContextType = useMemo(() => {
    /**
     * Expose method to update isCustomGiftSelected flag
     */
    const setIsCustomGiftSelected = (isSelected: boolean): void => {
      const newContext = {
        ...extraContext,
        isCustomGift: isSelected
      };
      setExtraContext(newContext);
    };

    const setIsThirdPartyPaySelected = (isSelected: boolean): void => {
      const newContext = {
        ...extraContext,
        isThirdPartyPaySelected: isSelected
      };
      setExtraContext(newContext);
    };

    const setIsOrganizationRequired = (isRequired: boolean): void => {
      const newContext = {
        ...extraContext,
        isOrganizationRequired: isRequired
      };
      setExtraContext(newContext);
    };

    return {
      setIsCustomGiftSelected,
      setIsThirdPartyPaySelected,
      setIsOrganizationRequired,
      extraContext
    };
  }, [extraContext]);

  return (
    <ExtraFormInfoContext.Provider value={value}>
      {children}
    </ExtraFormInfoContext.Provider>
  );
};
