import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps
} from '@mui/material';

export type ButtonProps = Omit<MuiButtonProps, 'color'> & { name: string };

/**
 * Base level GMS Button Component
 * More about Button here - https://mui.com/components/buttons/
 * @param props Any valid MUI Button props are forwarded
 * @returns A GMS themed Button component
 */
const Button = (props: ButtonProps): JSX.Element => (
  <MuiButton variant="primary" size="medium" {...props} />
);

export default Button;
