import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import Text from 'components/lib/Text';

const Tooltip = ({ children, title, ...props }: TooltipProps): JSX.Element => {
  // If title is passed as a string, we wrap it by default in a Text Caption Variant.
  const TitleWrapper =
    typeof title === 'string' ? (
      <Text variant="caption" color="common.white">
        {title}
      </Text>
    ) : (
      title
    );

  return (
    <MuiTooltip title={TitleWrapper} {...props}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
