import { FC } from 'react';
import { ApplePayIcon } from '../components/Icon';
import { ECheckIcon } from '../components/Icon/ECheckIcon';
import { GooglePayIcon } from '../components/Icon/GooglePayIcon';
import { PayPalIcon } from '../components/Icon/PayPalIcon';
import { PaymentOptionsWithIcons } from './givingForm/PaymentInfoSection';

export type PaymentIcons = {
  [key in PaymentOptionsWithIcons]: FC;
};
export const ICONS = {
  APPLEPAY: ApplePayIcon,
  ECHECK: ECheckIcon,
  GOOGLEPAY: GooglePayIcon,
  PAYPAL: PayPalIcon
};

// export type IconType = keyof typeof ICONS;
