import { CSSProperties, FC, useState } from 'react';
import { Box, IconButton, Icon as MuiIcon } from '@mui/material';
import clsx from 'clsx';
import { DragStart, DragUpdate, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as IconDrag } from 'assets/icons/DragBlockIcon.svg';
import { ReactComponent as IconPencil } from 'assets/icons/PencilIcon.svg';
import Icon, { ICONS } from 'components/lib/Icon';
import { HostedPageSections } from 'types/givingForm/HostedPage';
import './HostedPageEditWrapper.scss';

export const HostedPageEditWrapper: FC<{
  className?: string;
  dndProps?: {
    blockKey: string;
    index: number;
    section: HostedPageSections;
    draggedElementHeightRef: React.RefObject<number | null>;
    dragEvent: DragStart | DragUpdate | null;
  };
  compress?: boolean;
  isDraggable: boolean;
  onDelete?(): void;
  onEdit?(): void;
}> = ({
  children,
  className,
  dndProps,
  compress,
  isDraggable,
  onDelete,
  onEdit
}) => {
  const [isShifted, setIsShifted] = useState(false); // Handles whether or not we should translate the entire item during dragging
  const blockKey = dndProps?.blockKey;
  const index = dndProps?.index;
  const draggedElementHeightRef = dndProps?.draggedElementHeightRef;
  const dragEvent = dndProps?.dragEvent;
  const section = dndProps?.section;

  // Determine if this is the element that's actually being dragged:
  const isDragging = dragEvent?.draggableId === `${blockKey}-rbd`;

  const shiftStyling: CSSProperties = {};
  const destination = (dragEvent as DragUpdate)?.destination;
  const source = dragEvent?.source;
  if (isShifted) {
    if (
      destination && // there is a drag event destination
      destination.droppableId === section && // Hovering over this item's section
      // If the destination and source are different, we shift.  If they are
      // the same, we only shift when the indices aren't equal, e.g. we're not
      // hovering over the starting location
      (destination.droppableId !== source?.droppableId ||
        destination.index !== source?.index)
    ) {
      const shiftSize = draggedElementHeightRef?.current ?? 0;
      shiftStyling.transform = `translateY(${shiftSize}px)`;
    }
  }

  // If this is the item being dragged, we handle shifting the element differently
  if (isDragging) {
    if (
      destination && // There is a destination on the event
      destination.droppableId === section && // It is targeting the section this draggable is in
      destination.index < (source?.index as number) // It is targeting something above the original location (e.g. not hovering over the original spot)
    ) {
      const shiftSize = draggedElementHeightRef?.current ?? 0;
      shiftStyling.transform = `translateY(${shiftSize}px)`;
    }
  }

  const editOptionsClass = clsx(
    'hosted-page-edit-options-wrapper',
    className ?? '',
    {
      'compress-wrapper': compress
    }
  );

  return (
    <div className={editOptionsClass} style={shiftStyling}>
      <Box className="hosted-page-edit-options-buttons-container">
        {onEdit && (
          <IconButton onClick={onEdit}>
            <MuiIcon component={IconPencil} />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={onDelete}>
            <Icon className="edit-options-icon" icon={ICONS.TRASH} />
          </IconButton>
        )}
        {isDraggable && (
          <Draggable
            key={`${blockKey}-rbd`}
            draggableId={`${blockKey as string}-rbd`}
            index={index as number}
          >
            {(draggableProvided) => {
              if (draggableProvided.draggableProps.style?.transform) {
                setIsShifted(true); // This triggers a react error that can be ignored on the first drag event
              } else {
                setIsShifted(false);
              }
              return (
                <IconButton
                  {...draggableProvided?.draggableProps}
                  ref={draggableProvided?.innerRef}
                  {...draggableProvided?.dragHandleProps}
                  className="drag-icon-button"
                >
                  <MuiIcon component={IconDrag} />
                </IconButton>
              );
            }}
          </Draggable>
        )}
      </Box>
      {children}
    </div>
  );
};
