import { useEffect, useMemo, useState } from 'react';
import { Grid, Stack, useTheme } from '@mui/material';
import clsx from 'clsx';
import { isValid } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { IGivingFormSchema } from 'components/GivingForm';
import Button from 'components/lib/Button';
import DatePicker from 'components/lib/DatePicker';
import FormHelperText from 'components/lib/FormHelperText';
import Select from 'components/lib/Select';
import Text from 'components/lib/Text';
import { NumberTextField } from 'components/lib/TextField';
import { useQueryParam } from 'hooks';
import {
  IRecurringOptionsBlock,
  RecurringMethod,
  RecurringOptionsType
} from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import {
  mapParamToRecurringOptionEnum,
  sanitizeRecurringOptionsQueryParams
} from 'utils/queryParamUtils';
import './RecurringOptions.scss';

type RecurringOptionsProps = Omit<IRecurringOptionsBlock, 'blockType'>;

const RecurringOptions = ({
  recurringOptions,
  defaultOption,
  showStartDate,
  recurringMethod,
  indefiniteEndNoticeMessage
}: RecurringOptionsProps): JSX.Element | null => {
  const {
    palette: {
      primary: { main: primary }
    }
  } = useTheme();
  const { control, watch, trigger } = useFormContext<IGivingFormSchema>();
  const recurringOptionsQueryParam = useQueryParam(
    IDonateQueryParam.FrequencyOptions
  );
  const frequencyQueryParam = useQueryParam(IDonateQueryParam.Frequency);
  const startDateQueryParam = useQueryParam(IDonateQueryParam.StartDate);
  const endDateQueryParam = useQueryParam(IDonateQueryParam.EndDate);
  const endCountQueryParam = useQueryParam(IDonateQueryParam.EndCount);

  const sanitizedFrequencyParam = frequencyQueryParam
    ? mapParamToRecurringOptionEnum([frequencyQueryParam])[0]
    : null;

  const recurringOption = watch('recurringOption');
  const startDate = watch('startDate');
  const endCount = watch('endCount');

  const sanitizedQueryParams = useMemo(
    () => sanitizeRecurringOptionsQueryParams(recurringOptionsQueryParam),
    [recurringOptionsQueryParam]
  );

  const [options, setOptions] = useState(
    sanitizedQueryParams.length > 0 ? sanitizedQueryParams : recurringOptions
  );

  const optionsInitialized = !!options.length;

  useEffect(() => {
    if (optionsInitialized) {
      setOptions(
        sanitizedQueryParams.length > 0
          ? sanitizedQueryParams
          : recurringOptions
      );
    }
  }, [optionsInitialized, recurringOptions, sanitizedQueryParams]);

  useEffect(() => {
    // trigger related revalidation when frequency changes
    if (recurringOption === RecurringOptionsType.Once) {
      trigger('startDate');
    } else if (
      recurringOption &&
      recurringOption !== RecurringOptionsType.Once
    ) {
      if (startDate) {
        trigger('startDate');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurringOption]);

  const useSelect = options.length > 2;

  // If the only provided recurring option is 'once' we do not display the options
  const isOnlyOnceOption =
    options.length === 1 && options[0] === RecurringOptionsType.Once;

  const isRecurringDonation =
    !!recurringOption && recurringOption !== RecurringOptionsType.Once;

  return (
    <Grid
      className="GF-RecurringOptions"
      id="GF-RecurringOptions"
      container
      spacing={1}
    >
      <Grid item xs={12}>
        <Controller
          name="recurringOption"
          control={control}
          defaultValue={
            sanitizedFrequencyParam ??
            defaultOption ??
            (options.length === 1 ? options[0] : undefined)
          }
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <>
              {useSelect ? (
                <Select
                  {...field}
                  error={!!error}
                  helperText={error?.message ?? null}
                  className="GF-RecurringOptions__select"
                  label="Select"
                  options={options.map((option: RecurringOptionsType) => ({
                    value: option,
                    label: option
                  }))}
                  defaultValue={sanitizedFrequencyParam ?? defaultOption}
                />
              ) : (
                <Stack
                  className="GF-RecurringOptions__button-container"
                  direction="row"
                  spacing={0.75}
                >
                  {isOnlyOnceOption
                    ? null
                    : options.map((option: RecurringOptionsType) => {
                        const baseButtonClass = 'GF-RecurringOptions__button';
                        const isSelected = option === field.value;
                        const buttonClass = clsx(baseButtonClass, {
                          [`${baseButtonClass}--selected`]:
                            isSelected || options.length === 1
                        });
                        return (
                          <Button
                            name="recurringOption"
                            key={option}
                            className={buttonClass}
                            onClick={() => {
                              field.onChange(option);
                            }}
                            fullWidth
                          >
                            Donate {option}
                          </Button>
                        );
                      })}
                </Stack>
              )}
              {!!error?.message && !useSelect && (
                <FormHelperText error>{error?.message}</FormHelperText>
              )}
            </>
          )}
        />
      </Grid>

      {isRecurringDonation && showStartDate && (
        <Grid item xs={12} md={6}>
          <Controller
            name="startDate"
            control={control}
            defaultValue={startDateQueryParam ?? undefined}
            render={({
              field: { ref, onChange, ...field },
              fieldState: { error }
            }) => {
              const fieldValue = field?.value;
              return (
                <DatePicker
                  name={field.name}
                  primaryColor={primary}
                  error={!!error}
                  disablePast
                  value={fieldValue}
                  onChange={(...args) => {
                    onChange(
                      ...args.map((day) =>
                        day && isValid(day) ? new Date(day).toISOString() : ''
                      )
                    );
                    trigger('startDate');
                  }}
                  TextFieldProps={{
                    ...field,
                    className: 'GF-CustomField__field--dateInput',
                    label: 'Start Date',
                    helperText: error?.message ?? '',
                    fullWidth: true
                  }}
                  shouldDisableDate={(day) => {
                    // Disable all date options but the 1st and 15th if that option is selected
                    if (
                      recurringOption === RecurringOptionsType.FirstAndFifteenth
                    ) {
                      return ![1, 15].includes(new Date(day).getDate());
                    }
                    return false;
                  }}
                />
              );
            }}
          />
        </Grid>
      )}

      {isRecurringDonation && recurringMethod === RecurringMethod.EndDate && (
        <Grid item xs={12} md={6}>
          <Controller
            name="endDate"
            defaultValue={endDateQueryParam ?? undefined}
            control={control}
            render={({
              field: { ref, onChange, ...field },
              fieldState: { error }
            }) => {
              const fieldValue = field?.value;
              return (
                <DatePicker
                  name={field.name}
                  primaryColor={primary}
                  error={!!error}
                  value={fieldValue}
                  onChange={(...args) => {
                    onChange(
                      ...args.map((day) =>
                        day && isValid(day) ? new Date(day).toISOString() : ''
                      )
                    );
                    trigger('endDate');
                  }}
                  disablePast
                  shouldDisableDate={(day) =>
                    startDate ? new Date(day) < new Date(startDate) : false
                  }
                  TextFieldProps={{
                    ...field,
                    className: 'GF-CustomField__field--dateInput',
                    label: 'End Date',
                    helperText: error?.message ?? '',
                    fullWidth: true
                  }}
                />
              );
            }}
          />

          <Text variant="body" className="indefinite-end-notice-message">
            {indefiniteEndNoticeMessage ||
              'If no end date is selected, recurring gift will continue indefinitely.'}
          </Text>
        </Grid>
      )}

      {isRecurringDonation && recurringMethod === RecurringMethod.Count && (
        <Grid item xs={12} md={6}>
          <Controller
            name="endCount"
            defaultValue={endCountQueryParam ?? undefined}
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <NumberTextField
                {...field}
                name="endCount"
                fullWidth
                allowNegative={false}
                error={!!error}
                helperText={error?.message}
                label="Number of Donations"
                value={endCount}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RecurringOptions;
